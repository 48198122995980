import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg``;

export const IconXlsx = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.87097 0.582031V6.00139H0V19.782H3.87097V24.582H24V0.582031H3.87097ZM3.87109 9.87305H5.92271L6.96787 11.4214L8.01303 9.87305H10.0646L8.01303 12.9698L10.0646 16.0666H8.01303L6.96787 14.5182L5.92271 16.0666H3.87109L5.92271 12.9698L3.87109 9.87305ZM4.80078 23.3813H22.8008V1.78125H4.80078V5.9351H14.4008V7.59663H20.5099V9.25817H14.4008V10.9197H20.5099V12.5813H14.4008V14.2428H20.5099V15.9043H14.4008V19.6889H4.80078V23.3813Z"
      fill="#3E4450"
    />
  </StyledSvg>
);
