import { FC } from "react";
import { Error500Screen } from "../../features/errors";

type Error500PageProps = {};

const Error500Page: FC<Error500PageProps> = () => {
  return <Error500Screen />;
};

export default Error500Page;
