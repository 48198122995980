import styled from "styled-components";

const DownloadIcon = () => {
  return (
    <SvgWrapped xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path d="M12.75 7.5V12.75H2.25V7.5H0.75V12.75C0.75 13.575 1.425 14.25 2.25 14.25H12.75C13.575 14.25 14.25 13.575 14.25 12.75V7.5H12.75ZM8.25 8.0025L10.1925 6.0675L11.25 7.125L7.5 10.875L3.75 7.125L4.8075 6.0675L6.75 8.0025V0.75H8.25V8.0025Z" fill="#707882" />
    </SvgWrapped>
  );
};

const SvgWrapped = styled.svg`
  margin: 0 5px;
`;

export default DownloadIcon;
