import { TextField } from "@mui/material";
import { FC, ReactElement } from "react";
import { Form, Formik, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { createWorkspaceEntityAdmin } from "api/useCreateWorkspaceAdmin";

import { TWorkspaceAdmin } from "../../../types/workspace";
import Button from "components/button/Button";
import CustomModal from "components/modal/Modal";
import MainStore from "MainStore"

type ModalChnagePasswordProps = {
  open: boolean;
  hide: (state: boolean) => void;
  onSave: () => void;
  workspaceId: number;
  currentAdmins: TWorkspaceAdmin[];
};

const ModalCreateWorkspaceAdmin: FC<ModalChnagePasswordProps> = (
  props
): ReactElement => {

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("")
      .test(
        "is-unique",
        "There is an existing user with the same email address",
        value => !props.currentAdmins.find(admin => admin.email === value && admin.userRole === "Workspace admin")
      ),
  });

  const submitForm = async (
    values: { email: string },
    { setFieldError }: FormikHelpers<{ email: string }>
  ) => {
    let { email } = values;

    try {
      MainStore.changeLoader(true);
      const response = await createWorkspaceEntityAdmin({ email, workspaceId: props.workspaceId });
      let data = response?.data ?? response?.response?.data;
      if (data?.isExists) {
        MainStore.setSnackbar("There is an existing user with the same email address", "error")
      } else if (data?.isSend) {
        MainStore.setSnackbar(`An invite has been sent to ${email}`, "success");
      }
      // this is a temporary error handler
      else if (data?.error) {
        MainStore.setSnackbar(data?.error, "error");
      } else {
        MainStore.setSnackbar(`User ${email} added as admin`, "success");
        props.onSave();
        props.hide(false);
      }
    } catch (err) {
      MainStore.setSnackbar("Invite was unsuccessful. Please check Sendgrid and open a ticket for RS", "error")
    } finally {
      MainStore.changeLoader(false);
    }

  };
  return (
    <CustomModal open={props.open} hide={props.hide} width="468">
      <StyledModalTitle id="Workspace_PopUpCreate_workspaceAdmin_Title">Add workspace admin</StyledModalTitle>
      <Description id="Workspace_PopUpCreate_workspaceAdmin_Description">
        An email invite will be sent immediately to them.
      </Description>
      <StyledModalContent aria-label={"Create workspace form"}>
        <Formik
          initialValues={{ email: "" }}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={validationSchema}
          onSubmit={submitForm}
        >
          {({ isValid, dirty, values, handleChange, handleBlur }) => (
            <Form>
              <InputLabel id="Workspace_PopUpCreate_workspaceAdmin_Email_Label">Email address</InputLabel>

              <StyledInput
              id="Workspace_PopUpCreate_workspaceAdmin_Input"
                name="email"
                placeholder="Enter email address"
                type="email"
                variant="standard"
                error={!isValid}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />

              <ErrorMessage
              id="Workspace_PopUpCreate_workspaceAdmin_InputError_box"
              
              name="email">
                {msg => <StyledError id="Workspace_PopUpCreate_workspaceAdmin_InputError">{msg}</StyledError>}
              </ErrorMessage>

              <ButtonWrapper>
                <StyledNextButton
                id="Workspace_PopUpCreate_workspaceAdmin_AddWorkspace_Btn"
                  variant="outlined"
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  Add workspace admin
                </StyledNextButton>
                <StyledCancelButton
                id="Workspace_PopUpCreate_workspaceAdmin_Cansel_Btn"
                  variant="outlined"
                  onClick={() => props.hide(false)}
                >
                  Cancel
                </StyledCancelButton>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalCreateWorkspaceAdmin;

const StyledModalTitle = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const StyledError = styled.div`
  color: var(--colorPaletteRedForeground2);
`

const StyledModalContent = styled.div``;

const StyledInput = styled(TextField)`
  width: 100%;
  margin-top: 6px;

  label {
    margin-top: 24px;
  }

  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--colorNeutralForeground1);
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px !important;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border: 1px solid var(--colorNeutralForeground1) !important;
`;

const StyledNextButton = styled(Button)`
  width: 100%;
  max-width: 180px;
  height: 36px;
  font-weight: 500 !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-style: normal;
  :disabled {
    background: var(--colorNeutralBackground5) !important;
    color: var(--colorNeutralForeground2) !important;
  }
`;

const Description = styled.p`
  width: 100%;
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 16px 0 40px 0;
  margin: 0;
`;

const InputLabel = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
`;
