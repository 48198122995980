import React, { useState } from "react";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import styled from "styled-components";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";

interface DatePickerComponentProps {
  startValue: dayjs.Dayjs;
  endValue: dayjs.Dayjs;
  error: {
    start: boolean;
    end: boolean;
  };
  helperText: {
    start: string;
    end: string;
  };
  onChange: (key: any, value: any) => void;
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [isMonthChanging, setIsMonthChanging] = useState(false);
  const [hoverEndDate, setHoverendDate] = useState<dayjs.Dayjs | any>(null);
  const [dateRange, setDateRange] = useState({
    strbegin_date: props.startValue || (null as dayjs.Dayjs | null),
    strend_date: props.endValue || (null as dayjs.Dayjs | null),
  });

  const theme = (theme: any) =>
    createTheme({
      components: {
        ...theme,
        MuiPickersDay: {
          styleOverrides: {
            root: ({ ownerState }) => {
              const styles: { [key: string]: string | number } = {};
              const day = dayjs(ownerState.day);

              if (dateRange) {
                const start = dayjs(dateRange.strbegin_date).startOf("day");
                const end = dayjs(dateRange.strend_date).endOf("day");
                const hoverEnd = dayjs(hoverEndDate).endOf("day");

                if (day.isSame(start, "day")) {
                  if (start?.isValid() && end?.isValid()) {
                    styles.borderTopLeftRadius = "50%";
                    styles.borderBottomLeftRadius = "50%";
                    styles.borderTopRightRadius = 0;
                    styles.borderBottomRightRadius = 0;
                  } else {
                    styles.borderRadius = "50%";
                  }
                  if (start.format("DD/MM/YYYY") === end.format("DD/MM/YYYY")) {
                    styles.borderRadius = "50%";
                  }
                  styles.backgroundColor = "rgba(25, 118, 210, 0.12)";
                } else if (day.isSame(end, "day")) {
                  if (start?.isValid() && end?.isValid()) {
                    styles.borderTopRightRadius = "50%";
                    styles.borderBottomRightRadius = "50%";
                    styles.borderTopLeftRadius = 0;
                    styles.borderBottomLeftRadius = 0;
                  } else {
                    styles.borderRadius = "50%";
                  }
                  styles.backgroundColor = "rgba(25, 118, 210, 0.12)";
                } else if (day.isAfter(start) && day.isBefore(end)) {
                  styles.backgroundColor = "rgba(25, 118, 210, 0.12)";
                  styles.borderRadius = 0;
                } else if (day.isSame(hoverEnd, "day")) {
                  styles.border = "1px solid";
                  styles.transition = "0.1s";
                } else if (
                  day.isAfter(start) &&
                  day.isBefore(hoverEnd, "day")
                ) {
                  styles.borderRadius = 0;
                  styles.borderTop = "2px dashed rgba(0, 0, 0, 0.42)";
                  styles.borderBottom = "2px dashed rgba(0, 0, 0, 0.42)";
                }
              }
              return {
                ...styles,
                margin: "2px 0",
              };
            },
          },
        },
        MuiDateCalendar: {
          styleOverrides: {
            root: {
              width: "100%",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              borderRadius: 0,
            },
          },
        },
      },
    });

  const handleDateChange = (
    key: "strbegin_date" | "strend_date",
    date: dayjs.Dayjs | null
  ) => {
    setDateRange((prev) => ({ ...prev, [key]: date }));
    props.onChange(key, date);
  };

  const openHandler = () => {
    setOpen(true);
  };

  const closeHandler = () => {
    if (
      !isMonthChanging &&
      dateRange.strbegin_date?.isValid() &&
      dateRange.strend_date?.isValid()
    ) {
      setOpen(false);
    }
  };

  const closeChange = () => {
    setIsMonthChanging(true);
    setOpen(true);
    setTimeout(() => setIsMonthChanging(false), 500);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePickerWrapper>
          <DatePicker
            value={dateRange.strbegin_date}
            onChange={(date) => handleDateChange("strbegin_date", date)}
            format="DD/MM/YYYY"
            onOpen={openHandler}
            onClose={closeHandler}
            open={open}
            minDate={dayjs()}
            maxDate={dateRange.strend_date}
            onMonthChange={closeChange}
            onViewChange={closeChange}
            slotProps={{
              textField: {
                label: "Start Date",
                variant: "standard",
                error: props.error.start,
                helperText: props.helperText.start,
              },
              desktopPaper: {
                style: { boxShadow: "-5px 5px 11px -4px rgba(0,0,0,0.75)" },
              },
            }}
          />
          <Box sx={{ mx: 2 }}> to </Box>
          <DatePicker
            value={dateRange.strend_date}
            onChange={(date) => handleDateChange("strend_date", date)}
            format="DD/MM/YYYY"
            onOpen={openHandler}
            onClose={closeHandler}
            open={open}
            minDate={
              dateRange.strbegin_date?.isValid()
                ? dateRange.strbegin_date
                : dayjs()
            }
            onMonthChange={closeChange}
            onViewChange={closeChange}
            slotProps={{
              textField: {
                label: "End Date",
                variant: "standard",
                error: props.error.end,
                helperText: props.helperText.end,
              },
              desktopPaper: {
                style: {
                  boxShadow: "5px 5px 11px -4px rgba(0,0,0,0.75)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.60)",
                },
              },
              day: {
                onMouseEnter: (_, day) => setHoverendDate(day),
                onMouseLeave: () => setHoverendDate(null),
              },
            }}
          />
        </DatePickerWrapper>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DatePickerComponent;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
