import { useMutation } from "@tanstack/react-query";

import http from "./config/https";

export type CheckCorrectWorspaceNameSuccessResponseData = {};

const checkCorrectWorspaceName = async (workspaceData: { name: string }) => {
  if (http.isAPIGO) {
    const { data } = await http.postGO(`/workspace/checkname`, { name: workspaceData.name });
    return data;
  } else {
    const { data } = await http.post(`/workspace/checkname`, { name: workspaceData.name });
    return data;
  }
};

export const useCheckCorrectWorspaceName = () => {
  return useMutation({
    mutationFn: checkCorrectWorspaceName,
  });
};
