import { useState } from "react";

type SortModel = {
  field: string | null;
  sort: string | null;
};

const useSortedData = (data: any, initialSortModel: SortModel) => {
  const [sortModel, setSortModel] = useState(initialSortModel);

  const changeSort = (field: string, sort: string) => {
    setSortModel({ field, sort });
  };

  const sortedData =
    data &&
    [...data].sort((a, b) => {
      if (sortModel.sort === "asc") {
        if (sortModel.field === "created_at") {
          return (
            new Date(a[sortModel.field]).getTime() -
            new Date(b[sortModel.field]).getTime()
          );
        }
        if (a[sortModel.field] < b[sortModel.field]) return -1;
        if (a[sortModel.field] > b[sortModel.field]) return 1;
      } else if (sortModel.sort === "desc") {
        if (sortModel.field === "created_at") {
          return (
            new Date(b[sortModel.field]).getTime() -
            new Date(a[sortModel.field]).getTime()
          );
        }
        if (a[sortModel.field] > b[sortModel.field]) return -1;
        if (a[sortModel.field] < b[sortModel.field]) return 1;
      }
      return 0;
    });

  return { sortedData, changeSort, sortModel };
};

export default useSortedData;
