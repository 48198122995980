const EmpoyeesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_2744_63797)">
        <path
          d="M10.6641 7.3335C11.7707 7.3335 12.6574 6.44016 12.6574 5.3335C12.6574 4.22683 11.7707 3.3335 10.6641 3.3335C9.55739 3.3335 8.66406 4.22683 8.66406 5.3335C8.66406 6.44016 9.55739 7.3335 10.6641 7.3335ZM5.33073 7.3335C6.4374 7.3335 7.32406 6.44016 7.32406 5.3335C7.32406 4.22683 6.4374 3.3335 5.33073 3.3335C4.22406 3.3335 3.33073 4.22683 3.33073 5.3335C3.33073 6.44016 4.22406 7.3335 5.33073 7.3335ZM5.33073 8.66683C3.7774 8.66683 0.664062 9.44683 0.664062 11.0002V12.6668H9.9974V11.0002C9.9974 9.44683 6.88406 8.66683 5.33073 8.66683ZM10.6641 8.66683C10.4707 8.66683 10.2507 8.68016 10.0174 8.70016C10.7907 9.26016 11.3307 10.0135 11.3307 11.0002V12.6668H15.3307V11.0002C15.3307 9.44683 12.2174 8.66683 10.6641 8.66683Z"
          fill="#6E31FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2744_63797">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmpoyeesIcon;
