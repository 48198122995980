import { queryClient } from "providers/ReactQueryProvider";
import { useMutation } from "@tanstack/react-query";

import http from "./config/https";

export type RemoveMemberSuccessResponseData = {};

const removeMember = (memberDataId: number) =>
  http.get(`engage_rocket_admins/Delete?id=` + memberDataId, {}); //TODO delete request

export const useRemoveERMember = () => {
  return useMutation({
    mutationFn: removeMember,
    onSuccess: () => {
      queryClient.removeQueries(["engage_rocket_admins"]);
    },
  });
};
