import { FC } from "react";
import styled from "styled-components";

type ProgressLoaderProps = {};

const ProgressLoader: FC<ProgressLoaderProps> = () => {
  return (
    <LoaderWrapper id="Survey_ImportQuestions_ProgressLoader">
      <LoaderContent>
        <img src={"/assets/er_logo_loading.gif"} width={64} height={64} alt="EngageRocket Logo" />
        <LargeText id="Survey_ImportQuestions_ProgressLoader_Title">Uploading in progress...</LargeText>
        <SmallText id="Survey_ImportQuestions_ProgressLoader_Text">
          Feel free to navigate the platform; the process will continue seamlessly even if you move
          around.
        </SmallText>
      </LoaderContent>
    </LoaderWrapper>
  );
};

export default ProgressLoader;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
`;
const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 522px;
`;

const LargeText = styled.span`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  margin-top: 10px;
  text-align: center;
`;

const SmallText = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-top: 20px;
  text-align: center;
`;
