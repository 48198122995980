import { FC, useState } from "react";
import { observer } from "mobx-react";
import { Button, Grid } from "@mui/material";

import store from "./store";
import styled from "styled-components";

interface ImageUploaderProps {
  logoURL?: string;
}

const ImageUploader: FC<ImageUploaderProps> = observer(({ logoURL }) => {
  const [mainState, setMainState] = useState("initial");

  const handleUploadClick = (event) => {
    const file = event.target.files[0];

    if (file && file.size < 1000000) {
      store.setData("imageUpload", file);
      setMainState("uploaded");
    } else {
      setMainState("size-error");
    }
  };

  return (
    <UploadedWrapper id="ImageUploader_UploadedWrapper" $state={mainState === "initial"}>
      <Grid item id="ImageUploader_Grid">
        {(mainState === "initial" || mainState === "size-error") && !logoURL ? (
          <UploadedImage
            id={`ImageUploader_UploadedImage_${mainState}`}
            $state={mainState === "initial"}
            src={"/assets/empty-template.png"}
          />
        ) : (
          <UploadedImage
            id="ImageUploader_UploadedImage_Uploaded"
            $state={mainState === "initial"}
            src={store.imageUpload ? URL.createObjectURL(store.imageUpload) : logoURL}
          />
        )}
      </Grid>
      <UploaderLabel id="ImageUploader_UploaderLabel" htmlFor="ImageUploader_FileInput">
        <UploaderHelpText id="ImageUploader_UploaderHelpText">
          {mainState === "initial" &&
            `The image resolution must be 122(w) x 78(h) and the size must not exceed 1MB.`}
          {mainState === "uploaded" && (
            <>
              <img src={"/assets/success-upload.png"} alt="success_upload" id="ImageUploader_SuccessIcon" />
              Your image has a resolution of 122 (W) x 78 (H) and is no larger than 1 MB.
            </>
          )}
          {mainState === "size-error" && (
            <span id="ImageUploader_SizeErrorText">Your image is larger than 1MB.</span>
          )}
        </UploaderHelpText>
        <Button variant="outlined" component="span" id={`ImageUploader_Button_${mainState}`}>
          {mainState === "initial" || mainState === "size-error"
            ? "Upload image"
            : "Upload another image"}
        </Button>
        <input
          accept="image/*"
          id="ImageUploader_FileInput"
          type="file"
          onChange={handleUploadClick}
          style={{ display: "none" }}
        />
      </UploaderLabel>
    </UploadedWrapper>
  );
});

export default ImageUploader;

const UploadedWrapper = styled.div<{ $state: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.$state ? "60px 1fr" : "122px 1fr"};
  column-gap: 32px;
`;

const UploaderLabel = styled.label``;

const UploaderHelpText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--colorShadowInverted2);
  margin-bottom: 6px;
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }

  span {
    color: var(--colorPaletteRedForeground2);
  }
`;

const UploadedImage = styled.img<{ $state: boolean }>`
  width: ${(props) => (props.$state ? "60px" : "122px")};
  height: ${(props) => (props.$state ? "60px" : "82px")};
  object-fit: contain !important;
`;
