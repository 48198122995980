import { FC } from "react";
import styled from "styled-components";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import { GetEntitiesByWorkSpaceSuccessResponseData } from "api/sharp/useGetEntitiesByWorkspace";

import CustomButton from "components/button/Button";
import erLogo from "assets/images/er-logo-img.svg";
import SeatsIcon from "./icons/SeatsIcon";
import storeWorkSpaceSettings from "../../workspace-settings/store";
import store from "./store";
import AdminIcon from "./icons/AdminIcon";
import EmpoyeesIcon from "./icons/EmpoyeesIcon";
import { SIGN_IN_METHOD_OPTIONS_FOR_CARD } from "constants/signInOptions";

type WorkspaceCardProps = {
  id?: string;
  cardContent: GetEntitiesByWorkSpaceSuccessResponseData[0];
};

const WorkspaceCard: FC<WorkspaceCardProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Container>
      <TopSide>
        <LogoWrapper>
          <img
            src={props.cardContent.logo_url ? props.cardContent.logo_url : erLogo}
            style={{ maxWidth: 100, maxHeight: 100 }}
            alt={"company-logo"}
          />
        </LogoWrapper>

        <EditButton
          id={props.id && `${props.id}_EditBtn`}
          onClick={() => {
            store.changeHandler("currentEntityId", props.cardContent.id);
            store.changeOpenPanelAddEditEntity(true);
          }}
        >
          <ModeEditSharpIcon
            sx={{ fontSize: 16 }}
            style={{ color: "var(--colorBrandForeground1)" }}
          />
        </EditButton>
      </TopSide>
      <InfoWrapper>
        <Title id={props.id && `${props.id}_Title`} $disabled={props.cardContent.disabled}>
          {props.cardContent.name}
        </Title>

        <StyledMethodSign id={props.id && `${props.id}_Subtitle`}>
          Sign in via {SIGN_IN_METHOD_OPTIONS_FOR_CARD[props.cardContent.sign_in_method]}
        </StyledMethodSign>

        {!props.cardContent.disabled && <Divider style={{ margin: "10px 0" }} />}
        {props.cardContent.disabled && (
          <DisabledText id={props.id && `${props.id}_YiurEntityIsBeuing_Text`}>
            Your entity is being set up in the meantime.
          </DisabledText>
        )}
        {!props.cardContent.disabled && (
          <>
            <AdminCounter>
              <EmpoyeesIcon />
              <span id={props.id && `${props.id}_Empoyees`} style={{ paddingLeft: "3px" }}>
                {props.cardContent.settings.employee_count_total || 0} employees
              </span>
            </AdminCounter>
            <AdminCounter>
              <AdminIcon />
              <span id={props.id && `${props.id}_EntityAdmin`} style={{ paddingLeft: "3px" }}>
                {props.cardContent.settings.admin_count_total || 0} entity admin
              </span>
            </AdminCounter>
          </>
        )}
        <StyledSubscriptionContainer>
          <div>
            <SeatsIcon />
            <div>
              <p id={props.id && `${props.id}_ActiveSeats`}>
                {`Active seats: ${props.cardContent.settings.active_seat_count_total || 0}/${props.cardContent.settings.active_seat_count || 0}`}
              </p>
              <p id={props.id && `${props.id}_Features`}>
                {`Features: ${props.cardContent.settings.features_count_total || 0}/${props.cardContent.settings.features_count || 0}`}
              </p>
            </div>
          </div>
          <CustomButton
            id={props.id && `${props.id}_Subscription`}
            onClick={() => {
              navigate(`/workspace-settings/${props.cardContent.workspace_id}`);
              storeWorkSpaceSettings.setData("selectedEntity", props.cardContent);
            }}
          >
            Subscription
          </CustomButton>
        </StyledSubscriptionContainer>
      </InfoWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 32px 32px 20px 32px;
  height: 100%;
  background-color: var(--colorNeutralBackground1);
  border-radius: 15px;
`;

const TopSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--graySelectBoxBorder);
  border-radius: 8px;
`;

const InfoWrapper = styled.div`
  margin-top: 24px;
`;

const Title = styled.h1<{ $disabled: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: ${(props) =>
    props.$disabled ? "var(--colorNeutralForeground12)" : "var(--colorNeutralForeground1)"};
  margin: 0;
`;

const StyledMethodSign = styled.p`
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  margin-top: 5px;
`;

const DisabledText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  margin: 25px 0;
  align-items: center;
  line-height: 10px;
  text-align: left;
  color: var(--colorNeutralForeground12);
`;

const AdminCounter = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  margin-top: 10px;
  align-items: center;
  line-height: 10px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const EditButton = styled.button`
  position: relative;
  top: 0;
  right: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--colorPalleteLightBlue);
  transition: background-color 0.3s;

  :hover {
    background-color: var(--colorNeutralBackground3);
  }
`;

const StyledSubscriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  background-color: var(--colorPaletteBlueBackground2);
  padding: 10px;
  border-radius: 10px;
  width: 100%;

  @media (max-width: 1130px) {
    flex-direction: column;
    align-items: flex-start;

    button {
      margin-left: 21px;
    }
  }

  @media (max-width: 899px) {
    flex-direction: row;
    align-items: center;

    button {
      margin-left: 0;
    }
  }

  > div {
    display: flex;
    align-items: start;
    gap: 6px;
    p {
      color: var(--colorNeutralForeground1);
      font-family: Roboto, sans-serif;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export default WorkspaceCard;
