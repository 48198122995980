import { FC } from "react";
import { Alert, Snackbar } from "@mui/material";
import { SuccessLogoutIcon } from "./SuccessLogoutIcon";

import styled from "styled-components";

type LogOutAlertProps = {
  open: boolean;
  email: string;
  handleClose: () => void;
};

export const LogOutAlert: FC<LogOutAlertProps> = (props) => {
  return (
    <StyledSnackBar
      open={props.open}
      autoHideDuration={5000}
      onClose={props.handleClose}
    >
      <StyledAlert severity="success" sx={{ width: "100%" }} icon={false}>
        <SuccessLogoutIcon />
        You have signed out as {props.email}.
      </StyledAlert>
    </StyledSnackBar>
  );
};

const StyledSnackBar = styled(Snackbar)`
  .MuiAlert-standardSuccess {
    background-color: var(--colorNeutralForeground1);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledAlert = styled(Alert)`
  .MuiAlert-action {
    display: none;
  }

  .MuiAlert-message {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colorNeutralBackground1);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    svg {
      margin-right: 10px;
    }
  }
`;
