import { FC } from "react";
import { TemplatesScreen } from "features/templates";
import { SurveyProject } from "features/survey-project-view";

type SurveyProjectPageProps = {};

const SurveyProjectPage: FC<SurveyProjectPageProps> = () => {
  return <SurveyProject />;
};

export default SurveyProjectPage;
