import { FC } from "react";
import { Error404Screen } from "../../features/errors";

type Error404PageProps = {};

const Error404Page: FC<Error404PageProps> = () => {
  return <Error404Screen />;
};

export default Error404Page;
