import http from "api/config/https";

export const GetAdminsForWorkspace = async (id: number): Promise<any> => {
  try {
    if(http.isAPIGO){
      return http.getGO('/permission/GetAdminsForWorkspace?workspace_id=' + id)
    } else {
      return http.get('/account_role/GetAdminsForWorkspace?workspace_id=' + id)
    }  
  } catch (error) {
    throw error;
  }
};
