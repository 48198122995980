import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import BenchmarksTable from "./BenchmarksTable";
import ImportIcon from "../icons/ImportIcon";
import store from './store'
import DeleteBenchmarkPopup from "./DeleteBenchmark";

type BenchmarksProps = {};

const Benchmarks: FC<BenchmarksProps> = observer(() => {

  return (
    <Container id="Survey_View_Benchmarks_Title">
      <QuestionExportWrap>
        <QuestionExport>
          <ImportIcon />
          Custom Benchmarks (xlsx){" "} : <Import href={"/survey-projects/import-benchmarks/" + store.surveyId}>Import</Import>
        </QuestionExport>
      </QuestionExportWrap>
      <BenchmarksTable />
      <DeleteBenchmarkPopup />
    </Container>
  );
});

const Container = styled.div`
`;

const QuestionExportWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`

const QuestionExport = styled.div`
  display: flex;
  padding: 10px;
  border-radius: var(--borderRadius, 4px);
  background: var(--colorNeutralForeground4, #F1F3F8);
  align-items: center;
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  width: 280px;
`

const Import = styled.a`
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  text-decoration: none;
  color: var(--colorBrandForeground1, #1477F8);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`

export default Benchmarks;
