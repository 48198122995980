import { FC } from "react";
import { observer } from "mobx-react";
import { Dialog, DialogContent, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";

type DeleteTemplatePopupProps = {};

const DeleteTemplatePopup: FC<DeleteTemplatePopupProps> = observer(() => {
  return (
    <StyledDialog
      id="DeleteTemplatePopup_Dialog"
      open={store.isOpenRemoveTemplatePopup}
      onClose={() => store.setData("isOpenRemoveTemplatePopup", false)}
    >
      <CloseBtnWrapper id="DeleteTemplatePopup_CloseBtnWrapper">
        <CloseButton
          id="DeleteTemplatePopup_CloseButton"
          onClick={() => store.setData("isOpenRemoveTemplatePopup", false)}
        >
          <ClearIcon id="DeleteTemplatePopup_ClearIcon" />
        </CloseButton>
      </CloseBtnWrapper>
      <DialogTitle id="DeleteTemplatePopup_DialogTitle">
        Are you sure you want to delete the template “
        {store.selectedTemplate && store.selectedTemplate.name}”?
      </DialogTitle>

      <StyledDialogContent id="DeleteTemplatePopup_DialogContent">
        <Description id="DeleteTemplatePopup_Description1">
          Questionnaires that have previously used this template will not be
          affected. However, workspaces/entities will no longer be able to view
          this template on their platform.
        </Description>
        <Description id="DeleteTemplatePopup_Description2">
          To confirm your deletion, please enter DELETE below:
        </Description>
        <StyledTextField
          id="DeleteTemplatePopup_TextField"
          value={store.deleteTemplateInput}
          onChange={(e) => {
            store.setData("deleteTemplateInput", e.target.value);
          }}
          fullWidth
          variant="standard"
          placeholder="DELETE"
        />
        <ButtonWrapper id="DeleteTemplatePopup_ButtonWrapper">
          <DeleteCustomButton 
            id="DeleteTemplatePopup_DeleteButton"
            variant="contained" 
            type="submit" 
            disabled={store.deleteTemplateInput !== "DELETE"}
            onClick={() => { store.RemoveTemplate() }}>
            Delete template
          </DeleteCustomButton>
          <CancelCustomButton
            id="DeleteTemplatePopup_CancelButton"
            variant="outlined"
            onClick={() => store.setData("isOpenRemoveTemplatePopup", false)}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default DeleteTemplatePopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 522px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 64px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
  padding: 10px 64px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 60px 0px;
`;

const DeleteCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  color: var(--colorNeutralForeground2) !important;
  border-color: var(--colorNeutralForeground2) !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-top: 12px;
`;

const StyledTextField = styled(TextField)`
  margin-top: 15px !important;
`;
