import { MESSAGES } from "constants/messages";
import { TTemplate, typeQuestionary, typeRecomendedFrequancy } from "types/template";
import { RemoveTemplate } from "api/useRemoveTemplate";
import { makeAutoObservable, runInAction } from "mobx";
import { useCreateERTemplate } from "api/useCreateERTemplate";
import { getTemplTypeQuestionary } from "api/useGetTemplTypeQuestionary";
import {getTemplRecomendedFrequancy} from "api/UseGetTemplRecomendedFrequancy";
import {useEditERTemplate} from "api/useEditERTemplate";

import GetERTemplates from "api/useGetERTemplates";
import MainStore from "MainStore";

class Store {
  templatesFilter: string = "";

  selectedTemplate: TTemplate | null = null;

  isOpenRemoveTemplatePopup: boolean = false;
  isOpenManageTemplatePopup: boolean = false;
  isOpenUploadTemplatePopup: boolean = false;
  isOpenEditTemplatePopup: boolean = false;

  deleteTemplateInput: string = "";
  searchedTemplateValue: string = "";

  templateList: TTemplate[] = [];
  typesQuestionary: typeQuestionary[] = [];
  typeRecomendedFrequancy: typeRecomendedFrequancy[] = [];

  workspaceEntityList = [
    {
      workspaceNmae: "Nike",
      workspaceId: "2491WR",
      entity: [
        {
          entityName: "Malaysia",
          entityId: "R13431",
        },
        {
          entityName: "Singapore",
          entityId: "H64J37",
        },
        {
          entityName: "Canada",
          entityId: "L8653N",
        },
      ],
    },
    {
      workspaceNmae: "Amazon",
      workspaceId: "2491WR2",
      entity: [
        {
          entityName: "Malaysia",
          entityId: "RT6343",
        },
        {
          entityName: "Singapore",
          entityId: "163741",
        },
        {
          entityName: "Canada",
          entityId: "163441",
        },
      ],
    },
  ];

  selectedEntity = [];

  templateTitle: string = "";
  is_default_template: boolean = false;
  freequency: string = "";
  tag: string = "";
  frequency_name: string = "";
  type_questionary_name: string = "";
  summary: string = "";
  imageUpload: Blob | null = null;
  xlsUpload: Blob | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  settypeQuestionary = (data: typeQuestionary[]) => {
    this.typesQuestionary = data;
  };

  // settypeQuestionary = (data: typeQuestionary[]) => {
  //   this.typesQuestionary = data.map((x, index) => index === 2 ? { ...x, code: x.code.replace(/^\w/, (c) => c.toUpperCase())} : x);
  // };
  

  setRecomendedFrequancy = (data: typeRecomendedFrequancy[]) => {
    this.typeRecomendedFrequancy = data;
  };
  // setRecomendedFrequancy = (data: typeRecomendedFrequancy[]) => {
  //   this.typeRecomendedFrequancy = data.map((x, index) => index === 2 ? { ...x, code: x.code.replace(/^\w/, (c) => c.toUpperCase()) } : x);
  // };

  loadtypeQuestioonary = () => {
    MainStore.changeLoader(true);
    getTemplTypeQuestionary().then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.settypeQuestionary(x.data);
      }
    });
  };

  loadTemplRecomendedFrequancy = () => {
    MainStore.changeLoader(true);
    getTemplRecomendedFrequancy().then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.setRecomendedFrequancy(x.data);
      }
    });
  };

  // loadUploadTemplate = () => {
  //   MainStore.changeLoader(true);
  //   getUploadTemplate().then((x) => {
  //     MainStore.changeLoader(false);
  //     if (x?.data !== undefined) {
  //       this.setRecomendedFrequancy(x.data);
  //       console.log(x.data, "Data")
  //     }
  //   });
  // };

  loadERTemplates = () => {
    let filter = "all";
    if (this.templatesFilter === "Surveys") {
      filter = "surveys";
    } else if (this.templatesFilter === "Millestones") {
      filter = "milestones";
    } else if (this.templatesFilter === "Reviews") {
      filter = "reviews";
    }

    GetERTemplates(filter).then((x) => {
      this.templateList = x;
    });
  };

  saveTemplate = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await useCreateERTemplate(
        store.templateTitle,
        store.freequency,
        store.tag,
        store.is_default_template,
        store.summary,
        store.imageUpload,
        store.xlsUpload
      );
      if (response.status === 200 || response.status === 201) {
        MainStore.setSnackbar(`“${this.templateTitle}” has been uploaded successfully.`,
          "success"
        );
        this.loadERTemplates();
        this.loadtypeQuestioonary();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar(MESSAGES.error_create_template, "error");
    } finally {
      this.clearStore();
      this.isOpenUploadTemplatePopup = false;
      MainStore.changeLoader(false);
    }
  };

  RemoveTemplate = async () => {
    try {
      MainStore.changeLoader(true);
      await RemoveTemplate(this.selectedTemplate.id)
         runInAction(() =>{
           this.loadERTemplates();
           this.deleteTemplateInput = "";
           MainStore.setSnackbar(
             `You removed template "${this.selectedTemplate?.name}"`,
             "success"
           );
           store.setData("isOpenRemoveTemplatePopup", false);
           store.setData("selectedTemplate", null);
           MainStore.changeLoader(false);
         })
    } catch (error) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  saveEditTemplate = async (data: TTemplate) => {
    data.frequency_name = data.frequency_name.toLowerCase().replace(/\s/g, '')
    data.type_questionary_name = data.type_questionary_name.toLowerCase().replace(/\s/g, '')
      MainStore.changeLoader(true);
      const response = await useEditERTemplate(data);
      if (response.status === 200 || response.status === 201) {
        MainStore.setSnackbar(`Details for ${this.selectedTemplate?.name} have been successfully updated.`, "success");
        this.loadERTemplates();
        // this.clearStore();
        this.isOpenEditTemplatePopup = false;
        MainStore.changeLoader(false);
      } else throw new Error();
    } 

     


  doLoad() {
    this.loadERTemplates();
    this.loadtypeQuestioonary();
    this.loadTemplRecomendedFrequancy();
  }

  clearStore = () => {
    this.templateTitle = "";
    this.is_default_template = false;
    this.freequency = "";
    this.tag = "";
    this.summary = "";
    this.imageUpload = null;
    this.xlsUpload = null;
  };
  

  setData = (field: string, value: any) => {
    this[field] = value;

    if (field === "templatesFilter") {
      this.loadERTemplates();
    }
  };
}

const store = new Store();
export default store;
