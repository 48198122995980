import  WorkspacesScreen  from "features/workspaces/WorkspacesScreen";
import { FC } from "react";

type WorkspacesPageProps = {};

const WorkspacesPage: FC<WorkspacesPageProps> = () => {
  return <WorkspacesScreen />;
};

export default WorkspacesPage;
