import { Input } from "@mui/material";
import { FC, ReactElement } from "react";
import { useCreateERMember } from "api/useCreateERMember";
import { Form, Formik, FormikHelpers, ErrorMessage } from "formik";

import * as Yup from "yup";
import styled from "styled-components";
import Button from "components/button/Button";
import CustomModal from "components/modal/Modal";
import MainStore from "MainStore";

type ModalCreateMemberProps = {
  id?: string
  open: boolean;
  hide: (state: boolean) => void;
};

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Incorrect email"),
    // .matches(/@engagerocket.co$/, "Only ER employees can be added."),
  name: Yup.string().required("Preferred name is required"),
});

const ModalCreateMember: FC<ModalCreateMemberProps> = (props): ReactElement => {
  const { mutateAsync, isLoading } = useCreateERMember();

  const submitForm = async (
    values: { name: string; email: string },
    {
      setFieldError,
    }: FormikHelpers<{
      email: string;
      name: string;
    }>
  ) => {
    let { name, email } = values;
    try {
      MainStore.changeLoader(true)
      await mutateAsync({ name, email });
      MainStore.changeLoader(false)
      props.hide(false)
    } catch (err: any) {
      setFieldError("email", "This member has already been added.");
      MainStore.changeLoader(false)
    }
  };

  return (
    <CustomModal open={props.open} hide={props.hide} width="468">
      <StyledModalTitle
       id={props.id && `${props.id}_PopUpCreate_Title`}
      >Add new ER member</StyledModalTitle>
      <StyledModalContent aria-label={"Create workspace form"}>
        <Formik
          initialValues={{ email: "", name: "" }}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={schema}
          onSubmit={submitForm}
        >
          {({ values, handleChange, handleBlur }) => (
            <>
              <Form>
                <InputWrapper>
                  <StyledInput
                    name="email"
                    id={props.id && `${props.id}_PopUpCreate_Input_Email`}
                    placeholder="Enter email address"
                    type="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                  <ErrorMessage
                  id={props.id && `${props.id}_PopUpCreate_Error_Email`}
                  name="email">
                    {msg => <StyledError>{msg}</StyledError>}
                  </ErrorMessage>
                </InputWrapper>
                <InputWrapper>
                  <StyledInput
                    name="name"
                    id={props.id && `${props.id}_PopUpCreate_Input_Name`}
                    placeholder="Preferred name"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                  <ErrorMessage
                  id={props.id && `${props.id}_PopUpCreate_Error_Name`}
                  name="name">
                    {msg => <StyledError>{msg}</StyledError>}
                  </ErrorMessage>
                </InputWrapper>
                <ButtonWrapper>
                  <StyledNextButton
                  id={props.id && `${props.id}_PopUpCreate_ AddERMember_btn`}
                    variant="contained"
                    type="submit"
                    disabled={isLoading}
                  >
                    Add ER member
                  </StyledNextButton>
                  <StyledCancelButton
                  id={props.id && `${props.id}_PopUpCreate_ Cancel_btn`}
                    variant="outlined"
                    onClick={() => props.hide(false)}
                  >
                    Cancel
                  </StyledCancelButton>
                </ButtonWrapper>
              </Form>
            </>
          )}
        </Formik>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalCreateMember;

const StyledModalTitle = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
`;

const StyledError = styled.div`
color: var(--colorPaletteRedForeground2);
`

const StyledModalContent = styled.div``;

const InputWrapper = styled.div`
  margin-top: 40px;
`;

const StyledInput = styled(Input)`
  width: 100%;

  label {
    margin-top: 24px;
  }

  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--grayInputPlaceholder);
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border-color: var(--colorNeutralForeground1) !important;
`;

const StyledNextButton = styled(Button)`
  width: 100%;
  max-width: 150px;
  height: 36px;
  margin-right: 20px !important;
`;
