import styled from "styled-components";

const RemoveIcon = () => {
  return (
    <SvgWrapped xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
      <path d="M6.46867 5.99941L10.7968 1.67771C10.9262 1.54833 10.9988 1.37286 10.9988 1.18989C10.9988 1.00692 10.9262 0.831442 10.7968 0.702063C10.6674 0.572684 10.492 0.5 10.309 0.5C10.1261 0.5 9.95061 0.572684 9.82124 0.702063L5.5 5.03064L1.17876 0.702063C1.04939 0.572684 0.873935 0.5 0.690986 0.5C0.508036 0.5 0.332579 0.572684 0.203214 0.702063C0.073849 0.831442 0.0011726 1.00692 0.0011726 1.18989C0.0011726 1.37286 0.073849 1.54833 0.203214 1.67771L4.53133 5.99941L0.203214 10.3211C0.138822 10.385 0.0877136 10.461 0.0528354 10.5447C0.0179572 10.6284 0 10.7182 0 10.8089C0 10.8996 0.0179572 10.9894 0.0528354 11.0732C0.0877136 11.1569 0.138822 11.2329 0.203214 11.2968C0.26708 11.3612 0.343063 11.4123 0.42678 11.4472C0.510498 11.482 0.600293 11.5 0.690986 11.5C0.781678 11.5 0.871473 11.482 0.955191 11.4472C1.03891 11.4123 1.11489 11.3612 1.17876 11.2968L5.5 6.96819L9.82124 11.2968C9.88511 11.3612 9.96109 11.4123 10.0448 11.4472C10.1285 11.482 10.2183 11.5 10.309 11.5C10.3997 11.5 10.4895 11.482 10.5732 11.4472C10.6569 11.4123 10.7329 11.3612 10.7968 11.2968C10.8612 11.2329 10.9123 11.1569 10.9472 11.0732C10.982 10.9894 11 10.8996 11 10.8089C11 10.7182 10.982 10.6284 10.9472 10.5447C10.9123 10.461 10.8612 10.385 10.7968 10.3211L6.46867 5.99941Z" fill="#1477F8" />
    </SvgWrapped>
  );
};

const SvgWrapped = styled.svg`
  margin: 0 5px;
`;
export default RemoveIcon;
