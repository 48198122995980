import MainStore from "MainStore";
import { makeAutoObservable } from "mobx";
import { TSurvey, TBenchmarkParseExcelResult } from "types/survey";
import getSurveyInformation from "api/useGetSurveyInformation";
import { uploadBenchmarksExcelImport } from "api/useUploadBenchmarksExcelImport";

class Store {
  surveyId: number = 0;
  survey: TSurvey = null;

  file: File = null;
  fileName: string | null = null;
  importCurrentStep: string = "";
  showImportProgress: boolean = false;
  success: boolean = false;
  surveyParseResult: TBenchmarkParseExcelResult = null;

  constructor() {
    makeAutoObservable(this);
  }

  doLoad = (surveyId: number) => {
    this.setData("surveyId", surveyId);
    this.getSurveyData();
  };

  setSurveyData = (survey: TSurvey) => {
    this.survey = survey;
  };

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  setExcelParseResult = (surveyParseResult: TBenchmarkParseExcelResult) => {
    this.surveyParseResult = surveyParseResult;
    if (surveyParseResult.is_error) {
      this.setData("importCurrentStep", "Error");
    } else {
      this.setData("importCurrentStep", "ReviewChanges");
      MainStore.setSnackbar("Custom bechmark successfully imported")
    }
  };

  parseExcel = async () => {
    this.setData("importCurrentStep", "ProgressLoader");
    try {
      const formData = new FormData();
      formData.append("file", this.file);
      const response = await uploadBenchmarksExcelImport(
        this.surveyId,
        formData
      );
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setExcelParseResult(response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
      this.setData("importCurrentStep", "");
    } finally {
    }
  };

  // proceedParseExcel = async () => {
  //   try {
  //     MainStore.changeLoader(true);
  //     const response = await saveUploadQuestionsExcelImport(
  //       this.surveyParseResult.result
  //     );
  //     if (response.status === 200 || response.status === 201) {
  //       this.setData("importCurrentStep", "ReviewChanges");
  //     } else throw new Error();
  //   } catch (err) {
  //     MainStore.setSnackbar("Something went wrong", "error");
  //   } finally {
  //     MainStore.changeLoader(false);
  //   }
  // };

  getSurveyData = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getSurveyInformation(this.surveyId);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setSurveyData(response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
