import { FC } from "react";

import React from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";

type CustomButtonProps = {
  text?: string;
  variant?: "text" | "outlined" | "contained";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "submit" | "reset" | "button";
  href?: string;
  id?: string;
  disabled?: boolean;
  children?: any;
  size?: "small" | "medium";
  style?: any;
  color?: any;
  ref?: any;
  sx?: any;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

const CustomButton: FC<CustomButtonProps> = (props) => {
  const { text, ...rest } = props;
   return <StyledButton {...rest}>{props.children || props.text}</StyledButton>;
};

export default CustomButton;

const StyledButton = styled(Button)`
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  text-transform: none !important;
`;
