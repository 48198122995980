import { FC, ReactElement, useEffect, useState } from "react";
import { GetMyInfo } from "api/sharp/useGetMyInfo";
import { TMyAccount } from "types/accounstSettings";

import styled from "styled-components";
import Button from "components/button/Button";
import { observer } from "mobx-react";
import MainStore from "MainStore";

type AccountInfoCardProps = {
  handleVisibleResetPwdPopup: (state: boolean) => void;
};

const AccountInfoCard: FC<AccountInfoCardProps> = observer((props): ReactElement => {

  const [user, setUser] = useState<TMyAccount>(null);

  const getMyInfo = async () => {
    try {
      const response = await GetMyInfo();
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        setUser(response.data);
      } else throw new Error();
    } catch (error) {
      MainStore.setSnackbar("Cannot get my info", "error");
    }
  }

  useEffect(() => {
    getMyInfo()
  }, [])


  return (
    <Card>
      <RowLabel>Preferred name</RowLabel>
      <Text>{user?.preferred_name}</Text>
      <RowLabel>Full name</RowLabel>
      <Text>{user?.full_name}</Text>
      <RowLabel>Email address</RowLabel>
      <AccountWrapper>
        <Email>{user?.email}</Email>
        {/* <ChangePwdButton
          type="button"
          onClick={() => props.handleVisibleResetPwdPopup(true)}
          variant="contained"
        >
          Change password
        </ChangePwdButton> */}
      </AccountWrapper>
    </Card>
  );
});

export default AccountInfoCard;

const Card = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 610px;
  padding: 40px;
  border-radius: 10px;
  background-color: var(--colorNeutralBackground1);
  margin-right: 32px;
`;

const RowLabel = styled.h2`
  width: 100%;
  text-align: start;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
  color: var(--colorNeutralForeground2);
`;

const Text = styled.p`
  width: 100%;
  text-align: start;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0px 0px 24px 0px;
  color: var(--colorNeutralForeground3);
`;

const AccountWrapper = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px var(--borderColor);
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const ChangePwdButton = styled(Button)`
  height: 36px;
  max-width: 186px;
  margin-top: 18px;
  outline: none;
  margin-top: 20px !important;
`;

const Email = styled.span`
  width: 100%;
  text-align: start;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground3);
`;
