import { WorkspaceSettings } from "features/workspace-settings";
import { FC } from "react";

type WorkspacePageProps = {};

const WorkspacePage: FC<WorkspacePageProps> = () => {

  return <WorkspaceSettings />;
};

export default WorkspacePage;
