import { makeAutoObservable, reaction } from "mobx";
import { useRemoveActiveSeatsRows } from "api/useRemoveActiveSeatsRows";
import {
  TActiveSeat,
  TEntity,
  TEntityNumberPeople,
  TFeatureGroup,
} from "types/entity";
import { TWorkspaceSetting, TEntitySetting, TWorkspace } from "types/workspace";

import MainStore from "MainStore";
import getEntitiesSettings from "api/useGetEntitiesSettings";
import useGetAllWorkspaces from "api/useGetAllWorkspaces";
import useGetWoskspaceSetting from "api/useGetWoskspaceSetting";
import useGetEntityActiveSeats from "api/useGetEntityActiveSeats";
import useGetEntityNumberPeoples from "api/go/useGetEntityNumberPeoples";
import getEntityFeatures from "api/useGetEntityFeatures";
import { useSaveEntityFeatures } from "api/useSaveEntityFeatures";
import { GridSortModel } from '@mui/x-data-grid';

class Store {
  allWorkspaces: TWorkspace[] = [];
  workspaceSetting: TWorkspaceSetting = null;
  selectedEntity: TEntity = null;

  entitySetting: TEntitySetting = null;
  entitySettings: TEntitySetting[] = [];
  tabId = 0;
  openPanelEditWorkspaceSetting = false;
  openPanelEditEntitySetting = false;
  value = 0;
  selectedWorkspace: TWorkspace = null;
  hoverState = "";

  editedData = [];

  isOpenSaveFeatiresPopup: boolean = false;
  isOpenAddSeatsPopup: boolean = false;
  isOpenRemoveSeatsPopup: boolean = false;

  isShowSaveFeatureSettingsButton: boolean = false;

  features: TFeatureGroup[] = [];
  activeSeatsList: TActiveSeat[] = [];
  searchActiveSeatsValue: string = "";
  selectedActiveSeatsRows: number[] = [];
  newActiveSeatsEmails: string[] = [];
  newActiveSeatsEmail: string = "";
  activeSeatsTotalCount: number = 0;
  activeSeatsPageSize: number = 10;
  activeSeatsPage: number = 0;
  activeSeatsSortField: string = "";
  activeSeatsSortType: "asc" | "desc" | null = null;

  numberPeoplesList: TEntityNumberPeople[] = [];
  selectedNumberPeople: TEntityNumberPeople = null;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.searchActiveSeatsValue,
      (newSearchActiveSeatsValue) => {
        this.getEntityActiveSeats();
      },
      { delay: 1000 }
    );
  }

  setData = (field: string, value: any) => {
    this[field] = value;
  };


  changeHover(hoverState: string) {
    this.hoverState = hoverState;
  }

  changeValue(field: string, value: any) {
    this[field] = value;
  }

  changeWorkspace(value: TWorkspace) {
    this.selectedWorkspace = value;
    this.selectedEntity =
      value?.entities.length > 0 ? value?.entities[0] : null;
    this.getEntitiesSettings(value.id);
    this.getWoskspaceSetting(value.id);
  }

  changeFromGrid = (row: TEntitySetting, value: string, field: string) => {
    this.entitySettings.filter((x) => x.id == row.id)[0][field] = value;
    this.editedData = [
      ...this.editedData,
      {
        type: "entity",
        action: "updated",
        entity: this.entitySettings.filter((x) => x.id == row.id)[0],
      },
    ];
  };

  clearStore() {
    this.hoverState = "";
    this.editedData = [];
  }

  changeActiveSeatsPagination = (page: number, pageSize: number) => {
    this.activeSeatsPage = page;
    this.activeSeatsPageSize = pageSize;
    this.getEntityActiveSeats();
  };

  clearActiveSeatsTab = () => {
    this.activeSeatsPage = 0;
    this.setData("selectedActiveSeatsRows", []);
    this.setData("searchActiveSeatsValue", "");
  };

  changeSortActiveSeats = (sort: GridSortModel) => {
    if(sort.length === 0){
      this.activeSeatsSortField = ""
      this.activeSeatsSortType = null
    }else{
      this.activeSeatsSortField = sort[0].field
      this.activeSeatsSortType = sort[0].sort
    }
    this.getEntityActiveSeats()
  }

  clearFeaturesTab = () => {
    this.features = [];
    this.isShowSaveFeatureSettingsButton = false;
  };

  changeFeature = (
    featureGroupIndex: number,
    featureIndex: number,
    checked: boolean
  ) => {
    this.features[featureGroupIndex].features[featureIndex].hasFeature =
      checked;
    this.isShowSaveFeatureSettingsButton = true;
  };

  sendFeatureSettings = async () => {
    const hasAccessIds = this.features.flatMap((x) =>
      x.features.filter((y) => y.hasFeature).map((y) => y.id)
    );
    try {
      MainStore.changeLoader(true);
      const response = await useSaveEntityFeatures(
        this.selectedEntity?.id,
        hasAccessIds
      );
      if (response.status === 200 || response.status === 201) {
        this.setData("isShowSaveFeatureSettingsButton", false);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      this.isOpenSaveFeatiresPopup = false;
      MainStore.changeLoader(false);
    }
  };

  getEntitiesSettings = async (idWorkspace: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await getEntitiesSettings(idWorkspace);
      if (response.status === 200 || response.status === 201) {
        this.entitySettings = response.data;
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getEntityActiveSeats = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await useGetEntityActiveSeats(
        this.selectedEntity?.id,
        this.searchActiveSeatsValue,
        this.activeSeatsPage,
        this.activeSeatsPageSize,
        this.activeSeatsSortField,
        this.activeSeatsSortType
      );
      if (response.status === 200 || response.status === 201) {
        this.activeSeatsList = response.data.activeSeats;
        this.activeSeatsTotalCount = response.data.total;
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getFeatures = async () => {
    if (!this.selectedEntity) return;
    try {
      MainStore.changeLoader(true);
      const response = await getEntityFeatures(this.selectedEntity?.id);
      if (response.status === 200 || response.status === 201) {
        this.features = response.data;
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getEntityNumberPeoples = async (entityId: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await useGetEntityNumberPeoples(entityId);
      if (response.status === 200 || response.status === 201) {
        this.numberPeoplesList = [response.data];
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getWoskspaceSetting = async (idWorkspace: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await useGetWoskspaceSetting(idWorkspace);
      if (response.status === 200 || response.status === 201) {
        this.workspaceSetting = response.data;
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getAllWoskspaces = async (idWorkspace: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await useGetAllWorkspaces();
      if (response.status === 200 || response.status === 201) {
        this.allWorkspaces = response.data;
        const workspace = response.data.find((x) => x.id === idWorkspace);
        this.selectedWorkspace = workspace;
        this.selectedEntity = this.selectedEntity || (workspace?.entities.length > 0 ? workspace.entities[0] : null);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  removeActiveSeats = async (entityId: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await useRemoveActiveSeatsRows(
        entityId,
        this.selectedActiveSeatsRows
      );
      if (response.status === 200 || response.status === 201) {
        this.activeSeatsPage = 0;
        this.setData("selectedActiveSeatsRows", []);
        this.getEntityActiveSeats();
        this.getWoskspaceSetting(this.workspaceSetting?.id)
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      this.isOpenRemoveSeatsPopup = false;
      MainStore.changeLoader(false);
    }
  };

  loadData(idWorkspace: number) {
    this.getWoskspaceSetting(idWorkspace);
    this.getEntitiesSettings(idWorkspace);
    this.getAllWoskspaces(idWorkspace);
  }
}

const store = new Store();
export default store;
