import { FC } from "react";
import { observer } from "mobx-react";
import store from "../store";
import styled from "styled-components";
import { QuantityInput } from "components/number-input-counter/NumberInputCounter";
import WarningIcon from "../icons/WarningIcon";
import CustomButton from "components/button/Button";

type ThresholdProps = {};

const Threshold: FC<ThresholdProps> = observer(() => {

  return (
    <Container>

      <WarningBlock>
        <WarningIcon />
        <WarningText id="Survey_View_Confidentiality_WarningMessage">
          <WarningMainText id="Survey_View_Confidentiality_WarningMessage_TextFirst">
            The confidentiality threshold will apply to all surveys within the project {store.survey?.project_name}.
          </WarningMainText>
          <WarningDescription id="Survey_View_Confidentiality_WarningMessage_TextSecond">
            Kindly exercise caution while editing, as any changes made will impact how results are displayed on the survey dashboard.
          </WarningDescription>
        </WarningText>
      </WarningBlock>

      <Responses>
        <Text id="Survey_View_Confidentiality_Threshold_Description">Minimum responses required to display results</Text>
        <QuantityInput
          value={store.minResponses}
          onChange={(_, val) => store.changeMinResponse(val)}
        />
      </Responses>

      <SmallText id="Survey_View_Confidentiality_Description">
        Each segment will need at least{" "}
        <b>{store.minResponses > 0 ? store.minResponses : "1"} employees</b> in order to view
        results
      </SmallText>

      <Actions>
        <ButtonWrapper>
          <CustomButton id="Survey_View_Confidentiality_Save_Button" disabled={!store.thresholdChanged} onClick={() => store.saveThreshold()} variant="contained">
            Save
          </CustomButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <CustomButton id="Survey_View_Confidentiality_Save_Button" disabled={!store.thresholdChanged} onClick={() => store.onThresholdCancel()} variant="outlined">
            Cancel
          </CustomButton>
        </ButtonWrapper>
      </Actions>

    </Container>
  );
});

export default Threshold;

const Container = styled.div`
  margin: 20px;
  margin-top: 0;
`;

const WarningBlock = styled.div`
  width: 50%;
  padding: 15px;
  display: flex;
  border-radius: var(--borderRadius, 4px);
  background: var(--colorShadowInverted6, rgba(211, 47, 47, 0.12));
`;

const WarningText = styled.div`
  margin-left: 10px;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

const WarningMainText = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.1px;
`;

const WarningDescription = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
`;

const Responses = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  padding: 10px 20px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-right: 50px;
`;

const SmallText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;
