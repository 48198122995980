import { WorkspaceScreen } from "features/workspace";
import { FC, useCallback } from "react";
import { useGetWoskspaceById } from "api/useGetWoskspaceById";
import { useNavigate, useParams } from "react-router-dom";

import FullPageLoadingIndicator from "../../components/Loader/FullPageLoadingIndicator";

type WorkspacePageProps = {};

const WorkspacePage: FC<WorkspacePageProps> = () => {
  const navigate = useNavigate();

  const params = useParams();
  const workspaceId = params.id;

  const {
    data: workspace,
    error: getWorkspaceError,
    isLoading,
  } = useGetWoskspaceById(workspaceId);

  if (getWorkspaceError) {
    //TODO
    // navigate(`/404`)
  }

  const renderPage = useCallback(() => {
    if (isLoading) return <FullPageLoadingIndicator />;
    else return <WorkspaceScreen workspaceInfo={workspace.workspace} />;
  }, [isLoading, workspace]);

  return <div>{renderPage()}</div>;
};

export default WorkspacePage;
