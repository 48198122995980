import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { FC, useEffect } from "react";
import { CustomBreadCrumbs } from "./components/BreadCrumbs";

import store from "./store";
import Header from "./Header";
import styled from "styled-components";
import CardsList from "./CardsList";
import TableMenu from "./TableMenu";
import EntityList from "./EntityList";
import ConfirmSavePopup from "./edit-workspace-setting/ConfirmSavePopup";
import EditEntitySetting from "./edit-number-people-popup/EditPopup";
import EditWorkspaceSetting from "./edit-workspace-setting/EditPopup";
import ConfirmEntitySavePopup from "./edit-number-people-popup/ConfirmEntitySavePopup";

const WorkspaceSettings: FC<any> = observer(() => {
  const params = useParams();
  const workspaceId = params.id;

  useEffect(() => {
    store.loadData(+workspaceId);
  }, []);

  return (
    <Container>
      <CustomBreadCrumbs workspaceName="Subscription settings" />
      <Header />
      <CardsList />

      <Content>
        <EntityList />
        <TableContainer>
          <ActiveEntityWrapper>
            <ActiveEntityLabel>/</ActiveEntityLabel>
            <ActiveEntityName>
              {store.selectedEntity?.name || "No Entity"}
            </ActiveEntityName>
          </ActiveEntityWrapper>
          <TableMenu />
        </TableContainer>
      </Content>

      <EditWorkspaceSetting
        openPanel={store.openPanelEditWorkspaceSetting}
        onBtnCancelClick={() => {
          store.setData("openPanelEditWorkspaceSetting", false);
        }}
        onClose={() => {
          store.setData("openPanelEditWorkspaceSetting", false);
        }}
        id_workspace={store.selectedWorkspace?.id}
        workspace_setting={store.workspaceSetting}
      />

      <ConfirmSavePopup
        onSaved={() => {
          store.setData("openPanelEditWorkspaceSetting", false);
          store.getWoskspaceSetting(store.selectedWorkspace?.id);
        }}
      />

      <EditEntitySetting
        openPanel={store.openPanelEditEntitySetting}
        entity_setting={store.selectedNumberPeople}
        onBtnCancelClick={() => {
          store.setData("openPanelEditEntitySetting", false);
        }}
        onClose={() => {
          store.setData("openPanelEditEntitySetting", false);
        }}
      />

      <ConfirmEntitySavePopup
        onSaved={(idEntity: number) => {
          store.setData("openPanelEditEntitySetting", false);
          store.getEntityNumberPeoples(idEntity)
        }}
      />
    </Container>
  );
});

export default WorkspaceSettings;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 48px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  column-gap: 24px;
  margin-top: 20px;
`;

const TableContainer = styled.div`
  width: calc(100% - 244px);
  display: flex;
  flex-direction: column;
`;

const ActiveEntityWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: var(--colorNeutralBackground1);
  box-shadow: 1px 1px 1px 1px var(--colorShadowInverted3);
`;

const ActiveEntityName = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: var(--colorShadowInverted4);
`;

const ActiveEntityLabel = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorNeutralForeground7);
  color: var(--colorNeutralBackground1);
  margin-right: 16px;
  font-size: 18px;
`;
