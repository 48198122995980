import { FC } from "react";
import { IconXlsx } from "./IconXlsx";
import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";
import { observer } from "mobx-react"
import store from './store'

type ErrorsProps = {
  file: File;
};

const Errors: FC<ErrorsProps> = observer((props) => {
  return (
    <ErrorsWrapper id="Survey_ImportQuestions_Errors">
      <Content>
        <Header id="Survey_ImportQuestions_Errors_Title">Errors found in file</Header>
        <FileWrapper>
          <IconXlsx />
          <p id="Survey_ImportQuestions_Errors_FileName">{props.file?.name || "File not found"}</p>
        </FileWrapper>
        <WarningContainer>
          <ErrorIcon />
          <p id="Survey_ImportQuestions_Errors_Message">This list cannot be uploaded.</p>
        </WarningContainer>
        {store.surveyParseResult?.question_id_not_found.length > 0 && <Unit>
          <UnitHeader id="Survey_ImportQuestions_Errors_NotFoundSurvey">Question_id not found in this survey</UnitHeader>
          <br />
          {store.surveyParseResult?.question_id_not_found.map(x =>
            <UnitRowText key={x}>Row {x}</UnitRowText>)}
        </Unit>}

        
        {store.surveyParseResult?.question_ids_duplicated.length > 0 && <Unit>
          <UnitHeader id="Survey_ImportQuestions_Errors_DuplicatedId">Duplicated question_ids</UnitHeader>
          <br />
          {store.surveyParseResult?.question_ids_duplicated.map(x =>
            <UnitRowText key={x}>{x}</UnitRowText>)}
        </Unit>}

        {store.surveyParseResult?.question_codes_duplicated.length > 0 && <Unit>
          <UnitHeader id="Survey_ImportQuestions_Errors_DuplicatedCode">Duplicated question codes</UnitHeader>
          <br />
          {store.surveyParseResult?.question_codes_duplicated.map(x =>
            <UnitRowText key={x}>{x}</UnitRowText>)}
        </Unit>}

        {store.surveyParseResult?.question_codes_already_has.length > 0 && <Unit>
          <UnitHeader id="Survey_ImportQuestions_Errors_Already">Codes that already exist in the database </UnitHeader>
          <br />
          {store.surveyParseResult?.question_codes_already_has.map(x =>
            <UnitRowText key={x}>{x}</UnitRowText>)}
        </Unit>}

        {store.surveyParseResult?.other_errors.length > 0 && <Unit>
          <UnitHeader id="Survey_ImportQuestions_Errors_ExcelErrors">Other Excel errors </UnitHeader>
          <br />
          {store.surveyParseResult?.other_errors.map(x =>
            <UnitRowText key={x}>{x}</UnitRowText>)}
        </Unit>}
      </Content>
    </ErrorsWrapper>
  );
});

export default Errors;

const ErrorsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1, #3E4450);
  margin: 0px 0px 20px 0px;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1, #3E4450);
  }

  svg {
    margin-right: 10px;
  }
`;

const WarningContainer = styled.div`
  width: 100%;
  padding: 20px 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: var(--colorNeutralBackground1, #ffffff);

  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground2, #707882);
  }

  svg {
    color: var(--colorPaletteRedForeground2, #bd0202);
    margin-right: 10px;
  }
`;

const Unit = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1, #cdd3ec);
  margin-top: 20px;
  padding-bottom: 15px;
`;

const UnitHeader = styled.h2`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: var(--colorNeutralForeground2, #707882);
  margin: 0px 0px 0px 0px;
`;

const UnitRowText = styled.li`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1, #3E4450);
`;
