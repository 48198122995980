import styled from "styled-components";

const AdminIcon = () => {
  return (
    <Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 6.75C4.96457 6.75 4.125 5.91043 4.125 4.875C4.125 3.83957 4.96457 3 6 3C7.03543 3 7.875 3.83957 7.875 4.875C7.875 5.91043 7.03543 6.75 6 6.75ZM0 6C0 7.46486 0.526714 8.80586 1.39886 9.84814C2.29157 8.22514 4.017 7.125 6 7.125C7.983 7.125 9.70843 8.22514 10.6011 9.84814C11.4733 8.80586 12 7.46486 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6Z"
          fill="#707882"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.99958 7.125C4.01658 7.125 2.29115 8.22514 1.39844 9.84814C2.49901 11.1626 4.15115 12 5.99958 12C7.84801 12 9.50015 11.1626 10.6007 9.84814C9.70801 8.22514 7.98258 7.125 5.99958 7.125Z"
          fill="#F5F6FA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 3C4.96457 3 4.125 3.83957 4.125 4.875C4.125 5.91043 4.96457 6.75 6 6.75C7.03543 6.75 7.875 5.91043 7.875 4.875C7.875 3.83957 7.03543 3 6 3Z"
          fill="#F5F6FA"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="8"
        viewBox="0 0 7 8"
        fill="none"
        style={{
          marginLeft: "-3px",
        }}
      >
        <path
          d="M3.5 0L0 1.99278V5.99278L3.5 8L7 5.99278V1.99278L3.5 0Z"
          fill="#FCC658"
        />
        <path d="M3.5 0V8L7 5.99278V1.99278L3.5 0Z" fill="#FCC658" />
        <path
          d="M4.57917 5.75L3.5 5.1413L2.42083 5.73478L2.625 4.47174L1.75 3.57391L2.96042 3.3913L3.5 2.25L4.03958 3.40652L5.25 3.58913L4.375 4.48696L4.57917 5.75Z"
          fill="#FF6F00"
        />
      </svg>
    </Container>
  );
};

export default AdminIcon;

const Container = styled.div`
  display: flex;
  align-items: end;
`;
