import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import AppWrapper from "layouts/AppWrapper";
import AdminsPage from "pages/entity-admins/index.page";
import Error403Page from "pages/page403/index.page";
import Error404Page from "pages/page404/index.page";
import Error500Page from "pages/page500/index.page";
import ERMembersPage from "pages/er-members/index.page";
import SettingsPage from "pages/settings/index.page";
import WorkspacePage from "pages/workspace/index.page";
import TemplatesPage from "pages/templates/index.page";
import WorkspacesPage from "pages/workspaces/index.page";
import WorkspaceSettingsPage from "pages/workspace-settings/index.page";
import BackToMainLayoutWrapper from "layouts/BackToMainLayoutWrapper";
import SurveyProjectsPage from "pages/survey-projects/index.page";
import SurveyProjectViewPage from "pages/survey-project-view/index.page";
import ImportQuestionsPage from "pages/survey-import-questions/index.page";
import ImportBenchmarksPage from "pages/survey-import-benchmarks/index.page";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<AppWrapper />}>
            <Route path="/" element={<Navigate to="/workspaces" />} />
            <Route path="/workspaces" element={<WorkspacesPage />} />
            <Route path="/workspaces/:id" element={<WorkspacePage />} />
            <Route
              path="/workspace-settings/:id"
              element={<WorkspaceSettingsPage />}
            />
            <Route path="/workspaces/workspace" element={<WorkspacePage />} />
            <Route path="/entity-admins" element={<AdminsPage />} />
            <Route path="/er-members" element={<ERMembersPage />} />
            <Route path="/templates" element={<TemplatesPage />} />
            <Route path="/survey-projects" element={<SurveyProjectsPage />} />
            <Route path="/survey-projects/import-benchmarks/:id" element={<ImportBenchmarksPage />} />
            <Route
              path="/survey-projects/:id"
              element={<SurveyProjectViewPage />}
            />
            <Route
              path="/survey-projects/import-questions/:id"
              element={<ImportQuestionsPage />}
            />
          </Route>
          <Route element={<BackToMainLayoutWrapper />}>
            <Route path="/settings" element={<SettingsPage />} />
          </Route>
        </Route>
        <Route path="*" element={<Error404Page />} />
        <Route path="/access-deniend" element={<Error403Page />} />
        <Route path="/something-went-wrong" element={<Error500Page />} />
      </Routes>
    </div>
  );
};

export default App;
