import { FC } from "react";
import { observer } from "mobx-react";

import store from "./store";
import styled from "styled-components";
import SelectWorkspace from "./components/SelectWorkspace";
import { useNavigate } from "react-router-dom";

type HeaderProps = {};

const Header: FC<HeaderProps> = observer(() => {
  const navigate = useNavigate();

  return (
    <Container>
      <WorkspaceSelect>
        <Title id="WorkspaceSettings_Title">Workspace</Title>
        <SelectWrapper>
          <SelectWorkspace
            Id = "WorkspaceSettings_SelectHeader"
            data={store.allWorkspaces}
            value={store.selectedWorkspace?.id}
            error={false}
            errorText={""}
            name={"id_workspace"}
            onChange={(e) => {
              const workspace = store.allWorkspaces.find((el) => el.id === +e.target.value)
              store.changeWorkspace(workspace);
              navigate(`/workspace-settings/${workspace.id}`)
            }}
          />
        </SelectWrapper>
      </WorkspaceSelect>
    </Container>
  );
});

export default Header;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  margin-top: 26px;
  border-bottom: 1px solid var(--colorShadowInverted3);
`;

const WorkspaceSelect = styled.div`
  display: flex;
  align-items: center;
`;

const SelectWrapper = styled.div`
  margin-left: 10px;
  min-width: 300px;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin: 0px 12px 0px 0px;
`;
