import { FC } from "react";
import { observer } from "mobx-react";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";

import store from "./store";

type CardsListProps = {};

const CardsList: FC<CardsListProps> = observer(() => {
  return (
    <Container id={`WorkspaceSettings_EntityTotalstatistics_Container`}>
      <CardContainer
        id={`WorkspaceSettings_EntityTotalstatistics_WorkspaceAdmins_Container`}
        onMouseEnter={() => {
          store.changeHover("admin_count");
        }}
        onMouseLeave={() => {
          store.changeHover("");
        }}
        $isCounterAdmins
      >
        <TextCard
          id={`WorkspaceSettings_EntityTotalstatistics_WorkspaceAdmins_Title`}
        >
          Workspace admins
        </TextCard>
        <CardContent>
          <CountCard
            id={`WorkspaceSettings_EntityTotalstatistics_WorkspaceAdmins_Count`}
          >
            {store.workspaceSetting &&
              store.workspaceSetting.admin_count.toLocaleString("en-US")}
          </CountCard>
          {store.hoverState === "admin_count" && (
            <Tooltip title={"Edit"}>
              <StyledIconButton
                id={`WorkspaceSettings_EntityTotalstatistics_WorkspaceAdmins_EditBtn`}
                onClick={() => {
                  store.setData("openPanelEditWorkspaceSetting", true);
                }}
              >
                <EditIconWrapped />
              </StyledIconButton>
            </Tooltip>
          )}
        </CardContent>
      </CardContainer>
      <CardContainer
        id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelUsers_Container`}
        onMouseEnter={() => {
          store.changeHover("user_count");
        }}
        onMouseLeave={() => {
          store.changeHover("");
        }}
      >
        <TextCard
          id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelUsers_Title`}
        >
          Total Entity Level Users
        </TextCard>
        <CardContent>
          <CountCard
            id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelUsers_Count`}
            $isCounter
          >
            {store.workspaceSetting && store.workspaceSetting.user_count_total}/
          </CountCard>
          <CountCard
            id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelUsers_CountMax`}
          >
            {store.workspaceSetting &&
              store.workspaceSetting.user_count.toLocaleString("en-US")}
          </CountCard>
          {store.hoverState === "user_count" && (
            <Tooltip title={"Edit"}>
              <StyledIconButton
                id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelUsers_EditBtn`}
                onClick={() => {
                  store.setData("openPanelEditWorkspaceSetting", true);
                }}
              >
                <EditIconWrapped />
              </StyledIconButton>
            </Tooltip>
          )}
        </CardContent>
      </CardContainer>
      <CardContainer
        id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelEmployees_Container`}
        onMouseEnter={() => {
          store.changeHover("employee_count");
        }}
        onMouseLeave={() => {
          store.changeHover("");
        }}
      >
        <TextCard
          id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelEmployees_Title`}
        >
          Total Entity Level Employees
        </TextCard>
        <CardContent>
          <CountCard
            id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelEmployees_Count`}
            $isCounter
          >
            {store.workspaceSetting &&
              store.workspaceSetting.employee_count_total}
            /
          </CountCard>
          <CountCard
            id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelEmployees_CountMax`}
          >
            {store.workspaceSetting &&
              store.workspaceSetting.employee_count.toLocaleString("en-US")}
          </CountCard>
          {store.hoverState === "employee_count" && (
            <Tooltip title={"Edit"}>
              <StyledIconButton
                id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelEmployees_EditBtn`}
                onClick={() => {
                  store.setData("openPanelEditWorkspaceSetting", true);
                }}
              >
                <EditIconWrapped />
              </StyledIconButton>
            </Tooltip>
          )}
        </CardContent>
      </CardContainer>
      <CardContainer
        id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelActiveSeats_Container`}
        onMouseEnter={() => {
          store.changeHover("active_seat_count");
        }}
        onMouseLeave={() => {
          store.changeHover("");
        }}
      >
        <TextCard
          id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelActiveSeats_Title`}
        >
          Total Entity Level Active Seats
        </TextCard>
        <CardContent>
          <CountCard
            id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelActiveSeats_Count`}
            $isCounter
          >
            {store.workspaceSetting &&
              store.workspaceSetting.active_seat_count_total}
            /
          </CountCard>
          <CountCard
            id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelActiveSeats_CountMax`}
          >
            {store.workspaceSetting &&
              store.workspaceSetting.active_seat_count.toLocaleString("en-US")}
          </CountCard>

          {store.hoverState === "active_seat_count" && (
            <Tooltip title={"Edit"}>
              <StyledIconButton
                id={`WorkspaceSettings_EntityTotalstatistics_TotalEntityLevelActiveSeats_EditBtn`}
                onClick={() => {
                  store.setData("openPanelEditWorkspaceSetting", true);
                }}
              >
                <EditIconWrapped />
              </StyledIconButton>
            </Tooltip>
          )}
        </CardContent>
      </CardContainer>
    </Container>
  );
});

export default CardsList;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
`;

const CardContainer = styled.div<{
  $isLast?: boolean;
  $isCounterAdmins?: boolean;
}>`
  background-color: var(--colorNeutralBackground1);
  padding: 16px;
  border-radius: 4px;
  min-width: ${(props) => (props.$isCounterAdmins ? "220px" : "240px")};
  box-shadow: 1px 1px 2px 1px var(--colorShadowInverted3);
  transition: all 0.3s;
  ${(props) => !props.$isLast && `margin-right: 24px; `}

  &:hover {
    background-color: var(--colorPalleteLightBlue);
  }
`;

const TextCard = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: var(--colorShadowInverted2);
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
`;

const CountCard = styled.div<{ $isCounter?: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.25px;
  color: ${(props) =>
    props.$isCounter
      ? `var(--colorShadowInverted5)`
      : `var(--colorShadowInverted4)`};
`;

const EditIconWrapped = styled(EditIcon)`
  color: #666666;
`;

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  margin-left: 12px !important;

  svg {
    font-size: 24px;
  }
`;
