import http from "./config/https";

const getAllSurveys = (
  searchValue: string,
  page: number,
  pageSize: number,
  sortField: string,
  sortType: string,
  filterField: string,
  filterOperator: string,
  filterValue: string,
) => {
  if (http.isAPIGO) {
    return http.postGO(
      `/sm/sm_survey/GetAllSurveysInternalTool`,
      { searchValue, page, pageSize, sortField, sortType, filterField, filterOperator, filterValue },
      {}
    );
  } else {
    return http.post(
      `/sm_survey/GetAllSurveysInternalTool`,
      { searchValue, page, pageSize, sortField, sortType, filterField, filterOperator, filterValue },
      {}
    );
  };
};

export default getAllSurveys;
