import { validate } from "./valid";
import { makeAutoObservable } from "mobx";
import { TEntitySetting, TWorkspaceSetting } from "types/workspace";

import topStore from "../store";
import MainStore from "MainStore";
import useSaveEntitySetting from "api/useSaveEntitySetting";

class Store {
  entity_setting: TEntitySetting = null;
  workspace_setting: TWorkspaceSetting = null;
  erroradmin_count = "";
  erroruser_count = "";
  erroremployee_count = "";
  erroractive_seat_count = "";
  errorstrbegin_date = "";
  errorstrend_date = "";

  isOpenConfirmSavePopup: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  changeFieldEntity = (name: string, value: any) => {
    this.entity_setting[name] = value;
    validate(name, value, (errorField: string, errorText: string) => {
      this[errorField] = errorText;
    });
  };

  clearStore() {
    this.entity_setting = null;
    this.erroradmin_count = "";
    this.erroruser_count = "";
    this.erroremployee_count = "";
    this.erroractive_seat_count = "";
  }

  saveEntitySettingSubmit = () => {
    const validated = (errorField: string, errorText: string) => {
      this[errorField] = errorText;
    };

    let canSave = true;
    canSave =
      validate("admin_count", this.entity_setting.admin_count, validated) &&
      canSave;
    canSave =
      validate(
        "active_seat_count",
        this.entity_setting?.active_seat_count,
        validated
      ) && canSave;
    canSave =
      validate("strbegin_date", this.entity_setting.strbegin_date, validated) &&
      canSave;
    canSave =
      validate("strend_date", this.entity_setting.strend_date, validated) &&
      canSave;

    if (canSave) {
      this.isOpenConfirmSavePopup = true;
      topStore.setData("openPanelEditEntitySetting", false);
    } else {
      MainStore.setSnackbar("Cannot save");
    }
    return canSave;
  };

  saveEntitySettingsSubmit = async (afterSave: (idEntity: number) => void) => {
    try {
      MainStore.changeLoader(true);
      const response = await useSaveEntitySetting(this.entity_setting);
      if (response.status == 200 || response.status === 201) {
        afterSave(this.entity_setting.entity_id);
        this.clearStore();
        this.isOpenConfirmSavePopup = false;
      } else if (response.response?.status == 422) {
        MainStore.setSnackbar(response.response.data?.Content, "error");
      }
      else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
