import store from './store'
import workspaceStore from './../store'

export const validate = (nameField, value, validated) => {
  let admin_count = "";
  if (nameField === "admin_count") {
    let admin_countErrors = [];
    CheckEmptyTextField(value, admin_countErrors)
    CheckOnlyDigit(value, admin_countErrors)
    OnlyPositive(value, admin_countErrors)
    CheckMaxValue(value, admin_countErrors, 250000)
    if(store.entity_setting.admin_count_total > value){
      admin_countErrors.push("Unable to decrease number of entity admins. Please remove excess entity admins first.")
    }

    admin_count = admin_countErrors.join(", ");
    validated("erroradmin_count", admin_count);
  }
  //End of validation field admin_count

  let user_count = "";
  if (nameField === "user_count") {
    let user_countErrors = [];
    CheckEmptyTextField(value, user_countErrors)
    CheckOnlyDigit(value, user_countErrors)
    OnlyPositive(value, user_countErrors)
    CheckMaxValue(value, user_countErrors, workspaceStore.workspaceSetting?.user_count)
    if(store.entity_setting.user_count_total > value){
      user_countErrors.push("Unable to decrease number of entity admins. Please remove excess entity admins first.")
    }
    user_count = user_countErrors.join(", ");
    validated("erroruser_count", user_count);
  }
  //End of validation field user_count

  let employee_count = "";
  if (nameField === "employee_count") {
    let employee_countErrors = [];
    CheckEmptyTextField(value, employee_countErrors)
    CheckOnlyDigit(value, employee_countErrors)
    OnlyPositive(value, employee_countErrors)
    CheckMaxValue(value, employee_countErrors, workspaceStore.workspaceSetting?.employee_count)
    if(store.entity_setting.employee_count_total > value){
      employee_countErrors.push("Unable to decrease number of entity admins. Please remove excess entity admins first.")
    }
    employee_count = employee_countErrors.join(", ");
    validated("erroremployee_count", employee_count);
  }
  //End of validation field employee_count

  let active_seat_count = "";
  if (nameField === "active_seat_count") {
    let active_seat_countErrors = [];
    CheckEmptyTextField(value, active_seat_countErrors)
    CheckOnlyDigit(value, active_seat_countErrors)
    OnlyPositive(value, active_seat_countErrors)
    CheckMaxValue(value, active_seat_countErrors, workspaceStore.workspaceSetting?.active_seat_count)
    if(store.entity_setting.active_seat_count_total > value){
      active_seat_countErrors.push("Unable to decrease number of entity admins. Please remove excess entity admins first.")
    }

    active_seat_count = active_seat_countErrors.join(", ");
    validated("erroractive_seat_count", active_seat_count);
  }
  //End of validation field active_seat_count

  let strbegin_date = "";
  if (nameField === "strbegin_date") {
    let strbegin_dateErrors = [];
    CheckEmptyTextField(value, strbegin_dateErrors)
    CheckCorrectDate(value, strbegin_dateErrors)
    strbegin_date = strbegin_dateErrors.join(", ");
    validated("errorstrbegin_date", strbegin_date);
  }
  //End of validation field strbegin_date

  let strend_date = "";
  if (nameField === "strend_date") {
    let strend_dateErrors = [];
    CheckEmptyTextField(value, strend_dateErrors)
    CheckCorrectDate(value, strend_dateErrors)
    checkCorrectDateEnd(value, store.entity_setting.strbegin_date, strend_dateErrors)
    strend_date = strend_dateErrors.join(", ");
    validated("errorstrend_date", strend_date);
  }
  //End of validation field strend_date

  let canSave =
    true &&
    admin_count === "" &&
    user_count === "" &&
    employee_count === "" &&
    active_seat_count === "" &&
    strbegin_date === "" &&
    strend_date === ""

  return canSave;
};

export function CheckEmptyTextField(s: string, errors: string[]) {
  if (s === "" || s === null) {
    errors.push("Required field");
    return;
  }
}

export function CheckCorrectDate(date, errors) {

  if (date == null || Number.isInteger(date) == true) {
    errors.push("Required field");
  }

  var g2 = new Date(date);

  if (Object.prototype.toString.call(g2) === "[object Date]") {
    // date object is valid
    var g1 = new Date(1900, 0, 1);
    var g3 = new Date(2100, 0, 1)
    if (g1 > g2 || g2 > g3) {
      errors.push("Incorrect Date");
    }
  } else {
    // not a date object
    errors.push("Incorrect Date");
  }

}

export function checkCorrectDateEnd(dateEnd: string, dateStart: string, errors: string[]) {
  var g1 = new Date(dateEnd);
  var g2 = new Date(dateStart);
  if (g1 < g2) {
    errors.push("The end date cannot be earlier than the start date");
  }
}

export function CheckOnlyDigit(s: string, errors: string[]) {
  if (s === "" || s === null) return;
  let isnum = /^-?[0-9]+?$/.test(s);
  if (!isnum) errors.push("Only digits");
}

export function OnlyPositive(s: string, errors: string[]) {
  let isnum = +s
  if (isnum < 0) errors.push("Only positive");
}

export function CheckMaxValue(s: string, errors: string[], maxValue: number) {
  let isnum = +s
  if (isnum > maxValue) errors.push("Value can't exceed " + maxValue);
}

export function CheckMinValue(s: string, errors: string[], minValue: number) {
  let isnum = +s
  if (isnum > minValue) errors.push("Minimuv value " + minValue);
}