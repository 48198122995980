import { makeAutoObservable } from "mobx";
import { useImpersonateLogin, useImpersonateLogout } from "api/useImpersonateUser"
import { MAIN_FRONT_URL } from "constants/config";

import MainStore from "MainStore";

class Store {
  templatesFilter: string = "";


  constructor() {
    makeAutoObservable(this);
  }

  impersonateLogin = async (accountID) => {
    try {
      MainStore.changeLoader(true);
      const response = await useImpersonateLogin(accountID);
      if (response.status === 200 || response.status === 201) {
        MainStore.setSnackbar("Successfully impersonated", "success");
        window.open(MAIN_FRONT_URL, "_blank");
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
      return false;
    } finally {
      MainStore.changeLoader(false);
    }
  }

  impersonateLogout = async (accountID) => {
    try {
      MainStore.changeLoader(true);
      const response = await useImpersonateLogout(accountID);
      if (response.status === 200 || response.status === 201) {
        MainStore.setSnackbar("Successfully logged out", "success");
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
      return false;
    } finally {
      MainStore.changeLoader(false);
    }
  }

}

const store = new Store();
export default store;
