export const SURVEY_STATUS_CODES = {
  upcoming: "upcoming",
  unscheduled: "unscheduled",
  complete: "complete",
  running: "running",
};

export const PROJECT_STATUS = {
  unscheduled: "Unscheduled",
  completed: "Completed",
  running: "Active",
};
