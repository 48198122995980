import { FC } from "react";

import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";

type ModalProps = {
  open: boolean;
  hide: (state: boolean) => void;
  children: any;
  width?: string;
  id?: string;
};

const CustomModal: FC<ModalProps> = (props) => {
  return (
    <StyledDialog
      id={props.id}
      onClose={() => props.hide(false)}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      $width={props.width}
    >
      <CloseModal id={props.id && props.id + "_Close_Button"} onClick={() => props.hide(false)} />
      <StyledDialogContent id={props.id && props.id + "_Content"}>{props.children}</StyledDialogContent>
    </StyledDialog>
  );
};

export default CustomModal;

const StyledDialog = styled(Dialog)<{ $width: string | undefined }>`
  .MuiDialogContent-root {
    width: ${(props) => (props.$width ? `${props.$width}px` : "580px")};
    padding: ${(props) => (+props.$width < 500 ? "64px" : "64px 80px")};
  }
`;

const CloseModal = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38px;
  right: 30px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  opacity: 0.6;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    width: 18px;
    height: 3px;
    background: var(--colorBrandForeground1);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const StyledDialogContent = styled(DialogContent)``;
