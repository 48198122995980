import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import styled from "styled-components";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";

import MainStore from "MainStore";
import FullPageLoadingIndicator from "components/Loader/FullPageLoadingIndicator";

export const PrivateRoute = observer(() => {
  const { isAuthenticated } = useAuth0();
  const { getIdTokenClaims } = useAuth0();

  const getToken = async () => {
    const data = await getIdTokenClaims();
    localStorage.setItem("accessToken", data.__raw);
  };

  if (isAuthenticated) {
    getToken();
  }

  return (
    <>
      <Snackbar
        id="SnackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={MainStore.openSnackbar}
        onClose={() => MainStore.changeSnackbar(false)}
        autoHideDuration={3000}
        message={MainStore.snackbarMessage}
        key={"bottomleft"}
      >
        <StyledAlert
          id="SnackBar_ Alert"
          elevation={6}
          onClose={() => MainStore.changeSnackbar(false)}
          variant="filled"
          severity={MainStore.snackbarSeverity}
          icon={
            MainStore.snackbarSeverity === "error" ? (
              <StyledCancelIcon />
            ) : (
              <StyledCheckIcon />
            )
          }
        >
          {MainStore.snackbarMessage}
        </StyledAlert>
      </Snackbar>
      <Backdrop
        id="Preloader_2"
        sx={{ color: "var(--colorNeutralBackground1)", zIndex: 1000000 }}
        open={MainStore.loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Outlet />
    </>
  );
});

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <FullPageLoadingIndicator />,
});

const StyledCancelIcon = styled(CancelIcon)`
  color: var(--colorPaletteRedForeground2) !important;
`;

const StyledCheckIcon = styled(CheckCircleIcon)`
  color: var(--colorPaletteGreenBackground2) !important;
`;

const StyledAlert = styled(Alert)`
  background-color: var(--colorNeutralForeground1) !important;
  color: var(--colorNeutralBackground1) !important;
  border-radius: 8px !important;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
