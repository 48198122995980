import { API_URL } from "constants/config";

const getExcelWithQuestionCodes = () => {
  var url = API_URL + `/sm_question/GetExcelWithQuestionCodes`;
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.blob();
  });
};

export default getExcelWithQuestionCodes;
