import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import styled from "styled-components";
import { observer } from 'mobx-react';

type DateTimePickerValueProps = {
  value: Dayjs;
  label?: string;
  id?: string;
  minDateTime?: Dayjs;
  maxDateTime?: Dayjs;
  disabled?: boolean;
  onChange: (value: Dayjs) => void;
};


const DateTimeField: React.FC<DateTimePickerValueProps> = observer((props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <WrappedTimeField
        format="MM/DD/YYYY hh:mm A"
        slotProps={{
          textField: {
            id : props.id
          }
        }}
        value={props.value}
        label={props.label ? props.label : "Select date and time"}
        disabled={props.disabled}
        onChange={(newValue: Dayjs) => {
          if (newValue instanceof dayjs) {
            props.onChange(newValue)
          }
        }}
        closeOnSelect={false}
        minDateTime={props.minDateTime}
        maxDateTime={props.maxDateTime}
      />
    </LocalizationProvider>
  );
})

const WrappedTimeField = styled(DateTimePicker)`
  input {
      border-color: #CDD3EC;
  }
`
export default DateTimeField;