import { queryClient } from "providers/ReactQueryProvider";
import { useMutation } from "@tanstack/react-query";

import http from "./config/https";

export type CreateWorkspaceSuccessResponseData = {
  id: number;
};

const editWoskspace = async (workspaceData: { name: string; logo: any, id: number, logo_url: string }) => {

  const formData = new FormData();

  formData.append("id", workspaceData.id + "");
  formData.append("name", workspaceData.name);
  formData.append("region_id", "1");
  if (workspaceData.logo) {
    formData.append("Document.file", workspaceData.logo);
    formData.append("Document.name", workspaceData.logo ? workspaceData.logo.name : "");
  }

  try {
    if (http.isAPIGO) {
      const { data } = await http.putGO('/workspace/' + workspaceData.id, formData, { 'Content-Type': 'multipart/form-data' });
      return data;
    } else {
      const { data } = await http.post('/workspace/Update', formData, { 'Content-Type': 'multipart/form-data' });
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const useEditWoskspace = () => {
  return useMutation({
    mutationFn: editWoskspace,
    onSuccess: () => {
      queryClient.resetQueries(["workspaces/detail"]);
    },
  });
};
