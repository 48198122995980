import { FC } from "react";
import { InternalToolAccountSettingsScreen } from "features/settings";

type SettingsPageProps = {};

const SettingsPage: FC<SettingsPageProps> = () => {
  return <InternalToolAccountSettingsScreen />;
};

export default SettingsPage;
