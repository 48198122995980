import { TWorkspace } from "types/workspace";
import { useCreateEntity } from "api/useCreateEntity";
import { useCheckCorrectEntityName } from "api/useCheckCorrectEntityName";
import { FormControlLabel, Input, Radio } from "@mui/material";
import React, { FC, Fragment, ReactElement, useEffect, useState } from "react";

import styled from "styled-components";
import Button from "components/button/Button";
import RadioGroup from "@mui/material/RadioGroup";
import CustomModal from "components/modal/Modal";
import FormControl from "@mui/material/FormControl";
import useDebounce from "utils/useDebounse";

const OPTIONS = {
  email_and_password: {
    name: "Via email and password",
    value: 0,
  },
  microsoft: {
    name: "Login with",
    value: 1,
  },
};

type ModalChangePasswordProps = {
  open: boolean;
  hide: (state: boolean) => void;
  workspaceInfo: TWorkspace | undefined;
};

const ModalCreateEntity: FC<ModalChangePasswordProps> = (props): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [signInMethod, setSignInMethod] = useState<0 | 1>(0);

  const { mutateAsync: createEntity, isLoading: isLoadingCreateEntity } = useCreateEntity();

  const debouncedNameValue = useDebounce(name, 500);

  const { mutateAsync } = useCheckCorrectEntityName();

  const checkName = async () => {
    try {
      if (!name) {
        setError("Name is required");
        return;
      }
      if (props.open && debouncedNameValue && debouncedNameValue.length > 0) {
        const response = await mutateAsync({
          name: debouncedNameValue,
          workspaceId: props.workspaceInfo.id,
        });
        if (!response.exists) setError(null);
        else setError("There is an existing entity with the same name.");
      } else return;
    } catch (err) {
      setError("There is an existing entity with the same name.");
    }
  };

  useEffect(() => {
    checkName();
  }, [debouncedNameValue]);

  const submitForm = () => {
    try {
      createEntity({
        name,
        workspace_id: props.workspaceInfo.id,
        sign_in_method: signInMethod,
        super_admin: true, //TODO only for demo
      });
      closeModal();
    } catch (err) {}
  };

  const closeModal = () => {
    setName("");
    setSignInMethod(0);
    props.hide(false);
  };

  return (
    <CustomModal open={props.open} hide={closeModal} width="468">
      <StyledModalTitle id={`Workspace_Entities_WorkspaceCard_EditPopUp_Title`}>
        Create entity under {props.workspaceInfo.name}
      </StyledModalTitle>
      <StyledModalContent aria-label={"Create workspace form"}>
        <InputLabel id={`Workspace_Entities_WorkspaceCard_EditPopUp_Subtitle`}>
          Entity name
        </InputLabel>
        <StyledInput
          id={`Workspace_Entities_WorkspaceCard_EditPopUp_Input`}
          name="name"
          placeholder="Enter entity name"
          type="text"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        />
        {error && <Error id={`Workspace_Entities_WorkspaceCard_EditPopUp_Error`}>{error}</Error>}
        <InputLabel id={`Workspace_Entities_WorkspaceCard_EditPopUp_Question`}>
          How will entity users sign in?
        </InputLabel>
        <FormControl>
          <RadioWrapper
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={signInMethod}
            onChange={(e) => setSignInMethod(Number(e.target.value) === 0 ? 0 : 1)}
          >
            <StyledRadioWrapper>
              <StyledRadio
                value={OPTIONS.email_and_password.value}
                control={<Radio />}
                label={OPTIONS.email_and_password.name}
              />
            </StyledRadioWrapper>
            <StyledRadioWrapper>
              <StyledRadio
                value={OPTIONS.microsoft.value}
                control={<Radio />}
                label={
                  <Fragment>
                    <RadioLabel>{OPTIONS.microsoft.name}</RadioLabel>
                    <MicrosoftImage
                      src="/assets/microsoft_login.png"
                      width={86}
                      height={22}
                      alt="Login with Microsoft"
                    />
                  </Fragment>
                }
              />
            </StyledRadioWrapper>
          </RadioWrapper>
        </FormControl>
        <ButtonWrapper>
          <StyledNextButton
            id={`Workspace_Entities_WorkspaceCard_EditPopUp_CreateEntity_Btn`}
            variant="contained"
            onClick={submitForm}
            disabled={Boolean(error) || isLoadingCreateEntity}
          >
            Create entity
          </StyledNextButton>
          <StyledCancelButton
            id={`Workspace_Entities_WorkspaceCard_EditPopUp_Cansel_Btn`}
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalCreateEntity;

const StyledModalTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
`;

const StyledModalContent = styled.div``;

const StyledInput = styled(Input)`
  width: 100%;

  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--colorNeutralForeground5);
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px !important;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border: 1px solid var(--colorNeutralForeground1) !important;
`;

const StyledNextButton = styled(Button)`
  width: 100%;
  max-width: 150px;
  height: 36px;
`;

const Error = styled.p`
  width: 100%;
  margin: 4px 0 0 0;
  color: var(--colorPaletteRedForeground2);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const InputLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 40px 0 10px 0 !important;
  color: var(--colorNeutralForeground2);
`;

const StyledRadioWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  position: relative;
`;

const RadioLabel = styled.p`
  margin: 0;
`;

const MicrosoftImage = styled.img`
  margin: 0;
`;

const RadioWrapper = styled(RadioGroup)`
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  column-gap: 11px;
`;

const StyledRadio = styled(FormControlLabel)`
  width: 100%;
  max-width: 164px;
  padding: 16px 11px;
  margin: 0 !important;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
`;
