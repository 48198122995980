export const RECEPIENT_STATUSES = {
  pending: "pending",
  completed: "completed",
  removed_manually: "removed_manually",
  removed_by_the_system: "removed_by_the_system",
};
export const RECEPIENT_STATUSES_LIST = [
  "Pending",
  "Completed",
  "Removed Manually",
  "Removed by the System",
];
