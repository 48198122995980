import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import {
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  FormControlLabel,
  TableContainer,
} from "@mui/material";

import React from "react";
import store from "./store";
import Table from "@mui/joy/Table";
import styled from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const TableCell = styled(MuiTableCell)({});

type ManageTemplateTableProps = {
  id?: string;
};

const ManageTemplateTable: FC<ManageTemplateTableProps> = observer(() => {
  const [opensRow, setOpensRow] = useState([]);
  const [entityIds, setEntityIds] = useState([]);

  const counterEntity = () => {
    let entities = [];
    for (let i = 0; i < store.workspaceEntityList.length; i++) {
      const newEntites = store.workspaceEntityList[i].entity.map((ent) => ent.entityId);
      entities = [...entities, ...newEntites];
    }
    setEntityIds(entities);
  };

  useEffect(() => {
    counterEntity();
  }, []);

  return (
    <>
      <TableHeader id="DataOverview_TableHeader">
        <CheckBoxContainer id="DataOverview_CheckBoxContainer">
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                id="DataOverview_SelectAllCheckBox"
                checked={store.selectedEntity.length === entityIds.length}
                onChange={() => {
                  store.setData(
                    "selectedEntity",
                    store.selectedEntity.length === entityIds.length ? [] : entityIds,
                  );
                }}
              />
            }
            label="Select all"
            id="DataOverview_SelectAllLabel"
          />
        </CheckBoxContainer>
      </TableHeader>
      <StyledTableContainer id="DataOverview_TableContainer">
        <Table stickyHeader borderAxis="bothBetween" id="DataOverview_Table">
          <TableHead id="DataOverview_TableHead">
            <TableRow id="DataOverview_HeaderRow">
              <HeaderTableCell padding="checkbox" id="DataOverview_HeaderCell_Checkbox" />
              <HeaderTableCell id="DataOverview_HeaderCell_Workspaces">Workspaces</HeaderTableCell>
              <HeaderTableCell id="DataOverview_HeaderCell_Entities">Entities</HeaderTableCell>
              <HeaderTableCell id="DataOverview_HeaderCell_ID">
                Workspace & Entities ID
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody id="DataOverview_TableBody">
            {store.workspaceEntityList.map((workspace) => (
              <React.Fragment key={workspace.workspaceId}>
                <WorkspaceTableRow id={`DataOverview_WorkspaceRow_${workspace.workspaceId}`}>
                  <TableCell
                    padding="checkbox"
                    id={`DataOverview_ToggleRowButton_${workspace.workspaceId}`}
                  >
                    <IconButton
                      size="small"
                      onClick={() =>
                        setOpensRow((prev) =>
                          prev.includes(workspace.workspaceId)
                            ? prev.filter((el) => el !== workspace.workspaceId)
                            : [...prev, workspace.workspaceId],
                        )
                      }
                      id={`DataOverview_IconButton_${workspace.workspaceId}`}
                    >
                      {opensRow.includes(workspace.workspaceId) ? (
                        <ArrowDropUpIcon id={`DataOverview_ArrowUp_${workspace.workspaceId}`} />
                      ) : (
                        <ArrowDropDownIcon id={`DataOverview_ArrowDown_${workspace.workspaceId}`} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="th"
                    id={`DataOverview_WorkspaceName_${workspace.workspaceId}`}
                  >
                    {workspace.workspaceNmae}
                  </TableCell>
                  <TableCell
                    id={`DataOverview_WorkspaceEmptyCell_${workspace.workspaceId}`}
                  ></TableCell>
                  <TableCell id={`DataOverview_WorkspaceID_${workspace.workspaceId}`}>
                    {workspace.workspaceId}
                  </TableCell>
                </WorkspaceTableRow>

                {workspace.entity.map((entity) => {
                  const isChecked = store.selectedEntity.includes(entity.entityId);
                  return (
                    <EntityTableRow
                      sx={{
                        visibility: opensRow.includes(workspace.workspaceId)
                          ? "visible"
                          : "collapse",
                      }}
                      key={entity.entityId}
                      id={`DataOverview_EntityRow_${entity.entityId}`}
                    >
                      <TableCell
                        padding="checkbox"
                        id={`DataOverview_EntityCheckbox_${entity.entityId}`}
                      >
                        <StyledCheckBox
                          checked={isChecked}
                          onChange={() => {
                            store.setData(
                              "selectedEntity",
                              isChecked
                                ? store.selectedEntity.filter((ent) => ent !== entity.entityId)
                                : [...store.selectedEntity, entity.entityId],
                            );
                          }}
                          id={`DataOverview_Checkbox_${entity.entityId}`}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={`DataOverview_EntityEmptyCell_${entity.entityId}`}
                      ></TableCell>
                      <TableCell id={`DataOverview_EntityName_${entity.entityId}`}>
                        {entity.entityName}
                      </TableCell>
                      <TableCell id={`DataOverview_EntityID_${entity.entityId}`}>
                        {entity.entityId}
                      </TableCell>
                    </EntityTableRow>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
});

export default ManageTemplateTable;

const HeaderTableCell = styled(MuiTableCell)`
  background-color: var(--colorPaletteBlueBackground2) !important;
  height: 52px !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 1px !important;
  color: var(--colorShadowInverted4) !important;
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 4px !important;
  color: var(--colorBrandForeground1) !important;
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 16px;
  background-color: var(--grenBackgroundColor2);
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 0px, 0px, 1px, 0px;
  margin-top: 20px;
`;

const CheckBoxContainer = styled.div``;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const EntityTableRow = styled(TableRow)`
  background-color: var(--colorNeutralBackground1);
  height: 52px;
`;

const WorkspaceTableRow = styled(TableRow)`
  height: 52px !important;
`;

const StyledTableContainer = styled(TableContainer)`
  max-height: 400px !important;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--colorNeutralForeground4);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralForeground6);
    border-radius: 10px;
    border: 3px solid var(--colorNeutralForeground6);
  }
`;
