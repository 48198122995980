export const INTERNAL_TOOL_MENU = [
  {
    name: "Workspaces",
    path: "/workspaces",
  },
  {
    name: "Workspace/Entity Admins",
    path: "/entity-admins",
  },
  {
    name: "Internal tool ER members",
    path: "/er-members",
  },
  {
    name: "Templates",
    path: "/templates",
  },
  {
    name: "Survey Projects Management",
    path: "/survey-projects",
  },
];
