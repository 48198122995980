import styled from "styled-components";

const ImportIcon = () => {
  return (
    <SvgWrapped xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path d="M14.375 9.96875V15.2188H3.875V9.96875H2.375V15.2188C2.375 16.0438 3.05 16.7188 3.875 16.7188H14.375C15.2 16.7188 15.875 16.0438 15.875 15.2188V9.96875H14.375Z" fill="#707882" />
      <path d="M6.4325 8.02625L8.375 6.09125L8.375 13.3437L9.875 13.3437L9.875 6.09125L11.8175 8.02625L12.875 6.96875L9.125 3.21875L5.375 6.96875L6.4325 8.02625Z" fill="#707882" />
    </SvgWrapped>
  );
};

const SvgWrapped = styled.svg`
  margin: 0 5px;
  margin-bottom: 5px;
`;
export default ImportIcon;
