import { HTMLAttributes, ReactElement } from "react";

import Box from "@mui/material/Box";
import styled from "styled-components";
import { prependBasePath } from "api/routes/basePath";

const FullPageLoadingIndicator = (
  props: HTMLAttributes<HTMLDivElement>
): ReactElement => {
  return (
    <Container id="Preloader_1" role="alert" aria-label="Loading" {...props}>
      <Box
        id="spiner"
        component="img"
        src={prependBasePath("/assets/er_logo_loading.gif")}
        width={64}
        height={64}
        alt="EngageRocket Logo"
      />
    </Container>
  );
};

export default FullPageLoadingIndicator;

const Container = styled.div`
  background-color: var(--overlayBackgroundColor);
  display: flex;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
`;
