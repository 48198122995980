import { useMutation } from "@tanstack/react-query";

import http from "./config/https";

const checkCorrectEntityName = async (entityData: {
  name: string;
  workspaceId: number;
}) => {
  if (http.isAPIGO) {
    const { data } = await http.postGO(`/entity/checkname`, //TODO only for demo
      {
        name: entityData.name,
        workspace_id: entityData.workspaceId,
      },
      {}
    );

    return data
  } else {
    const { data } = await http.post(
      (window["_env_"]?.REACT_APP_BASE_API_SHARP ?? process.env.REACT_APP_BASE_API_SHARP) + `/entity/checkname`, //TODO only for demo
      {
        name: entityData.name,
        workspace_id: entityData.workspaceId,
      },
      {}
    );
    return data;

  }

};

export const useCheckCorrectEntityName = () => {
  return useMutation({
    mutationFn: checkCorrectEntityName,
  });
};
