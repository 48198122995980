import { FC } from "react";
import { ImportQuestions } from "features/Import-questions";

type ImportQuestionsPageProps = {};

const ImportQuestionsPage: FC<ImportQuestionsPageProps> = () => {
  return <ImportQuestions />;
};

export default ImportQuestionsPage;
