const basePath = "";

function prependBasePath(sublink) {
  return `${basePath}${sublink}`;
}

module.exports = {
  basePath,
  prependBasePath,
};
