import { FC } from "react";
import { observer } from "mobx-react";

import store from "./store";
import styled from "styled-components";
import TemplateItem from "./TemplateItem";

type TemplatesListProps = {};

const TemplatesList: FC<TemplatesListProps> = observer(() => {
  return (
    <Container>
      {store.templateList.map((template) => (
        <TemplateItem template={template} key={template.id} />
      ))}
    </Container>
  );
});

export default TemplatesList;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }
`;
