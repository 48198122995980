import { makeAutoObservable } from "mobx";
import { useAddActiveSeatsRows } from "api/useAddActiveSeatsRows";
import MainStore from "MainStore";
import { useCheckEmailActiveSeat } from "api/useCheckEmailActiveSeat";
import { TActiveSeatEmailCheckResult } from "types/activeSeats";
import { EMAIL_REGEXP } from "constants/email";

class Store {
  entityId: number = 0;
  newActiveSeatsEmails: string[] = [];
  newActiveSeatsEmail: string = "";
  errorMessage: string = ""

  constructor() {
    makeAutoObservable(this);
  }
  setData = (field: string, value: any) => {
    this[field] = value;
  };

  clearStore = () => {
    this.newActiveSeatsEmails = [];
    this.newActiveSeatsEmail = "";
    this.errorMessage = ""
  }

  setCheckEmailResult = (response: TActiveSeatEmailCheckResult) => {
    if (response.hasActiveSeat) {
      this.setData("errorMessage", `An employee with "${response.email}" email has already been added to the active seats`)
    } else if (response.notInEmployee) {
      this.setData("errorMessage", `The employee with "${response.email}" email does not exist in this entity`)
    } else {
      this.setData("errorMessage", ``)
      this.newActiveSeatsEmails = [
        ...this.newActiveSeatsEmails,
        response.email,
      ];
    }
  };

  addNewEmail = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value.length < 1) {
        this.setData("errorMessage", "Email cannot be empty");
      } else if (!EMAIL_REGEXP.test(e.target.value)) {
        this.setData("errorMessage", "Incorrect email");
      } else if (store.newActiveSeatsEmails.includes(e.target.value)) {
        this.setData("errorMessage", "Email exists");
      } else {
        this.setData("errorMessage", "");
        this.checkEmailActiveSeat(e.target.value)
      }
    }
  };

  checkEmailActiveSeat = async (newEmail: string) => {
    try {
      MainStore.changeLoader(true);
      const response = await useCheckEmailActiveSeat(this.entityId, newEmail);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setCheckEmailResult(response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  addActiveSeats = async (onSaved: () => void) => {
    try {
      MainStore.changeLoader(true);
      const response = await useAddActiveSeatsRows(
        this.entityId,
        this.newActiveSeatsEmails
      );
      if (response.status === 200 || response.status === 201) {
        onSaved()
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
