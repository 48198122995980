import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import store from "./store";
import Card from "./card";
import { Grid } from "@mui/material";
import EntityAddEditView from "./addedit/popup";
import CustomButton from "components/button/Button";
import { AddIcon } from "../components/AddIcon";

type EntityCardListViewProps = {
  workspaceId: number;
  workspaceLogo: string;
  workspaceName: string;
};

const EntityCardListView: FC<EntityCardListViewProps> = observer(
  ({ workspaceId, workspaceLogo, workspaceName }): ReactElement => {
    React.useEffect(() => {
      store.loadEntities(workspaceId);
    }, []);

    return (
      <Container>
        <EntityAddEditView
          workspaceId={workspaceId}
          workspaceLogo={workspaceLogo}
          openPanel={store.openPanelAddEditEntity}
          id={store.currentEntityId}
          onBtnCancelClick={() => {
            store.currentEntityId = 0;
            store.changeOpenPanelAddEditEntity(false);
          }}
          onSaved={() => {
            store.changeOpenPanelAddEditEntity(false);
            store.loadEntities(workspaceId);
          }}
          workspacename={workspaceName}
        />

        <>
          <TopHeader>
            <Header id="Workspace_Entities_Title">Entities ({store.data.length})</Header>
          </TopHeader>

          <Grid container spacing={3}>
            {store.data.map((content, index) => {
              return (
                <Grid item xs={12} key={content.id} md={4}>
                  <Card id={`Workspace_Entities_Title_${index}`} cardContent={content} />
                </Grid>
              );
            })}
            <Grid item xs={12} md={4}>
              <CreateButton
                isData={store.data.length > 0}
                id="Workspace_Entities_CreateANewEntityBtn"
                onClick={() => store.changeOpenPanelAddEditEntity(true)}
              >
                <AddIcon /> Create a new entity
              </CreateButton>
            </Grid>
          </Grid>
        </>
      </Container>
    );
  },
);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--colorNeutralForegroundInverted1);
`;

const Header = styled.h1`
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

const CreateButton = styled(CustomButton)<{ isData: boolean }>`
  width: 100%;
  height: ${({ isData }) => (isData ? "100%" : "127px")};
  min-height: 127px;
  flex-shrink: 0;
  border-radius: 10px !important;
  border: 1px dashed var(--colorBrandForeground1) !important;
  background: var(--colorNeutralForeground4);
  font-size: 20px !important;

  svg {
    margin-right: 9px;
  }
`;

export default EntityCardListView;
