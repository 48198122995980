import { TextField } from "@mui/material";
import React, { useState } from "react";

interface NumberInputProps {
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  variant: any;
  id: string;
  error: boolean;
  helperText: string;
  label: string;
  name: string;
  placeholder: string;
  fullWidth: boolean;
}

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [formattedValue, setFormattedValue] = useState(formatNumber(value));

  function formatNumber(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value.replace(/\D/g, "");
    value = value === "" ? "0" : value;
    setFormattedValue(formatNumber(parseInt(value)));
    onChange({
      target: { value: value },
    } as React.ChangeEvent<HTMLInputElement>);
  }

  return <TextField {...rest} value={formattedValue} onChange={handleChange} />;
};

export default NumberInput;
