import { FC } from "react";
import { observer } from "mobx-react";
import { Dialog, DialogContent } from "@mui/material";
import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";
import WarningIcon from "./icons/WarningIcon";

type UpdateAttributesProps = {};

const UpdateAttributes: FC<UpdateAttributesProps> = observer(() => {
  return (
    <StyledDialog
      open={store.openUpdateAttributes}
      onClose={() => store.setData("openUpdateAttributes", false)}
    >
      <CloseBtnWrapper>
        <CloseButton
          onClick={() => store.setData("openUpdateAttributes", false)}
        >
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <DialogTitle>
        Are you sure you want to update attributes and segments for {store.survey?.participation_count} employee(s)?
      </DialogTitle>

      <StyledDialogContent>

        <WarningBlock>
          <WarningIcon />
          <WarningText>
            <WarningDescription>
              This action is irreversible and will update the attributes and segments of survey respondents who have already submitted their survey responses.
            </WarningDescription>
          </WarningText>
        </WarningBlock>

        <ButtonWrapper>
          <DeleteCustomButton variant="contained" onClick={() => { store.updateRespondentAttributes() }}>
            Proceed
          </DeleteCustomButton>
          <CancelCustomButton
            variant="outlined"
            onClick={() => store.setData("openUpdateAttributes", false)}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default UpdateAttributes;

const WarningBlock = styled.div`
  padding: 15px;
  display: flex;
  border-radius: var(--borderRadius, 4px);
  background: var(--colorPaletteRedForeground3, #FFCACA);
  margin-top: 20px;
`;

const WarningText = styled.div`
  margin-left: 10px;
`;

const WarningDescription = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 522px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 64px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h1`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  margin: 0px;
  padding: 10px 64px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 60px 0px;
`;

const DeleteCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  color: var(--colorNeutralForeground2) !important;
  border-color: var(--colorNeutralForeground2) !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;
