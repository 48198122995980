import { FC } from "react";
import ERMembersScreen from "features/er-members/ERMembersScreen";

type ERMembersPageProps = {};

const ERMembersPage: FC<ERMembersPageProps> = () => {
  return <ERMembersScreen />;
};

export default ERMembersPage;
