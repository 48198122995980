import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  .path-background {
    fill: var(--colorBrandForeground1);
  }
`;

export const AddIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="13.5" cy="13.5" r="13.5" fill="white" />
    <g clipPath="url(#clip0_501_22085)">
      <path
        d="M14.8304 6.58127L13.1687 6.58127L13.1687 13.1691L6.58085 13.1691V14.8309L13.1687 14.8309L13.1687 21.4187L14.8304 21.4187L14.8304 14.8309L21.4183 14.8308V13.1692L14.8304 13.1691L14.8304 6.58127Z"
        fill="#1477F8"
      />
    </g>
    <defs>
      <clipPath id="clip0_501_22085">
        <rect width="20" height="20" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </StyledSvg>
);
