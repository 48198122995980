import { FC, useState } from "react";

import styled from "styled-components";
import SearchInput from "./SearchInput";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";

type CustomTableHeaderProps = {
  Id?: string;
  title: string;
  renderHeader?: () => any;
  filter?: boolean;
  sort?: boolean;
  sort_by?: string | undefined;
  sort_type?: string | undefined;
  value: string;
  searchFunc: (newParams: { [key: string]: string | undefined }) => void;
  sortFunc: (field: string, sort: string) => void;
  sortModel?: { field: string | null; sort: string | null };
  search_by: string | undefined;
  search_value: string;
};

const SORT_STATUS = {
  disabled: "disabled",
  asc: "asc",
  desc: "desc",
  enabled: "enabled",
};

const CustomTableHeader: FC<CustomTableHeaderProps> = (props) => {
  const [searchState, setSearchState] = useState(SORT_STATUS.disabled);

  const closeSearchInput = () => {
    setSearchState(SORT_STATUS.disabled);
    props.searchFunc({
      search_by: undefined,
      search_value: "",
    });
  };

  const changeSearchInput = () => {
    setSearchState(SORT_STATUS.enabled);
    props.searchFunc({
      search_by: props.value,
      search_value: "",
    });
  };

  const sortable = Boolean(props.sortModel?.field === props.value);
  const searchable = Boolean(props.search_by === props.value);

  return (
    <HeaderWrapper
      $search={Boolean(searchState === SORT_STATUS.enabled) && searchable}
    >
      <Row $search={Boolean(searchState === SORT_STATUS.enabled) && searchable}>
        <Title id={props.Id ? `${props.Id}_Title` : null}>{props.renderHeader ? props.renderHeader() : props.title}</Title>
        {props.filter && (
          <IconWrapper id={props.Id ? `${props.Id}_SortBtn` : null}>
            {sortable && props.sortModel?.sort === SORT_STATUS.desc ? (
              <ArrowDropUpSharpIcon
                sx={{ fontSize: 22 }}
                style={{ color: "var(--colorBrandForeground1)" }}
                onClick={() => {
                  props.sortFunc(props.value, "asc");
                }}
              />
            ) : sortable && props.sortModel?.sort === SORT_STATUS.asc ? (
              <ArrowDropDownSharpIcon
                sx={{ fontSize: 22 }}
                style={{ color: "var(--colorBrandForeground1)" }}
                onClick={() => {
                  props.sortFunc(props.value, "desc");
                }}
              />
            ) : (
              <ArrowDropDownSharpIcon
                sx={{ fontSize: 22 }}
                style={{ color: "var(--colorNeutralForeground2)" }}
                onClick={() => {
                  props.sortFunc(props.value, "asc");
                }}
              />
            )}
          </IconWrapper>
        )}
      </Row>
      {props.sort && (
        <SearchWrapper
        
          $search={Boolean(searchState === SORT_STATUS.enabled && searchable)}
        >
          {searchable && searchState === SORT_STATUS.enabled ? (
            <SearchInput
              Id={props.Id ? `${props.Id}` : null}
              title={props.title}
              closeSearchInput={closeSearchInput}
              search_by={props.search_by}
              search_value={props.search_value}
              sortFunc={props.searchFunc}
            />
          ) : (
            <SearchSharpIcon
              id={props.Id ? `${props.Id}SearchButton` : null}
              sx={{ fontSize: 18 }}
              style={{ color: "var(--lightGrayColor)" }}
              onClick={changeSearchInput}
            />
          )}
        </SearchWrapper>
      )}
    </HeaderWrapper>
  );
};

export default CustomTableHeader;

const HeaderWrapper = styled.div<{ $search: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.$search ? "column" : "row")};
  align-items: center;
`;

const Title = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  margin: 0;
`;

const Row = styled.div<{ $search: boolean }>`
  width: ${(props) => (props.$search ? "100%" : "fit-content")};
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  margin: 0 2px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :hover {
    background-color: var(--colorPalleteLightBlue);
  }
`;

const SearchWrapper = styled.div<{ $search: boolean }>`
  width: ${(props) => (props.$search ? "100%" : "fit-content")};
  cursor: pointer;
  padding: 2px;
  margin: 0 2px;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
