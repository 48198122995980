import { Divider, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import Button from "components/button/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ProgressLoader from "./ProgressLoader";
import Errors from "./Errors";
import Success from "./Success";
import { observer } from "mobx-react"
import store from './store'
import { IconchngPhoto } from "features/Import-questions/IconchngPhoto";

type ImportBenchmarksProps = {};

const ImportBenchmarks: FC<ImportBenchmarksProps> = observer(() => {

  const params = useParams();
  const navigate = useNavigate();
  const surveyId = params.id;

  useEffect(() => {
    const id: number = parseInt(surveyId);
    if (!isNaN(id)) {
      store.doLoad(id)
    }else{
      navigate("/something-went-wrong")
    }
  }, [params.id])

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file != undefined) {
        store.setData("file", file)
        store.parseExcel();
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      handleFileChange(newFiles);
    }
  };
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };


  return (
    <Container>
      <BactToSurveys href={"/survey-projects/" + store.surveyId}>
        {"<"} Back to survey
      </BactToSurveys>

      <TopContainer>
        <LeftSide>
          <Header>
            {store.survey?.name}
          </Header>
          <SurveyId>
            Survey ID: {store.survey?.id}
          </SurveyId>
        </LeftSide>
      </TopContainer>

      <Divider />

      <Grid container>
        <Grid item xs={12} md={6}>
          <ContentLeft>
            <HeaderImport>Import custom benchmark</HeaderImport>
            <ImportImageWrapper>
              <ImportImage src={"/assets/img-import.png"} />
            </ImportImageWrapper>

            <FileWrapper>
              <div
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
              >
                <FileUploadIcon />
                <EmptyFileWrapperText>Drag and drop xlsx file to upload</EmptyFileWrapperText>
                <EmptyFileWrapperText>(.csv, .xlsx)</EmptyFileWrapperText>
                <EmptyFileWrapperText>Max file size of 5MB</EmptyFileWrapperText>
              </div>
            </FileWrapper>

            <div>
              <input
                type="file"
                ref={fileInputRef}
                accept=".csv, .xlsx"
                style={{ display: "none" }}
                onClick={handleUploadClick}
                onChange={(e) => handleFileChange(e.target.files)}
              />
              <label htmlFor={"uploadFile"}>
                <DownloadButton variant="text" onClick={handleButtonClick}>
                  <IconchngPhoto />
                  Browse files
                </DownloadButton>
              </label>
            </div>
          </ContentLeft>
        </Grid>
        <Grid item xs={12} md={6}>
          <ContentRight>
            {store.importCurrentStep === "ProgressLoader" &&
              <ProgressLoader />
            }
            {store.importCurrentStep === "Error" &&
              <Errors file={store.file} />}
            {store.importCurrentStep === "ReviewChanges" &&
              <Success />
            }
          </ContentRight>
        </Grid>
      </Grid>
    </Container >
  );
});

export default ImportBenchmarks;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 40px 150px 48px 150px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`

const BactToSurveys = styled.a`
  color: var(--colorNeutralForeground2, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const SurveyId = styled.div`
  color: var(--colorNeutralForeground2, #707882);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
  margin-left: 15px;
  margin-top: 10px;
`

const LeftSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ContentLeft = styled.div`
  width: 100%;
  max-width: 550px;
  margin-top: 20px;
`;

const ContentRight = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Header = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const HeaderImport = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 180% */
`;

const ImportImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const ImportImage = styled.img`
  width: 240px;
  height: 168px;
`;

const DownloadButton = styled(Button)`
  width: fit-content;
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  text-decoration: underline !important;

  svg {
    margin-right: 5px;
  }
`;

const FileWrapper = styled.div`
  width: 100%;
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--colorNeutralBackground1);
  border: 1px dashed var(--colorPaletteBlueBackground1);
  margin: 40px 0px 10px 0px;
  border-radius: 4px;

  svg {
    color: var(--colorNeutralForeground5);
  }
`;

const EmptyFileWrapperText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground5);
`;
