type UrlParams = {
  baseUrl?: string;
  path?: string;
  query?: Record<string, string>;
};

export const createUrl = ({ baseUrl, path, query }: UrlParams): string => {
  const effectiveBaseUrl = baseUrl || "http://placeholder";
  const url = new URL(path || "", effectiveBaseUrl);

  if (query) {
    url.search = new URLSearchParams(query).toString();
  }

  let urlString = url.toString();

  if (!baseUrl) {
    urlString = urlString.replace(new RegExp(`^${effectiveBaseUrl}`), "");
  }

  return urlString;
};
