import http from "./config/https";

export const useCreateERTemplate = (
  name: string,
  frequency: string,
  type: string,
  is_default: boolean,
  description: string,
  Image: any,
  Template: any
) => {
  const formData = new FormData();
  formData.append("Name", name);
  formData.append("Frequency", frequency);
  formData.append("Type", type);
  formData.append("MakeDefault", is_default ? "true" : "false");
  formData.append("Summary", description);
  formData.append("Template", Template);
  formData.append("Image", Image);

  return http.post(
    "/templ_template_questionary/upload_survey_template",
    formData
  );
};
