import { useNavigate } from "react-router-dom";
import { ReactElement } from "react";
import { prependBasePath } from "api/routes/basePath";

import Error from "./components/Error";

const Error404Screen = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <Error
      header="Oops!"
      subHeader="The page you're looking for does not exist."
      message="You may have mistyped the address or the page may have moved."
      onClickGoToPreviousPage={(): void => navigate(-1)}
      placeholderImageProps={{
        alt: "Oops",
        height: 254,
        width: 440,
        src: prependBasePath("/assets/kelly_404.png"),
      }}
    />
  );
};

export default Error404Screen;
