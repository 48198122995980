import { TEntity } from "types/entity";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";

type EntityListProps = {};

const EntityList: FC<EntityListProps> = observer(() => {
  const [entityList, setEntityList] = useState<TEntity[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<string>("");

  useEffect(() => {
    setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 1000);
  }, [searchValue]);

  useEffect(() => {
    if (store.selectedWorkspace) {
      if (!debouncedValue) {
        setEntityList(store.selectedWorkspace.entities);
      } else {
        setEntityList(
          store.selectedWorkspace.entities.filter((entity) =>
            entity.name
              .toLocaleLowerCase()
              .includes(debouncedValue.toLocaleLowerCase())
          )
        );
      }
    }
  }, [debouncedValue, store.selectedWorkspace]);

  return (
    <Container>
      <Header>
        <StyledSearchInput
          id="WorkspaceSettings_EntitySerchInput"
          size="small"
          placeholder="Entity"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton 
                id="WorkspaceSettings_EntitySerchBtn"
                onClick={() => {}}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Header>
      <EntityListContainer
      id={`WorkspaceSettings_EntityCard_Container`}
      >
        {entityList.length > 0 ? (
          entityList.map((entity, index) => (
            <EntityCard
            id={`WorkspaceSettings_EntityCard_${index}`}
              key={entity.id}
              onClick={() => store.setData("selectedEntity", entity)}
              $isActive={Boolean(entity.id === store.selectedEntity?.id)}
            >
              <EntityName
              id={`WorkspaceSettings_EntityCard_${index}_${entity.name}`}
              >{entity.name}</EntityName>
            </EntityCard>
          ))
        ) : (
          <EmptyList>
            <EmptyListText id={`WorkspaceSettings_EntityNoCardText`}>No entity</EmptyListText>
          </EmptyList>
        )}
      </EntityListContainer>
    </Container>
  );
});

export default EntityList;

const Container = styled.div`
  max-height: 80%;
  width: 220px;
  height: max-content;
  display: flex;
  flex-direction: column;
  background-color: var(--colorNeutralForeground8);
  box-shadow: 0px 1px 3px 0px var(--colorShadowInverted3);
`;

const Header = styled.div`
  width: 100%;
  padding: 16px;
`;

const EntityListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 500px;

  ::-webkit-scrollbar {
    width: 14px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: var(--colorShadowInverted2);
  }
`;

const EntityCard = styled.div<{ $isActive?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid var(--colorShadowInverted3);
  border-right: 1px solid var(--colorShadowInverted3);
  background-color: ${(props) =>
    props.$isActive
      ? `var(--colorPalleteLightBlue)`
      : `var(--colorNeutralBackground1)`};
  transition: all 0.3s;

  &:hover {
    background-color: ${(props) =>
      props.$isActive
        ? `var(--colorPalleteLightBlue)`
        : `var(--colorNeutralForegroundInverted1)`};
  }
`;

const EntityName = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 320px !important;
  background-color: transparent !important;
  margin: 0px !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 8px 11px !important;
    border-radius: 2px !important;
    font-family: Roboto;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 20px 0px;
  min-height: 180px;
`;

const EmptyListText = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-style: italic;
  letter-spacing: 0.15px;
  color: var(--colorNeutralForeground5);
`;
