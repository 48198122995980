import http from "./config/https";

const getRecepientsFilter = (
  survey_id: number,
  searchValue: string,
  page: number,
  pageSize: number,
  sortField: string,
  sortType: string,
  filterField: string,
  filterOperator: string,
  filterValue: string
) => {
  return http.post(
    `/sm_survey_recepients/GetRecepientsSurveyInternalTool`,
    {
      survey_id,
      searchValue,
      page,
      pageSize,
      sortField,
      sortType,
      filterField,
      filterOperator,
      filterValue,
    },
    {}
  );
};

export default getRecepientsFilter;
