import { ReactElement, ReactNode } from "react";

import styled from "styled-components";
import TopBannerLogo from "../../../components/header/TopBannerLogo";

type ErrorProps = {
  header: ReactNode;
  subHeader: ReactNode;
  message: ReactNode;
  onClickGoToPreviousPage: () => void;
  placeholderImageProps: any;
};

const Error = ({
  header,
  subHeader,
  message,
  onClickGoToPreviousPage,
  placeholderImageProps,
}: ErrorProps): ReactElement => {
  return (
    <Container>
      <TopBannerLogo />

      <H1>{header}</H1>

      <H2>{subHeader}</H2>

      <Message>{message}</Message>

      <PreviousPageButton onClick={onClickGoToPreviousPage}>
        Go back to the previous page
      </PreviousPageButton>

      <ImageContainer>
        <img {...placeholderImageProps} alt="Error" />
      </ImageContainer>
    </Container>
  );
};

export default Error;

const Container = styled.div`
  background-color: var(--colorNeutralBackground2);
  color: var(--colorNeutralForeground1);
  text-align: center;
  min-height: 100vh;
`;

const H1 = styled.h1`
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.96px;
  margin: 63px 0 24px 0;
`;

const H2 = styled.h2`
  font-size: 28px;
  font-weight: 300;
  padding: 0 16px;
`;

const Message = styled.p`
  font-size: 15px;
  margin: 20px auto;
  max-width: 570px;
  padding-bottom: 20px;
  padding: 0 16px;
`;

const PreviousPageButton = styled.button`
  all: unset;
  color: var(--colorBrandForeground1);
  cursor: pointer;
  border-bottom: 1px dashed;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-decoration: none;
`;

const ImageContainer = styled.div`
  margin: 32px;
`;
