import ReactPagination from "react-js-pagination";
import styled from "styled-components";

export interface PaginationProps {
  showTotalPage?: boolean;
  totalPages?: number;
  onChange: (pageNumber: number) => void;
  activePage: number;
  pageRangeDisplayed?: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
}

const Pagination = (props: PaginationProps) => {
  const { showTotalPage, totalPages } = props;

  return (
    <StyledPagination
      className="table-pagination-wrapper"
      showTotalPage={showTotalPage}
    >
      {showTotalPage && (
        <PageProgress>
          Page {props.activePage} of {totalPages}
        </PageProgress>
      )}
      <ReactPagination
        prevPageText={"<"}
        nextPageText={">"}
        activeLinkClass="active-page-box"
        linkClass="paginate-links"
        {...props}
      />
    </StyledPagination>
  );
};

export default Pagination;

const StyledPagination = styled.div<{ showTotalPage?: boolean }>`
  display: ${({ showTotalPage }) => (showTotalPage ? "flex" : "inline-flex")};
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto";
  font-size: 12px;

  .pagination {
    display: inline-block;
    margin: 0;
    padding: 0;

    > li {
      display: inline;

      > a {
        align-items: center;
        background: var(--colorNeutralBackground1);
        border: 1px solid var(--colorPaletteBlueBackground1);
        box-sizing: border-box;
        color: var(--colorNeutralForeground1);
        display: inline-flex;
        justify-content: center;
        height: 36px;
        text-decoration: none;
        width: 36px;

        &:hover,
        &:focus {
          background: var(--colorPaletteBlueBackground2);
          color: var(--colorBrandForeground1);
          outline: 0;
        }
      }

      :first-child > a {
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      :last-child > a {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.active {
        > a {
          background: var(--colorBrandForeground1);
          color: var(--colorNeutralBackground1);
          border: 1px solid var(--colorBrandForeground1);
        }
      }

      &.disabled {
        > a {
          background: var(--colorNeutralBackground1);
          border: 1px solid var(--colorPaletteBlueBackground1);
          color: var(--colorNeutralForeground1);
          cursor: not-allowed;
        }
      }
    }
  }
`;

const PageProgress = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.7384616136550903px;
  color: var(--colorNeutralForeground2);
  margin-right: 20px;
`;
