import { useState } from "react";
import { observer } from "mobx-react";
import { Button } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";

const XLSUploader = observer(() => {
  const [mainState, setMainState] = useState("initial");

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    uploadFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const fileType = file.name.split('.').pop().toLowerCase();
      if (['csv', 'xls', 'xlsx'].includes(fileType)) {
        uploadFile(file);
      } else {
        alert('Please drop only CSV, XLS, or XLSX files.');
      }
    }
  };

  const uploadFile = (file) => {
    const reader = new FileReader();

    reader.onloadend = function (e) {
      store.setData("xlsUpload", [reader.result]);
    };

    setMainState("uploaded");
    store.setData("xlsUpload", file);
  };

  const clearFile = () => {
    setMainState("initial");
    store.setData("xlsUpload", null);
  };

  const renderEmptyUploader = () => {
    return (
      <EmptyUploaderLabel 
        id="Uploader_EmptyUploader"
        htmlFor="contained-button-file-xls"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <img src={"/assets/upload-icon.png"} />
        <ButtonWrapper id="Uploader_ButtonWrapper">
          <Button id="Uploader_Button" component="span">Click to upload</Button>
          <ButtonHelpText id="Uploader_ButtonHelpText">or drag and drop</ButtonHelpText>
        </ButtonWrapper>
        <LimitUpload id="Uploader_LimitUpload">XLS (max. 10MB) </LimitUpload>
        <input
          accept=".csv, .xls, .xlsx, text/csv, application/csv"
          id="contained-button-file-xls"
          type="file"
          onChange={handleUploadClick}
          style={{ display: "none" }}
        />
      </EmptyUploaderLabel>
    );
  };

 
  const fillUploader = () => {
    return (
      <FillUpload id="Uploader_FillUpload">
        <img src={"/assets/upload-icon.png"} id="Uploader_UploadIcon" />
        <FileInfo id="Uploader_FileInfo">
          <FileName id="Uploader_FileName">{store.xlsUpload?.name}</FileName>
          <FileSize id="Uploader_FileSize">{store.xlsUpload?.size / 1000}kb</FileSize>
        </FileInfo>
        <DeleteButton onClick={clearFile} id="Uploader_DeleteButton">
          <ClearIcon id="Uploader_ClearIcon" />
        </DeleteButton>
      </FillUpload>
    );
  };

  return (
     <UploadedWrapper id="Uploader_UploadedWrapper" $state={Boolean(mainState == "initial")}>
      {mainState === "initial" ? renderEmptyUploader() : fillUploader()}
    </UploadedWrapper>
  );
});

export default XLSUploader;

const UploadedWrapper = styled.div<{ $state: boolean }>`
  width: 100%;
  margin-top: 20px;
`;

const EmptyUploaderLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 152px;
  border: 1px dashed var(--colorShadowInverted3);
  border-radius: 4px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;

  .MuiButtonBase-root,
  .MuiButton-root {
    text-transform: none !important;
    text-decoration: underline;
    margin-right: 4px;
  }
`;

const ButtonHelpText = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: var(--colorShadowInverted4);
`;

const LimitUpload = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: var(--colorShadowInverted2);
`;

const FillUpload = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  column-gap: 10px;
`;

const FileInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const FileName = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--colorShadowInverted4);
`;

const FileSize = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: var(--colorShadowInverted2);
`;

const DeleteButton = styled(Button)``;