import { FC } from "react";
import { IconXlsx } from "./IconXlsx";
import styled from "styled-components";
import { observer } from "mobx-react"
import store from "./store"
import { SuccessIcon } from "./SuccessIcon";

type SuccessProps = {
};

const Success: FC<SuccessProps> = observer((props) => {
  return (
    <ReviewChangesWrapper>
      <Content>
        <Header>Successfully imported!</Header>
        <FileWrapper>
          <IconXlsx />
          <p>{store.file?.name || "File not found"}</p>
        </FileWrapper>
        <SubHeader>Import summary</SubHeader>
        <Row>
          <StatisticContainer>
            <span>{store.surveyParseResult.result.length}</span>
            <p>question_codes updated</p>
          </StatisticContainer>
          <StatisticContainer>
            <SuccessIcon />
            <p>Benchmarks successfully refreshed</p>
          </StatisticContainer>
        </Row>
      </Content>
    </ReviewChangesWrapper>
  );
});

export default Success;

const ReviewChangesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px 0px 20px 0px;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1);
  }

  svg {
    margin-right: 10px;
  }
`;

const SubHeader = styled.span`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
`;

const StatisticContainer = styled.div<{ $notLast?: boolean }>`
  width: 100%;
  max-width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
  background-color: var(--colorNeutralBackground1);
  ${(props) => !props.$notLast && "margin-right: 30px"};
  height: 125px;
  margin-top: 20px;

  span,
  p {
    font-family: Roboto;
    text-align: center;
  }

  span {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    color: var(--colorNeutralForeground2);
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
  }
`;

const Row = styled.div`
  display: flex;
`;
