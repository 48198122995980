import { observer } from "mobx-react";
import {
  DataGrid, GridSortModel, GridFilterItem,
  GridFilterInputValueProps, getGridStringOperators, GridToolbarFilterButton
} from '@mui/x-data-grid';
import React from "react";
import store from "./store";
import { InputLabel } from '@mui/material';
import Button from "components/button/Button";
import styled from "styled-components";
import { GridColDef } from "@mui/x-data-grid/models";
import { RECEPIENT_STATUSES } from "constants/recepientStatuses";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CachedIcon from '@mui/icons-material/Cached';

type RecipientsProps = {
};

const Recipients: React.FC<RecipientsProps> = observer((props) => {

  const tableData: GridColDef[] = [
    {
      field: "full_name",
      headerName: "Full Name",
      sortable: true,
      flex: 3,
      renderHeader: () => {
        return <HeaderTable id="Survey_View_Recipients_Table_Header_FullName">Full Name <HeaderTable id="Survey_View_Recipients_Table_Header_PreferredName" $second={true}>(Preferred name)</HeaderTable></HeaderTable>
      },
      renderCell: (params) => {
        return <HeaderTable id={"Survey_View_Recipients_Table_FullName_" + params.row.id}>{params.row.full_name} <HeaderTable id={"Survey_View_Recipients_Table_PreferredName_" + params.row.id} $second={true}>({params.row.preferred_name})</HeaderTable></HeaderTable>
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains' || operator.value === 'equals',
      ),
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      flex: 3,
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_Recipients_Table_Header_Email">Email</HeaderTable>
      },
      renderCell: (params) => {
        return <span id={"Survey_View_Recipients_Table_Email_" + params.row.id}>{params.row.email}</span>
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains' || operator.value === 'equals',
      ),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1,
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_Recipients_Table_Header_Status">Status</HeaderTable>
      },
      renderCell: (params) => {
        return <span id={"Survey_View_Recipients_Table_Status_" + params.row.id}>{params.row.status}</span>
      },
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
              return null;
            }
          },
          InputComponent: StatusesInputValue,
          InputComponentProps: { type: 'string' },
        },
      ],
    },
    {
      field: "id",
      sortable: false,
      flex: 1,
      width: 70,
      disableColumnMenu: true,
      filterable: false,
      renderHeader: () => (<></>),
      renderCell: (params) => {
        return <>
          {params.row.status_code === RECEPIENT_STATUSES.pending
            && <Button id={"Survey_View_Recipients_Table_ResendInvite_Button_" + params.row.id} onClick={() => store.resendInvite(params.row.id, params.row.preferred_name)} startIcon={<MailOutlineIcon />} variant="outlined">Resend invite</Button>}
          {params.row.status_code === RECEPIENT_STATUSES.completed
            && <Button id={"Survey_View_Recipients_Table_ResetResponse_Button_" + params.row.id} onClick={() => store.resetResponse(params.row.id, params.row.preferred_name)} startIcon={<CachedIcon />} variant="outlined">Reset response</Button>}
          {(params.row.status_code === RECEPIENT_STATUSES.removed_manually
            || params.row.status_code === RECEPIENT_STATUSES.removed_by_the_system)
            && <Button id={"Survey_View_Recipients_Table_ResendInvite_Disabled_Button_" + params.row.id} disabled variant="outlined">Resend invite</Button>}
        </>
      },
    },
  ];


  return (
    <Container>
      <StyledDataGrid
        rows={store.recepientList}
        columns={tableData}
        initialState={{
          pagination: {
            paginationModel: { page: store.recepientsPage, pageSize: store.recepientsPageSize },
          },
        }}
        rowCount={store.recepientsTotalCount}
        pageSizeOptions={[10, 25, 50, 100]}
        rowSelection={false}
        onFilterModelChange={(model, details) => {
          store.changeFilterRecepients(model)
        }}
        slots={{
          toolbar: GridToolbarFilterButton,
        }}
        paginationMode="server"
        filterMode="server"
        onSortModelChange={(sortModel: GridSortModel) => {
          store.changeSortRecepients(sortModel)
        }}
        onPaginationModelChange={(model) => {
          store.changePaginationRecepients(model.page, model.pageSize)
        }}
        sortingMode="server"
      />
    </Container>
  );
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      padding: 0
    },
  },
};

const StatusesInputValue = observer((props: GridFilterInputValueProps) => {
  const { item, applyValue, focusElementRef } = props;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    applyValue({ ...item, value: typeof value === 'string' ? value.split(',') : value })
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">Value</InputLabel>
        <StyledSelect
          multiple
          variant="standard"
          labelId="demo-multiple-checkbox-label"
          value={item.value ?? []}
          onChange={handleChange}
          renderValue={(selected: any) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {store.recepientStatuses.map((status) => (
            <CustomMenu key={status.id} value={status.name}>
              <Checkbox checked={(item.value ?? []).indexOf(status.name) > -1} />
              <ListItemText primary={status.name} />
            </CustomMenu>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
})


export default Recipients;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
`;

const HeaderTable = styled.span<{ $second?: boolean }>`
  color: ${props => props.$second ? "var(--colorShadowInverted5, rgba(0, 0, 0, 0.38))"
    : "var(--colorNeutralBackgroundInverted2, #10141f)"} !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%; /* 31.92px */
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const CustomMenu = styled(MenuItem)`
  padding: 0 !important;
`;

const StyledDataGrid = styled(DataGrid)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 0px !important;

  .MuiDataGrid-columnHeaderTitleContainer {
    background-color: var(--colorPaletteBlueBackground2);
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--colorPaletteBlueBackground2);
    border-radius: 0px !important;
    text-transform: uppercase !important;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer !important;
  }

  .Mui-selected {
    background-color: transparent !important;
  }
  .MuiCheckbox-root {
    color: var(--colorBrandForeground1) !important;
  }


  .MuiDataGrid-root {
    border: none !important;
  }
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .Mui-odd {
    background-color: var(--colorNeutralForeground8);
  }
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border-right: 1px solid var(--graySelectBoxBorder);
    color: var(--Light-Text-Primary, var(--text-primary, rgba(0, 0, 0, 0.87)));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
  }
`;
