import http from "./config/https";

export const useImpersonateLogin = (accountID: number) => {
  if (http.isAPIGO) {
    return http.postGO(`/permission/ImpersonateLogin`, { accountId: accountID });
  } else {
    return http.post(`/permission/ImpersonateLogin`, { accountId: accountID });
  }
};

export const useImpersonateLogout = (accountID: number) => {
  if (http.isAPIGO) {
    return http.postGO(`/permission/ImpersonateLogout`, { accountId: accountID });
  } else {
    return http.post(`/permission/ImpersonateLogout`, { accountId: accountID });
  }
};
