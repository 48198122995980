import { useMutation } from "@tanstack/react-query";

import http from "./config/https";

export type CreateWorkspaceSuccessResponseData = {
  id: string;
};

const createWoskspace = async (workspaceData: {
  name: string;
  sign_in_method: 0 | 1;
  logo: any
}) => {

  const formData = new FormData();

  formData.append("name", workspaceData.name);
  formData.append("region_id", "1");
  formData.append("sign_in_method", workspaceData.sign_in_method + "");
  formData.append("Document.name", workspaceData.logo ? workspaceData.logo.name : "");
  formData.append("Document.file", workspaceData.logo);

  try {
    if (http.isAPIGO) {
      const { data } = await http.postGO('/workspace', formData, { 'Content-Type': 'multipart/form-data' });
      return data;
    } else {
      const { data } = await http.post('/workspace/AddOrUpdate', formData, { 'Content-Type': 'multipart/form-data' });
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const useCreateWoskspace = () => {
  return useMutation({
    mutationFn: createWoskspace,
  });
};
