import { UseQueryResult, useQuery } from "@tanstack/react-query";

import http from "./config/https";

export type GetWorkspaceSuccessResponseData = {
  workspace: {
    created_at: string;
    created_by: string;
    demo: boolean;
    id: number;
    industry: string;
    name: string;
    proj_m: boolean;
    sign_in_method: number;
    updated_at: string;
    updated_by: string;
    logo_id?: number;
    logo_url?: string
  };
};

const getWoskspaceById = async (workspaceId: string) => {
  if (http.isAPIGO) {
    const { data } = await http.getGO(`/workspace/getOneById?id=${workspaceId}`);
    return { workspace: data };
  } else {
    const { data } = await http.get(`/workspace/getOneById?id=${workspaceId}`);
    return { workspace: data };
  }
};

export const useGetWoskspaceById = (id: string): UseQueryResult<any, Error> => {
  const queryResult = useQuery(["workspaces/detail", id], {
    queryFn: () => getWoskspaceById(id),
    enabled: id !== "undefined" && Boolean(id),
    onError: (error: Error) => error,
  });

  return queryResult;
};
