import { TNewWorkspace } from "types/workspace";
import { FC, ReactElement } from "react";

import React from "react";
import Radio from "@mui/material/Radio";
import Button from "components/button/Button";
import styled from "styled-components";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import CustomModal from "components/modal/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";

const OPTIONS = {
  email_and_password: {
    name: "Via email and password",
    value: 0,
  },
  microsoft: {
    name: "Login with",
    value: 1,
  },
};

type ModalCreateWorkspaceSigninTypeProps = {
  open: boolean;
  hide: (state: boolean) => void;
  workspaceData: TNewWorkspace;
  handleChangeWorkspaceData: (name: string, value: number) => void;
  createNewWorkspace: () => void;
};

const ModalCreateWorkspaceSigninType: FC<
  ModalCreateWorkspaceSigninTypeProps
> = (props): ReactElement => {
  return (
    <CustomModal open={props.open} hide={props.hide} width="468">
      <StyledModalTitle id="Workspace_createWorkspacePopUp_Title">
        How will workspace users on {props.workspaceData.name} sign in?
      </StyledModalTitle>
      <StyledModalContent aria-label={"Create workspace form"}>
        <FormControl>
          <RadioWrapper
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={props.workspaceData.sign_in_method}
            onChange={(e) =>
              props.handleChangeWorkspaceData(
                "sign_in_method",
                Number(e.target.value)
              )
            }
          >
            <StyledRadioWrapper>
              <StyledRadio
                id="Workspace_createWorkspacePopUp_RadioBtn_1"
                value={OPTIONS.email_and_password.value}
                control={<Radio  id="Workspace_createWorkspacePopUp_RadioBtnInput_2"/>}
                label={OPTIONS.email_and_password.name}
              />
            </StyledRadioWrapper>
            <StyledRadioWrapper>
              <StyledRadio
                id="Workspace_createWorkspacePopUp_RadioBtn_2"
                value={OPTIONS.microsoft.value}
                control={<Radio id="Workspace_createWorkspacePopUp_RadioBtnInput_2"/>}
                label={ 
                  <React.Fragment>
                    <RadioLabel>{OPTIONS.microsoft.name}</RadioLabel>
                    <MicrosoftImage
                      src="/assets/microsoft_login.png"
                      width={86}
                      height={22}
                      alt="Login with Microsoft"
                    />
                  </React.Fragment>
                }
              />
            </StyledRadioWrapper>
          </RadioWrapper>
        </FormControl>

        <ButtonWrapper>
          <StyledCreateButton
          id="Workspace_createWorkspacePopUp_CreateWorkspace_Btn"
            variant="outlined"
            onClick={props.createNewWorkspace}
          >
            Create workspace
          </StyledCreateButton>
          <StyledCancelButton
          id="Workspace_createWorkspacePopUp_Cancel_Btn"
            variant="outlined"
            onClick={() => {
              props.hide(false)
              props.handleChangeWorkspaceData("logo", null)
              props.handleChangeWorkspaceData("name", null)
            }}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalCreateWorkspaceSigninType;

const StyledModalTitle = styled.div`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 20px;
`;

const StyledModalContent = styled.div``;

const StyledRadioWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  position: relative;
`;

const RadioLabel = styled.p`
  margin: 0px;
`;

const MicrosoftImage = styled.img`
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px !important;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border: 1px solid var(--colorNeutralForeground1) !important;
`;

const StyledCreateButton = styled(Button)`
  width: 100%;
  max-width: 144px;
  white-space: nowrap;
  height: 36px;
`;

const RadioWrapper = styled(RadioGroup)`
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  column-gap: 11px;
`;

const StyledRadio = styled(FormControlLabel)`
  width: 100%;
  max-width: 164px;
  padding: 16px 11px;
  margin: 0px !important;

  span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
`;
