import { DataGrid } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { TEntityNumberPeople } from "types/entity";
import { IconButton, Tooltip } from "@mui/material";

import store from "../../store";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";

type NumberPeopleTableProps = {
  list: TEntityNumberPeople[];
};

const NumberPeopleTable: FC<NumberPeopleTableProps> = observer((props) => {
  useEffect(() => {
    if ((store.tabId === 0, store.selectedEntity)) {
      store.getEntityNumberPeoples(store.selectedEntity.id);
    }
  }, [store.selectedEntity, store.tabId]);

  const tableData = [
    {
      field: "settings",
      headerName: "",
      width: 50,
      sortable: false,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column${params.colDef.headerName}`}></span>
      },
      disableColumnMenu: true,
      renderCell: (params) => (
        <TableCellWrapper>
          <Tooltip title={"Edit"}>
            <IconButton
              id={`WorkspaceSettings_GridContainer_Tab_Column_SettingsBtn${params.id}`}
              size="small"
              onClick={() => {
                store.setData("openPanelEditEntitySetting", true);
                store.setData("selectedNumberPeople", params.row);
              }}
            >
              <EditIconWrapped />
            </IconButton>
          </Tooltip>
        </TableCellWrapper>
      ),
    },
   
    {
      field: "admin_count",
      headerName: "ENTITY ADMINS",
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column${params.colDef.headerName}`}>{params.colDef.headerName}</span>
      },
    },
    {
      field: "user_count",
      headerName: "ENTITY LEVEL USERS",
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column${params.colDef.headerName}`}>{params.colDef.headerName}</span>
      },
      renderCell: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column_UserCount_table_${params.id}`}>{params.value}</span>
      }
    },
    {
      field: "active_seat_count",
      headerName: "MAX LEVEL ACTIVE SEATS",
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column${params.colDef.headerName}`}>{params.colDef.headerName}</span>
      },
      renderCell: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column_MaxLevelActiveSeats_table_${params.id}`}>{params.value}</span>
      }
    },
    {
      field: "employee_count",
      headerName: "ENTITY LEVEL EMPLOYEES",
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column${params.colDef.headerName}`}>{params.colDef.headerName}</span>
      },
      renderCell: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column_EmployeeCount_table_${params.id}`}>{params.value}</span>
      }
    },
    {
      field: "strbegin_date",
      headerName: "VALIDITY PERIOD",
      sortable: false,
      width: 350,
      flex: 0.1,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_Tab_Column_${params.colDef.headerName}`}>{params.colDef.headerName}</span>
      },
      renderCell: (params) => {
        if(params.row.strbegin_date === null || params.row.strend_date === null){
          return ""
        }
        const strbegin_date = new Date(params.row.strbegin_date);
        const strend_date = new Date(params.row.strend_date);
        const formattedDateStart = new Intl.DateTimeFormat('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }).format(strbegin_date);

        const formattedDateEnd = new Intl.DateTimeFormat('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }).format(strend_date);

        return <TableCellWrapper id={`WorkspaceSettings_GridContainer_Tab_Column_ValidityPeriod_table_${params.id}`}>
          {formattedDateStart} to {formattedDateEnd}
        </TableCellWrapper>
      },
    },
  ];

  return (
    <StyledDataGrid rows={props.list} columns={tableData} hideFooter={true} />
  );
});

export default NumberPeopleTable;

const TableCellWrapper = styled.div`
  display: flex;
  min-height: 50px;
  justify-content: center;
  align-items: center;
`;

const EditIconWrapped = styled(EditIcon)`
  color: var(--colorNeutralForeground5);
`;

const StyledDataGrid = styled(DataGrid)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 0px !important;

  .MuiDataGrid-columnHeaderTitleContainer {
    background-color: var(--colorNeutralForeground8);
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--colorNeutralForeground8);
    border-radius: 0px !important;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer !important;
  }

  .Mui-selected {
    background-color: transparent !important;
  }
  .MuiCheckbox-root {
    color: var(--colorBrandForeground1) !important;
  }

  .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
    display: none !important;
  }

  .MuiDataGrid-root {
    border: none !important;
  }
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .Mui-odd {
    background-color: var(--colorNeutralForeground8);
  }
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border-right: 1px solid var(--graySelectBoxBorder);
  }
`;
