import { FC } from "react";
import { TemplatesScreen } from "features/templates";

type TemplatesPageProps = {};

const TemplatesPage: FC<TemplatesPageProps> = () => {
  return <TemplatesScreen />;
};

export default TemplatesPage;
