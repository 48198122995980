import * as React from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { ITableData, TableHeader } from "../../types/tableData";
import CustomTableHeader from "./CustomHeader";

type CustomTableProps = {
  list: { [key: string]: any }[] | [];
  id?: string;
  data: ITableData;
  sort_by?: string;
  sort_type?: string;
  search: (newParams: { [key: string]: string | undefined }) => void;
  sort: (field: string, sort: string) => void;
  sortModel?: { field: string | null; sort: string | null };
  search_value: string;
  search_by: string | undefined;
  filed_key?: string;
  renderCustomRows?: (row: any) => React.ReactNode;
};

const CustomTable: React.FC<CustomTableProps> = (props) => {
  const { list, data, filed_key, id, sort_by, sort_type, search, sort, sortModel, search_value, search_by, renderCustomRows } = props;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead id={id && `TableHeader_Container_${id}`}>
          <TableRow id={id && `TableHeader_Row_${id}`}>
            {data.headers
              .filter((x) => x.hidden !== true)
              .map((header: TableHeader, index) => (
                <TableCell id={id && `Column_${index}_${id}`} key={header.value}>
                  <CustomTableHeader
                    Id={id && `Column_${index}_${id}`}
                    title={header.title}
                    filter={header.filter}
                    sort={header.sort}
                    sort_by={sort_by}
                    sort_type={sort_type}
                    value={header.value}
                    searchFunc={search}
                    sortFunc={sort}
                    sortModel={sortModel}
                    search_value={search_value}
                    search_by={search_by}
                  />
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody  id = {id && `TableBody_${id}`}>
          {list && (
            <>
              {list.map((row: { [key: string]: any }, indexRow) => (
                <React.Fragment key={filed_key ? row[filed_key] : row.id}>
                  <TableRow
                    id={id && `Row_${indexRow}_${row.id}__${id}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {data.headers
                      .filter((x) => x.hidden !== true)
                      .map((el, index) => (
                        <TableCell
                          id={id && `Table_${index}_${indexRow}_${row.id}_${id}`}
                          component="th"
                          scope="row"
                          key={el.value}
                        >
                          {el.renderRow ? el.renderRow(row, index, indexRow) : <TableText>{row[el.value]}</TableText>}
                        </TableCell>
                      ))}
                  </TableRow>
                  {/* Render custom rows if provided */}
                  {renderCustomRows && renderCustomRows(row)}
                </React.Fragment>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;

const TableText = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;