import { Input } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { createWorkspaceEntityAdmin } from "api/useCreateWorkspaceAdmin";
import { Form, Formik, FormikHelpers, ErrorMessage } from "formik";
import { TWorkspace } from "types/workspace";

import * as Yup from "yup";
import styled from "styled-components";
import Button from "components/button/Button";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CustomModal from "components/modal/Modal";
import { TWorkspaceAdmin } from "../../../types/workspace";

import { getEntitiesByWorkspace } from "api/sharp/useGetEntitiesByWorkspace"
import MainStore from "MainStore";



type ModalChnagePasswordProps = {
  open: boolean;
  hide: (state: boolean) => void;
  onSave: () => void;
  workspaceId: number;
  workspaceInfo: TWorkspace | undefined;
  currentAdmins: TWorkspaceAdmin[];
};

const ModalCreateEntityAdmin: FC<ModalChnagePasswordProps> = (
  props
): ReactElement => {
  const [entityId, setEntityId] = useState(0);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required(""),
    entity_id: Yup.number().required().moreThan(0)
  });

  const [entities, setEntities] = useState([]);

  useEffect(() => {
    if (props.open) {
      getEntitiesByWorkspace(props.workspaceInfo.id).then(x => {
        if (x?.data !== undefined) {
          setEntities(x.data);
        }
      });
    }
  }, [props.open])

  const submitForm = async (
    values: { email: string, entity_id?: number },
    {
      setFieldError,
    }: FormikHelpers<{
      email: string;
      entity_id?: number;
    }>
  ) => {
    let { email, entity_id } = values;
    if (props.workspaceId) {

      try {
        MainStore.changeLoader(true);
        const response = await createWorkspaceEntityAdmin({ email, workspaceId: props.workspaceId, entityId: entity_id });
        let data = response?.data ?? response?.response?.data;
        if (data?.isExists) {
          MainStore.setSnackbar("There is an existing user with the same email address", "error")
        }
        // this is a temporary error handler
        else if (data?.error) {
          MainStore.setSnackbar("There is an existing user with the same email address", "error")
        } else if (data?.isSend) {
          MainStore.setSnackbar(`An invite has been sent to ${email}`, "success")
        } else {
          MainStore.setSnackbar(`User ${email} added as admin`, "success")
          props.hide(false);
          props.onSave();
        }
      } catch (err) {
        MainStore.setSnackbar("Invite was unsuccessful. Please check Sendgrid and open a ticket for RS", "error")
      } finally {
        MainStore.changeLoader(false);
      }
    } else return;

  };

  return (
    <CustomModal id="Workspace_Manage_AddEntityAdmin_Popup" open={props.open} hide={props.hide} width="468">
      <StyledModalTitle id="Workspace_Manage_AddEntityAdmin_Popup_Title">Add entity admin</StyledModalTitle>
      <Description id="Workspace_Manage_AddEntityAdmin_Popup_Description">
        An email invite will be sent immediately to them.
      </Description>
      <StyledModalContent aria-label={"Create entity form"}>
        <Formik
          initialValues={{ email: "", entity_id: null }}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={validationSchema}
          onSubmit={submitForm}
        >
          {({ isValid, dirty, values, handleChange, handleBlur, setFieldValue }) => (
            <Form>

              <InputLabel id="Workspace_Manage_AddEntityAdmin_Popup_SelectEntity_InputLabel">Select Entity</InputLabel>

              <StyledAutocomplete
                id="Workspace_Manage_AddEntityAdmin_Popup_SelectEntity_Input"
                options={entities}
                getOptionLabel={(option: any) => option.name}
                onChange={(e, value: any) => {
                  setFieldValue("entity_id", value?.id);
                  setEntityId(value?.id ?? 0)
                }}
                renderInput={params => (<TextField variant="standard" {...params} />)}
              />

              <InputLabel id="Workspace_Manage_AddEntityAdmin_Popup_Email_InputLabel">Email address</InputLabel>

              <StyledInput
                name="email"
                id="Workspace_Manage_AddEntityAdmin_Popup_Email_Input"
                placeholder="Enter email address"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />

              <ErrorMessage name="email">
                {msg => <StyledError id="Workspace_Manage_AddEntityAdmin_Popup_Email_Input_Errror">{msg}</StyledError>}
              </ErrorMessage>

              <ButtonWrapper>
                <StyledNextButton
                  id="Workspace_Manage_AddEntityAdmin_Popup_Save_Button"
                  variant="outlined"
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  Add entity admin
                </StyledNextButton>
                <StyledCancelButton
                  id="Workspace_Manage_AddEntityAdmin_Popup_Cancel_Button"
                  variant="outlined"
                  onClick={() => props.hide(false)}
                >
                  Cancel
                </StyledCancelButton>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalCreateEntityAdmin;

const StyledModalTitle = styled.span`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const StyledModalContent = styled.div``;

const StyledAutocomplete = styled(Autocomplete)`
  margin-bottom: 15px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  margin-top: 6px;

  label {
    margin-top: 24px;
  }

  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--grayInputPlaceholder);
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px !important;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border: 1px solid var(--colorNeutralForeground1) !important;
`;

const StyledNextButton = styled(Button)`
  width: 100%;
  max-width: 180px;
  height: 36px;
`;

const StyledError = styled.div`
  color: var(--colorPaletteRedForeground2);
`

const Description = styled.p`
  width: 100%;
  color: var(--colorNeutralForeground1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 16px 0px 40px 0px;
  margin: 0px;
`;

const InputLabel = styled.label`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
`;
