import { FC } from "react";
import { ImportBenchmarks } from "features/Import-benchmarks";

type ImportBenchmarksPageProps = {};

const ImportBenchmarksPage: FC<ImportBenchmarksPageProps> = () => {
  return <ImportBenchmarks />;
};

export default ImportBenchmarksPage;
