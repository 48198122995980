import { useNavigate } from "react-router-dom";
import { ReactElement } from "react";
import { prependBasePath } from "api/routes/basePath";

import Error from "./components/Error";

const Error403Screen = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <Error
      header="Oops!"
      subHeader="You do not have access to this page."
      message="Please contact your company administrator or contact customer support for enquiries."
      onClickGoToPreviousPage={(): void => navigate(-1)}
      placeholderImageProps={{
        alt: "Oops",
        height: 232,
        width: 232,
        src: prependBasePath("/assets/kelly_cluckson.png"),
      }}
    />
  );
};

export default Error403Screen;
