import { FC } from "react";
import { observer } from "mobx-react";
import store from "../store";
import styled from "styled-components";
import WarningIcon from "../icons/WarningIcon";
import CustomButton from "components/button/Button";
import DateTimeField from "components/date-time-field/DateTimeField";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import TimeField from "components/time-field/TimeField";
import dayjs from "dayjs";

type ReopenSurveyProps = {};

const ReopenSurvey: FC<ReopenSurveyProps> = observer(() => {

  return (
    <Container>

      <WarningBlock>
        <WarningIcon />
        <WarningText>
          <WarningMainText id="Survey_View_ReopenSurvey_Title">
            Are you sure you want to reopen this survey? Reopening a survey will:
          </WarningMainText>
          <WarningDescription>
            <ul>
              <li id="Survey_View_ReopenSurvey_Text1">
                Affect existing score data displayed on the dashboard when new survey responses are submitted. To have a copy of the existing score data, please export from the dashboard now first before reopening survey.
              </li>
              <li id="Survey_View_ReopenSurvey_Text2">
                Potentially affect benchmarks displayed for this survey. If the revised survey closure date crosses over to another year, the benchmark values will change.
              </li>
              <li id="Survey_View_ReopenSurvey_Text3">
                Report emails will not be sent again. To resend report emails, please re-trigger report emails from the customer’s account.
              </li>
            </ul>
          </WarningDescription>
        </WarningText>
      </WarningBlock>


      {store.isReopened ? <>

        <SetDateText id="Survey_View_ReopenSurvey_SetDateText">
          Set a new end date to reopen this survey.
        </SetDateText>

        <DateWrapper id="Survey_View_ReopenSurvey_LaunchTime_Wrapper">
          {store.survey?.survey_type_code !== SEND_OUT_SURVEY_TYPES.milestones && <DateTimeField
            id="Survey_View_ReopenSurvey_LaunchTime"
            label="Survey Launch Time"
            disabled={true}
            value={store.startDate}
            onChange={(e) => store.changeStartDate(e)}
          />}

          {store.survey?.survey_type_code === SEND_OUT_SURVEY_TYPES.milestones &&
            <TimeField id="Survey_View_ReopenSurvey_LaunchTime_TimeField" value={store.milestoneStart} disabled={true} changeValue={(value: any) => {
              store.changeMilestoneStart(value)
            }} />
          }
        </DateWrapper>

        <DateWrapper id="Survey_View_ReopenSurvey_EndTime_Wrapper">
          <DateTimeField
            id="Survey_View_ReopenSurvey_EndTime"
            label="Survey End Date"
            value={store.endDate}
            onChange={(e) => store.changeEndDate(e)}
          />
        </DateWrapper>

        <TimeZone id="Survey_View_ReopenSurvey_TimeZone">
          Local timezone: {store.timezoneMessage}
        </TimeZone>

        <Actions>
          <ButtonWrapper>
            <CustomButton id="Survey_View_ReopenSurvey_Save_Button" disabled={!store.datesChanged || store.endDate < dayjs()} variant="contained" onClick={() => store.saveReopenSurvey()}>
              Save
            </CustomButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <CustomButton id="Survey_View_ReopenSurvey_Cancel_Button" disabled={!store.datesChanged} variant="outlined" onClick={() => store.onReopenCancel()}>
              Cancel
            </CustomButton>
          </ButtonWrapper>
        </Actions>
      </> :
        <>

          <Actions>
            <ButtonWrapper>
              <CustomButton id="Survey_View_ReopenSurvey_Reopen_Button" variant="contained" onClick={() => store.setData("isReopened", true)}>
                Reopen
              </CustomButton>
            </ButtonWrapper>
          </Actions>
        </>}



    </Container>
  );
});

export default ReopenSurvey;

const Container = styled.div`
  margin: 20px;
  margin-top: 0;
`;


const WarningBlock = styled.div`
  width: 50%;
  padding: 15px;
  display: flex;
  border-radius: var(--borderRadius, 4px);
  background: var(--colorShadowInverted6, rgba(211, 47, 47, 0.12));
`;

const WarningText = styled.div`
  margin-left: 10px;
`;

const DateWrapper = styled.div`
  margin: 30px 0 10px 0;
`;

const TimeZone = styled.div`
  color: var(--colorShadowInverted7, rgba(0, 0, 0, 0.60));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.4px;
  margin-left: 10px;
`

const SetDateText = styled.div`
  margin: 20px 0;
  color: var(--colorNeutralForeground2, #707882);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
`;


const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

const WarningMainText = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.1px;
`;

const WarningDescription = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
`;

