import { observer } from "mobx-react";
import { TWorkspaceSetting } from "types/workspace";
import { FC, ReactElement, useEffect } from "react";
import { Dialog, DialogContent, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import topStore from "../store";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";

type EditSettingValueProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onClose: () => void;
  id_workspace: number;
  workspace_setting: TWorkspaceSetting;
};

const EditSettingValue: FC<EditSettingValueProps> = observer(
  (props): ReactElement => {
    useEffect(() => {
      if (props.openPanel) {
        let new_setting = Object.assign({}, props.workspace_setting);
        store.changeValue("workspace_setting", new_setting);
      }
    }, [props.openPanel]);

    useEffect(() => {
      store.changeValue("id_workspace", props.id_workspace);
    }, [props.id_workspace]);

    return (
      <StyledDialog open={props.openPanel} onClose={() => {
        props.onBtnCancelClick()
        store.clearStore()
      }}>
        <CloseBtnWrapper>
          <CloseButton id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_CLoseBtn`} onClick={() => {
            props.onBtnCancelClick()
            store.clearStore()
          }}>
            <ClearIcon />
          </CloseButton>
        </CloseBtnWrapper>
        <StyledDialogContent>
          <Header
            id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_Title`}  
          >
            Edit number of people inside the workspace “
            {topStore.selectedWorkspace?.name || ""}”
          </Header>
          {store.workspace_setting != null && (
            <>
              <TextFieldWrapper>
                <TextField
                id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_InputWorkspaceAdmins`}  
                  value={store.workspace_setting.admin_count}
                  variant="standard"
                  error={store.erroradmin_count != ""}
                  helperText={store.erroradmin_count}
                  label="Workspace admins"
                  name="admin_count"
                  placeholder="Workspace admins"
                  fullWidth
                  onChange={(e) => {
                    let value = e.target.value.replace(/^0+/, '')
                    value = value === "" ? "0" : value
                    let v = parseInt(value, 10);
                    if (!isNaN(v))
                      store.changeFieldWorkspace("admin_count", v);
                  }}
                />
              </TextFieldWrapper>
              <Group>
                <TextFieldWrapper>
                  <TextField
                   id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_InputTotalEntityLevelUsers`}  
                    value={store.workspace_setting.user_count_total}
                    variant="standard"
                    type="number"
                    name="user_count_total"
                    label="Total entity level users"
                    placeholder="Total entity level users"
                    fullWidth
                    disabled
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <TextField
                   id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_InputMaxEntityLevelUsers`}  
                  
                    value={store.workspace_setting.user_count}
                    variant="standard"
                    error={store.erroruser_count != ""}
                    helperText={store.erroruser_count}
                    name="user_count"
                    label="Max Entity Level Users"
                    placeholder="Max Entity Level Users"
                    fullWidth
                    onChange={(e) => {
                      let value = e.target.value.replace(/^0+/, '')
                      value = value === "" ? "0" : value
                      let v = parseInt(value, 10);
                      if (!isNaN(v))
                        store.changeFieldWorkspace("user_count", v);
                    }}
                  />
                </TextFieldWrapper>
              </Group>
              <Group>
                <TextFieldWrapper>
                  <TextField
                   id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_InputTotalEntityLevelEmployees`}  
                    value={store.workspace_setting.employee_count_total}
                    variant="standard"
                    name="employee_count_total"
                    label="Total entity level employees"
                    placeholder="Total entity level employees"
                    fullWidth
                    disabled
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <TextField
                    id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_InputMaxEntityLevelUsersEmployees`}  
                    value={store.workspace_setting.employee_count}
                    variant="standard"
                    error={store.erroremployee_count != ""}
                    helperText={store.erroremployee_count}
                    name="employee_count"
                    label="Max Entity Level Employees"
                    placeholder="Max Entity Level Employees"
                    fullWidth
                    onChange={(e) => {
                      let value = e.target.value.replace(/^0+/, '')
                      value = value === "" ? "0" : value
                      let v = parseInt(value, 10);
                      if (!isNaN(v))
                        store.changeFieldWorkspace("employee_count", v);
                    }}
                  />
                </TextFieldWrapper>
              </Group>
              <Group>
                <TextFieldWrapper>
                  <TextField
                  id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_InputTotalEntityLevelAactiveSeats`}  
                    value={store.workspace_setting.active_seat_count_total}
                    variant="standard"
                    type="number"
                    name="active_seat_count_total"
                    label="Total entity level active seats"
                    placeholder="Total entity level active seats"
                    fullWidth
                    disabled
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <TextField
                  id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_InputMaxLevelActiveSeats`}  
                    value={store.workspace_setting.active_seat_count}
                    variant="standard"
                    error={store.erroractive_seat_count != ""}
                    helperText={store.erroractive_seat_count}
                    name="active_seat_count"
                    label="Max Level Active Seats"
                    placeholder="Max Level Active Seats"
                    fullWidth
                    onChange={(e) => {
                      let value = e.target.value.replace(/^0+/, '')
                      value = value === "" ? "0" : value
                      let v = parseInt(value, 10);
                      if (!isNaN(v))
                        store.changeFieldWorkspace("active_seat_count", v);
                    }}
                  />
                </TextFieldWrapper>
              </Group>
            </>
          )}
          <ButtonWrapper>
            <SaveCustomButton
                id={`WorkspaceSettings_EntityTotalstatistics_PopUpEdit_SaveChanhgesBtn`}
              variant="contained"
              type="submit"
              disabled={store.erroractive_seat_count !== ""
                || store.erroradmin_count !== ""
                || store.erroremployee_count !== ""
                || store.erroruser_count !== ""
              }
              onClick={() => store.saveWorkspaceSettings()}
            >
              Save changes
            </SaveCustomButton>
            <CancelCustomButton
             id="WorkspaceSettings_EntityTotalstatistics_PopUpEdit_CanselsBtn"
              variant="outlined"
              onClick={() => {
                props.onBtnCancelClick()
                store.clearStore()
              }}
            >
              
              Cancel
            </CancelCustomButton>
          </ButtonWrapper>
        </StyledDialogContent>
      </StyledDialog >
    );
  }
);

export default EditSettingValue;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const TextFieldWrapper = styled.div`
  margin-top: 20px;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 592px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 40px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 48px 0px;
`;

const SaveCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const Group = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`;
