import { useNavigate } from "react-router-dom";
import { ReactElement } from "react";
import { prependBasePath } from "api/routes/basePath";

import Error from "./components/Error";

const Error500Screen = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <Error
      header="Oops!"
      subHeader="Sorry, we're having some technical problems."
      message="Please contact customer support team if you require assistance."
      onClickGoToPreviousPage={(): void => navigate(-1)}
      placeholderImageProps={{
        alt: "Oops",
        height: 232,
        width: 232,
        src: prependBasePath("/assets/kelly_cluckson.png"),
      }}
    />
  );
};

export default Error500Screen;
