import { observer } from "mobx-react";
import { EMAIL_REGEXP } from "constants/email";
import { FC, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, TextField } from "@mui/material";

import store from "./store";
import storeTable from "../../store";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "components/button/Button";

type AddActiveSeatsPopupProps = {};

const AddActiveSeatsPopup: FC<AddActiveSeatsPopupProps> = observer(() => {
  useEffect(() => {
    if (storeTable.isOpenAddSeatsPopup) {
      store.setData("entityId", storeTable.selectedEntity.id);
    }
    return () => {
      store.clearStore()
    };
  }, [storeTable.isOpenAddSeatsPopup]);


  return (
    <StyledDialog
      open={storeTable.isOpenAddSeatsPopup}
      onClose={() => storeTable.setData("isOpenAddSeatsPopup", false)}
    >
      <StyledDialogContent>
        <DialogTitle>
          Add active seats to the entity “{storeTable.selectedEntity?.name}”
        </DialogTitle>
        <Description>
          Enter their email address and hit “Enter” on your keyboard to add
          more.
        </Description>
        <StyledTextField
          variant="standard"
          placeholder="Email address"
          value={store.newActiveSeatsEmail}
          error={Boolean(store.errorMessage.length > 0)}
          helperText={store.errorMessage}
          onChange={(e) => store.setData("newActiveSeatsEmail", e.target.value)}
          onKeyDown={store.addNewEmail}
        />
        <AddedEmailsWrapper>
          <AddedEmailsWrapperTitle>
            Added email addresses ({store.newActiveSeatsEmails.length})
          </AddedEmailsWrapperTitle>
          <SelectedEmails>
            {store.newActiveSeatsEmails.map((email, index) => (
              <EmailWrapper key={index + email}>
                <Email>{email}</Email>
                <RemoveEmail
                  onClick={() =>
                    store.setData(
                      "newActiveSeatsEmails",
                      store.newActiveSeatsEmails.filter(
                        (_, addedEmailIndex) => addedEmailIndex !== index
                      )
                    )
                  }
                >
                  <CloseIcon />
                </RemoveEmail>
              </EmailWrapper>
            ))}
          </SelectedEmails>
        </AddedEmailsWrapper>
        <WarningContainer>
          <InfoIcon />
          <WarningText>
            Active seats will be added to the database immediately.{" "}
          </WarningText>
        </WarningContainer>
        <ButtonWrapper>
          <SaveCustomButton
            variant="contained"
            type="submit"
            onClick={() => {
              store.addActiveSeats(() => {
                storeTable.setData("isOpenAddSeatsPopup", false)
                storeTable.getEntityActiveSeats()
              });
            }}
            disabled={Boolean(store.newActiveSeatsEmails.length < 1)}
          >
            Save
          </SaveCustomButton>
          <CancelCustomButton
            variant="outlined"
            onClick={() => storeTable.setData("isOpenAddSeatsPopup", false)}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default AddActiveSeatsPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 600px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
  padding: 64px 64px 0px 64px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 26px 64px 48px 64px;
`;

const SaveCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-top: 16px;
  padding: 0px 64px;
`;

const WarningContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground2);
  padding: 22px 65px;
  margin-top: 40px;

  svg {
    color: var(--colorBrandForeground1);
    font-size: 22px;
  }
`;

const WarningText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin-left: 4px;
`;

const StyledTextField = styled(TextField)`
  width: calc(100% - 128px);
  margin: 40px 64px 16px 64px !important;
  height: 40px;

  &::placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--colorNeutralForeground5);
  }

  :before {
    border-bottom: 1px solid var(--colorPaletteBlueBackground1) !important;
  }
`;

const AddedEmailsWrapper = styled.div`
  width: calc(100% - 128px);
  margin: 20px 64px 0px 64px !important;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground4);
`;

const AddedEmailsWrapperTitle = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(-colorNeutralForeground1);
  padding: 12px 12px 8px 12px;
`;

const SelectedEmails = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 12px 12px 12px;
  max-height: 150px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 14px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: var(--colorShadowInverted2);
  }
`;

const EmailWrapper = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 100px;
  border: 1px solid var(--colorBrandForeground1);
  margin: 8px 8px 0px 0px;
  background-color: var(--colorNeutralBackground1);
`;

const Email = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorBrandForeground1);
`;

const RemoveEmail = styled(Button)`
  width: 20px;
  height: 20px;
  min-width: 0px !important;
  margin-left: 12px !important;

  svg {
    font-size: 16px;
  }
`;
