import { makeAutoObservable } from "mobx";
import { GetEntitiesByWorkSpaceSuccessResponseData, getEntitiesByWorkspace } from "api/sharp/useGetEntitiesByWorkspace";

class Store {

  openPanelCreateEntity = false
  openPanelAddEditEntity = false
  currentEntityId = 0

  constructor() {
    makeAutoObservable(this);
  }


  data: GetEntitiesByWorkSpaceSuccessResponseData = []

  setData(data: GetEntitiesByWorkSpaceSuccessResponseData) {
    this.data = data
  }

  changeHandler(field: string, value: number) {
    this[field] = value
  }

  changeOpenPanelCreateEntity(flag: boolean) {
    this.openPanelCreateEntity = flag
  }
  changeOpenPanelAddEditEntity(flag: boolean) {
    this.openPanelAddEditEntity = flag
  }

  loadEntities(id: number) {

    getEntitiesByWorkspace(id).then(json => {
      if (json != null && json.data != null)
        this.setData(json.data)
    })
  }

}

const store = new Store();
export default store;
