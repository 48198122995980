import http from "api/config/https";
import { TAddOrEditEntityType } from "types/entity";

export const addOrEditEntity = (data: TAddOrEditEntityType): Promise<any> => {
  const formData = new FormData();

  formData.append("super_admin", "true"); //TODO
  formData.append("id", data.id + "");
  formData.append("name", data.name);
  formData.append("workspace_id", data.workspace_id + "");
  if (data.logo_url) {
    formData.append("logo_url", data.logo_url);
  }
  formData.append("country_id", data.country_id + "");
  formData.append("industry_id", data.industry_id + "");
  formData.append(
    "sub_industry_id",
    !data.sub_industry_id ? "" : data.sub_industry_id + ""
  );
  formData.append("organization_size_id", data.organization_size_id + "");
  formData.append("sign_in_method", data.sign_in_method);
  if (data.file) {
    formData.append("Document.name", data.file ? data.file.name : "");
    formData.append("Document.file", data.file);
  }

  if (http.isAPIGO) {
    return http.postGO("/entity/AddOrUpdate", formData, {
      "Content-Type": "multipart/form-data",
    });
  } else {
    return http.post("/entity/AddOrUpdate", formData, {
      "Content-Type": "multipart/form-data",
    });
  }
};
