import { FC } from "react";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

type CustomBreadCrumbsProps = {
  workspaceName: string;
};

export const CustomBreadCrumbs: FC<CustomBreadCrumbsProps> = (props) => {
  const breadcrumbs = [
    <StyledLink id="BreadCrumbs_1" underline="hover" key="1" href="/workspaces">
      All workspaces
    </StyledLink>,

    <StyledTypography id="BreadCrumbs_2" key="2">{props.workspaceName}</StyledTypography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            fontSize="small"
            style={{ color: "var(--colorPaletteVioletBackground1)" }}
          />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

const StyledTypography = styled(Typography)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorPaletteVioletBackground1);
`;

const StyledLink = styled(Link)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorBrandForeground1);
`;
