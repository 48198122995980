import { FC } from "react";
import { TemplatesScreen } from "features/templates";
import { SurveyProjects } from "features/survey-project-list";

type SurveyProjectsPageProps = {};

const SurveyProjectsPage: FC<SurveyProjectsPageProps> = () => {
  return <SurveyProjects />;
};

export default SurveyProjectsPage;
