import { createUrl } from "helpers/url";
import http from "../config/https";

const useGetEntityNumberPeoples = (entityId: number) => {
  const query: { idEntity: string } = { idEntity: entityId.toString() };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "subscription/entity_settings/GetOneByIdEntity",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/entity_settings/GetOneByIdEntity",
      query: query,
    });
    return http.get(url);
  }
};

export default useGetEntityNumberPeoples;
