import { useNavigate } from "react-router-dom";
import { ReactElement } from "react";

import Error from "./components/Error";
type Error422ScreenProps = {
  message? : string;
}

const Error422Screen = (props: Error422ScreenProps): ReactElement => {
  const navigate = useNavigate();

  return (
    <Error
      header="Oops!"
      subHeader="Update is not proccesed correctly"
      message={props.message}
      onClickGoToPreviousPage={(): void => navigate(-1)}
      placeholderImageProps={{
        alt: "Oops",
        height: 232,
        width: 232,
        src: "/assets/kelly_cluckson.png",
      }}
    />
  );
};

export default Error422Screen;
