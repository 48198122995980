import { FC } from "react";
import { TWorkspace } from "types/workspace";

import styled from "styled-components";
import CustomImage from "components/Image/Image";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import erLogo from "assets/images/er-logo-img.svg";

type WorkspaceCardProps = {
  workspaceInfo: TWorkspace | undefined;
  workspaceAdminsData: any[];
  handleVisibleEditWorkspacePopup: (state: boolean) => void;
};

const WorkspaceCard: FC<WorkspaceCardProps> = (props) => {
  return (
    <Container>
      <LogoWrapper>
        <CustomImage
          style={{ maxWidth: 80, maxHeight: 80 }}
          src={props.workspaceInfo.logo_url ? props.workspaceInfo.logo_url : erLogo}
          alt={"company-logo"}
        />
      </LogoWrapper>
      <InfoWrapper>
        <Title id={`WorkspaceCard_Title_${props.workspaceInfo.id}`}>
          {props.workspaceInfo?.name || "Workspace name"}
        </Title>
        <LoginType>
          <LoginTypeLabel id={`WorkspaceCard_Subtitle_${props.workspaceInfo.id}`}>
            {props.workspaceInfo?.sign_in_method === 0
              ? "Sign in via email and password"
              : "Sign in via SSO" || "Workspace signInMethod"}
          </LoginTypeLabel>
        </LoginType>
        <AdminCounter id={`WorkspaceCard_WorkspaceAdmin_${props.workspaceInfo.id}`}>
          Workspace admin: {props.workspaceAdminsData?.length}
        </AdminCounter>
      </InfoWrapper>
      <EditButton
        id={`WorkspaceCard_EditBtn_${props.workspaceInfo.id}`}
        onClick={() => props.handleVisibleEditWorkspacePopup(true)}
      >
        <ModeEditSharpIcon
          sx={{ fontSize: 16 }}
          style={{ color: "var(--colorBrandForeground1)" }}
        />
      </EditButton>
    </Container>
  );
};

export default WorkspaceCard;

const Container = styled.div`
  width: 449px;
  height: 148px;
  padding: 24px;
  display: flex;
  align-items: center;
  background-color: var(--colorNeutralBackground1);
  border-radius: 10px;
  border: 1px solid var(--colorNeutralForegroundInverted2);
`;

const LogoWrapper = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--graySelectBoxBorder);
  border-radius: 8px;
`;

const InfoWrapper = styled.div`
  margin-left: 24px;
`;

const Title = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const LoginType = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 4px 0 12px 0;
`;

const LoginTypeLabel = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0 4px 0 0;
`;

const AdminCounter = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const EditButton = styled.button`
  position: relative;
  top: -35px;
  right: -13px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--colorPalleteLightBlue);
  transition: background-color 0.3s;

  :hover {
    background-color: var(--colorNeutralBackground3);
  }
`;
