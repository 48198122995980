import { TSurvey, TProjectStatus, TSurveyType } from "types/survey";
import { makeAutoObservable, reaction } from "mobx";
import getAllSurveys from "api/useGetAllSurveys";
import MainStore from "MainStore";
import { GridSortModel, GridFilterModel } from "@mui/x-data-grid";
import getProjectStatuses from "api/useGetProjectStatuses";
import getSurveyTypes from "api/useGetSurveyTypes";
import getExcelWithQuestionCodes from "api/useGetExcelWithQuestionCodes";
import getExcelWithSurveyDetails from "api/useGetExcelWithSurveyDetails";

class Store {
  surveyList: TSurvey[] = [];
  projectStatuses: TProjectStatus[] = [];
  surveyTypes: TSurveyType[] = [];
  surveysTotalCount: number = 0;
  surveysPageSize: number = 10;
  surveysPage: number = 0;
  surveysSortField: string = "";
  surveysSortType: "asc" | "desc" | null = null;
  surveysSearchValue: string = "";
  surveysFilterField: string = "";
  surveysFilterOperator: string = "";
  surveysFilterValue: string = "";

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.surveysSearchValue,
      (newSearchActiveSeatsValue) => {
        this.getAllSurveys();
      },
      { delay: 1000 }
    );
  }

  clearStore = () => {};

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  doLoad = () => {
    this.getProjectStatuses();
    this.getSurveyTypes();
    this.getAllSurveys();
  };

  changeSortSurveys = (sort: GridSortModel) => {
    if (sort.length === 0) {
      this.surveysSortField = "";
      this.surveysSortType = null;
    } else {
      this.surveysSortField = sort[0].field;
      this.surveysSortType = sort[0].sort;
    }
    this.getAllSurveys();
  };

  changeFilterSurveys = (filter: GridFilterModel) => {
    if (filter.items.length === 0) {
      this.setData("surveysFilterField", "");
      this.setData("surveysFilterOperator", "");
      this.setData("surveysFilterValue", "");
    } else {
      this.setData("surveysFilterField", filter.items[0].field);
      this.setData("surveysFilterOperator", filter.items[0].operator);
      this.setData("surveysFilterValue", filter.items[0].value);
    }
    this.getAllSurveys();
  };

  changePaginationSurveys = (page: number, pageSize: number) => {
    this.surveysPage = page;
    this.surveysPageSize = pageSize;
    this.getAllSurveys();
  };

  getAllSurveys = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getAllSurveys(
        this.surveysSearchValue,
        this.surveysPage,
        this.surveysPageSize,
        this.surveysSortField,
        this.surveysSortType,
        this.surveysFilterField,
        this.surveysFilterOperator,
        this.surveysFilterValue
      );
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("surveyList", response.data.surveys);
        this.setData("surveysTotalCount", response.data.total);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getProjectStatuses = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getProjectStatuses();
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("projectStatuses", response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  downloadQuestionCodesExcel = async () => {
    try {
      MainStore.changeLoader(true);
      const data = await getExcelWithQuestionCodes();
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "Question codes.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  downloadSurveyDetailsExcel = async () => {
    try {
      MainStore.changeLoader(true);
      const data = await getExcelWithSurveyDetails();
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "All survey details.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getSurveyTypes = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getSurveyTypes();
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("surveyTypes", response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
