import { FC } from "react";
import { AdminsScreen } from "../../features/admins";

type AdminsPageProps = {};

const AdminsPage: FC<AdminsPageProps> = () => {
  return <AdminsScreen />;
};

export default AdminsPage;
