import { Input } from "@mui/material";
import { TWorkspace } from "types/workspace";
import { useEditWoskspace } from "api/useEditWoskspace";
import { useCheckCorrectWorspaceName } from "api/useCheckCorrectWorspaceName";
import React, { FC, ReactElement, useEffect, useState } from "react";

import Button from "components/button/Button";
import styled from "styled-components";
import CustomModal from "components/modal/Modal";
import useDebounce from "utils/useDebounse";
import FileInput from "components/uploadImage";

type ModalChangePasswordProps = {
  open: boolean;
  hide: (state: boolean) => void;
  workspaceInfo: TWorkspace | undefined;
};

const ModalEditWorkspace: FC<ModalChangePasswordProps> = (props): ReactElement => {
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [errorFile, setErrorFile] = useState<string | null>(null);
  const [file, setFile] = useState(null);
  const [isRemoveFile, setIsRemoveFile] = useState(false);

  const debouncedNameValue = useDebounce(name, 500);

  const { mutateAsync, isLoading } = useCheckCorrectWorspaceName();

  const { mutateAsync: mutateAsyncEditWorkspace, isLoading: isLoadingEditWorkspace } =
    useEditWoskspace();

  useEffect(() => {
    if (props.workspaceInfo && props.workspaceInfo.name) setName(props.workspaceInfo?.name);
  }, [props.workspaceInfo]);

  const checkName = async () => {
    try {
      if (props.workspaceInfo.name === debouncedNameValue || props.workspaceInfo.name === name) {
        setError(null);
        return;
      }
      // if (!name) {
      //   setError("Name is required");
      //   return;
      // }
      if (props.open && debouncedNameValue && debouncedNameValue.length > 0) {
        const response = await mutateAsync({ name: debouncedNameValue });
        if (!response.is_exists) setError(null);
        else setError("There is an existing workspace with the same name.");
      } else return;
    } catch (err) {
      setError("There is an existing workspace with the same name.");
    }
  };

  useEffect(() => {
    checkName();
  }, [debouncedNameValue]);

  const submitForm = () => {
    if (props.workspaceInfo && props.workspaceInfo.id) {
      if (name && name.trim()) {
        mutateAsyncEditWorkspace({
          name, logo: file, id: props.workspaceInfo?.id, logo_url: isRemoveFile ? "" : props.workspaceInfo?.logo_url,
        });
        props.hide(false);
      } else {
        setError("Name is required");
      }
    }
  };

  const changeFile = (value: any) => {
    if (value == null) {
      if (errorFile !== "") {
        setErrorFile("");
      }
      setFile(null);
      setIsRemoveFile(true);
      return;
    }
    if (value.size > 1000000) {
      setErrorFile("Image not uploaded as it is larger than 1MB.");
    } else {
      setFile(value);
      setIsRemoveFile(false);
      if (errorFile !== "") {
        setErrorFile("");
      }
    }
  };

  return (
    <CustomModal open={props.open} hide={props.hide} width="468">
      <StyledModalTitle id="WorkspaceCard_EditPopUp_Title">Edit Workspace</StyledModalTitle>
      <StyledModalContent aria-label={"Edit workspace form"}>
        <TitleRow id="WorkspaceCard_EditPopUp_Title_WorkspaceName">Workspace name</TitleRow>
        <StyledInput
          id="WorkspaceCard_EditPopUp_Input"
          name="name"
          placeholder=""
          type="text"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.value) {
              setError("Name is required");
            }
            setName(e.target.value);
          }}
        />
        {error && <Error id="WorkspaceCard_EditPopUp_Input_Error">{error}</Error>}

        <FileInput
          Id="WorkspaceCard_EditPopUp_FileInput"
          onFileSelect={(file) => changeFile(file)}
          label={"Company logo (Optional)"}
          secondaryLabel={"Only .jpg and .png formats with max file size of 1MB accepted"}
          errorText={errorFile}
          logoURL={isRemoveFile ? null : props.workspaceInfo?.logo_url}
        />

        <TitleRow id="WorkspaceCard_EditPopUp_Question">How will workspace users sign in?</TitleRow>
        <Text>
          {props.workspaceInfo?.sign_in_method === 0 ? "Via email and password" : "SSO login"}
        </Text>
        <ButtonWrapper>
          <StyledNextButton
            id="WorkspaceCard_EditPopUp_SaveChangesBtn"
            variant="outlined"
            onClick={submitForm}
            disabled={Boolean(error) || isLoading || isLoadingEditWorkspace}
          >
            Save changes
          </StyledNextButton>
          <StyledCancelButton
            id="WorkspaceCard_EditPopUp_CancelBtn"
            variant="outlined"
            onClick={() => {
              setName(props.workspaceInfo?.name);
              setError(null);
              props.hide(false);
            }}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalEditWorkspace;

const StyledModalTitle = styled.span`
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const StyledModalContent = styled.div``;

const StyledInput = styled(Input)`
  width: 100%;

  label {
    margin-top: 24px;
  }

  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--colorNeutralForeground5);
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px !important;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border: 1px solid var(--colorNeutralForeground1) !important;
`;

const StyledNextButton = styled(Button)`
  width: 100%;
  max-width: 160px;
  height: 36px;
`;

const Error = styled.p`
  width: 100%;
  margin: 0;
  color: var(--colorPaletteRedForeground2);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const TitleRow = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 32px 0 6px 0;
  color: var(--colorNeutralForeground2);
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 10px 0 0 0;
  color: var(--colorNeutralForeground1);
`;
