import { observer } from "mobx-react";

import * as React from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import store from "./store";
import Button from "components/button/Button";
import styled from "styled-components";
import FeatureTab from "./tabs/feature/FeatureTab";
import PersonIcon from "@mui/icons-material/Person";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ActiveSeatsTable from "./tabs/active-seats/ActiveSeatsTable";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import NumberPeopleTable from "./tabs/number-people/NumberPeopleTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`WorkspaceSettings_GridContainer_TabGridContainer_${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <TabPanelWrapper>{children}</TabPanelWrapper>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `WorkspaceSettings_GridContainer_Tab_${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TableMenu = observer(() => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    store.changeValue("tabId", newValue);
    store.changeValue("selectedData", []);
  };

  return (
    <TabsWrapper id="WorkspaceSettings_GridContainer">
      <TabHeaderWrapper>
        <StyledTabs
          value={store.tabId}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab
            label={"Number of people"}
            icon={<ContentPasteIcon />}
            iconPosition="start"
            {...a11yProps(0)}
          />
          <StyledTab
            label={"Active seats"}
            icon={<PersonIcon />}
            iconPosition="start"
            {...a11yProps(1)}
          />
          <StyledTab
            label={"Features"}
            icon={<StarBorderIcon />}
            iconPosition="start"
            {...a11yProps(2)}
          />
        </StyledTabs>
        {store.isShowSaveFeatureSettingsButton && store.tabId === 2 && (
          <SaveFeatureSettingsButton
            variant="contained"
            onClick={() => store.setData("isOpenSaveFeatiresPopup", true)}
          >
            Save changes
          </SaveFeatureSettingsButton>
        )}
      </TabHeaderWrapper>
      <CustomTabPanel value={store.tabId} index={0}>
        <NumberPeopleTable  list={store.numberPeoplesList} />
      </CustomTabPanel>
      <CustomTabPanel value={store.tabId} index={1}>
        <ActiveSeatsTable list={store.activeSeatsList} />
      </CustomTabPanel>
      <CustomTabPanel value={store.tabId} index={2}>
        <FeatureTab />
      </CustomTabPanel>
    </TabsWrapper>
  );
});

export default TableMenu;

const TabsWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const TabHeaderWrapper = styled.div`
  border-bottom: 1px;
  border-color: divider;
  margin-top: 10px;
  position: relative;
`;

const TabPanelWrapper = styled.div`
  margin-top: 20px;
`;

const StyledTab = styled(Tab)`
  min-height: 0px !important;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid var(--colorNeutralForeground7);
`;

const SaveFeatureSettingsButton = styled(Button)`
  position: absolute !important;
  right: 0px !important;
  bottom: 12px;
`;
