import { FC } from "react";

import styled from "styled-components";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";

type SearchInputProps = {
  Id?: string;
  title: string;
  closeSearchInput: () => void;
  search_value: string;
  search_by: string | undefined;
  sortFunc: (newParams: { [key: string]: string | undefined }) => void;
};

const SearchInput: FC<SearchInputProps> = (props) => {
  const close = () => {
    props.closeSearchInput();
    props.sortFunc({ search_value: "", search_by: undefined });
  };
  return (
    <SearchInputWrapper>
      <StyledSearchSharpIcon id={props.Id ? `${props.Id}_SearchInputBtn` : null} />
      <StyledInput
        id={props.Id ? `${props.Id}_SearchInput` : null}
        value={props.search_value}
        onChange={(e) => props.sortFunc({ search_value: e.target.value })}
        placeholder={`Enter ${props.title}`}
      />

      <ButtonIcon id={props.Id ? `${props.Id}_SearchInput_Canselbtn` : null} onClick={close}>
        <StyledCancelSharpIcon />
      </ButtonIcon>
    </SearchInputWrapper>
  );
};

export default SearchInput;

const SearchInputWrapper = styled.div`
  width: 100%;
  max-width: 266px;
  height: 27px;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid var(--colorPaletteBlueBackground1);
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);

  :focus {
    outline: none;
  }

  ::placeholder {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--colorNeutralForeground2);
  }
`;

const ButtonIcon = styled.button`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  padding: 0px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearchSharpIcon = styled(SearchSharpIcon)`
  font-size: 18px;
  margin-left: 7px;
  color: var(--lightGrayColor);
`;

const StyledCancelSharpIcon = styled(CancelSharpIcon)`
  font-size: 18px;
  color: var(--colorBrandForeground1);
  margin-right: 7px;
`;
