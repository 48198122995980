import { API_URL } from "constants/config";

const getRawSurveyResponses = (surveyId: number) => {
  var url = API_URL + `/sm_question/GetRawSurveyResponses?survey_id=${surveyId}`;
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.blob();
  });
};

export default getRawSurveyResponses;
