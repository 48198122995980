import { IconXlsx } from "./IconXlsx";
import { FC } from "react";
import styled from "styled-components";
import Button from "components/button/Button";
import store from './store'
import { observer } from "mobx-react"
import WarningIcon from "features/survey-project-view/icons/WarningIcon";

type ActionRequiredProps = {
};

const ActionRequired: FC<ActionRequiredProps> = observer((props) => {

  return (
    <ActionRequiredWrapper>
      <Content>
        <Header>Employee List Update: Action Required</Header>
        <FileWrapper>
          <IconXlsx />
          <p>{store.file?.name || "File not found"}</p>
        </FileWrapper>

        <WarningBlock>
          <WarningIcon />
          <WarningText>
            <WarningMainText>
              Are you sure you want to change the question codes?
            </WarningMainText>
            <WarningDescription>
              Changing the question codes will refresh benchmarks based on the new question_codes imported for this survey. Please confirm before proceeding.
            </WarningDescription>
          </WarningText>
        </WarningBlock>

      </Content>
      <ButtonWrapper>
        <ConfirmButton
          variant="contained"
          onClick={() => store.proceedParseExcel()}
        >
          Proceed
        </ConfirmButton>
      </ButtonWrapper>
    </ActionRequiredWrapper>
  );
});

export default ActionRequired;

const ActionRequiredWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
`;

const WarningBlock = styled.div`
  padding: 15px;
  display: flex;
  border-radius: var(--borderRadius, 4px);
  background: var(--colorShadowInverted6, rgba(211, 47, 47, 0.12));
`;

const WarningText = styled.div`
  margin-left: 10px;
`;

const WarningMainText = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.1px;
`;

const WarningDescription = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1, #3E4450);
  margin: 0px 0px 20px 0px;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1, #3E4450);
  }

  svg {
    margin-right: 10px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 660px;
`;

const ConfirmButton = styled(Button)`
  width: 100%;
  height: 34px;
  max-width: 180px !important;
  margin-top: 40px !important;
`;
