import http from "./config/https";

export const createWorkspaceEntityAdmin = (workspaceAdminData: {
  email: string;
  workspaceId: number;
  entityId?: number;
}) => {
  if (http.isAPIGO) {
    return http.postGO(
      `/permission/accounts_roles/add_workspace_admin`,
      {
        email: workspaceAdminData.email,
        workspace_id: workspaceAdminData.workspaceId,
        entity_id: workspaceAdminData.entityId
      },
    ) as any;
  } else {
    return http.post(
      `/account_role/AddWorkspaceAdmin`,
      {
        email: workspaceAdminData.email,
        workspace_id: workspaceAdminData.workspaceId,
        entity_id: workspaceAdminData.entityId
      },
    ) as any;
  }
};
