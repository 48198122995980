import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Menu, MenuItem } from "@mui/material";
import Link from "@mui/material/Link";

import store from "./store";
import { TWorkspaceAdmin } from "../../types/workspace";
import Button from "components/button/Button";
import CustomTable from "components/table/CustomTable";
import ModalRevokeAdmin from "./ModalRevokeAdmin";
import { SelectIcon } from "./SelectIcon";
import useDebounce from "../../utils/useDebounse";

type WorkspacesTableProps = {
  id?: string;
  list: TWorkspaceAdmin[];
  sort_by: string | undefined;
  refetch: () => void;
  sort_type: string | undefined;
  handleParams: (newParams: { [key: string]: string | undefined }) => void;
  search_by: string | undefined;
  search_value: string;
  sortModel: { field: string | null; sort: string | null };
  changeSort: (field: string, sort: string) => void;
};

export const AdminsTable: FC<WorkspacesTableProps> = observer((props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenRevokeAdminModal, setIsOpenRevokeAdminModal] =
    useState<boolean>(false);
  const [selectedAdmin, setSelectedAdmin] = useState<TWorkspaceAdmin | null>(
    null,
  );

  const debouncedSearchValue = useDebounce(props.search_value, 500);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleVisibleRevokeAdminPopup = (state: boolean) => {
    setIsOpenRevokeAdminModal(state);
    setAnchorEl(null);
  };

  const filteredList = props.list.filter((admin) => {
    if (!props.search_by || !debouncedSearchValue) {
      return true;
    } else {
      const searchColumnValue = String(admin[props.search_by]).toLowerCase();
      return searchColumnValue.includes(debouncedSearchValue.toLowerCase());
    }
  });

  const renderLastSignedIn = (lastSignedIn: string) => {
    const date1 = new Date(lastSignedIn);
    const date2 = new Date(Date.now());
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays > 2) return `About ${diffDays} days ago`;
    else
      return (
        <TableText>
          {new Date(lastSignedIn).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })}
          ,<br />
          {new Date(lastSignedIn).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
          })}
          ,
        </TableText>
      );
  };

  const tableData = {
    headers: [
      {
        title: "Full name / Email",
        value: "email",
        filter: true,
        sort: true,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <div>
            <UserName
            id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}}_Text_1_${row.id}`}
            >{row.userName ? row.userName : "-"}</UserName>
            <TableText
             id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_Text_2_${row.id}`}
             >{row.email}</TableText>
          </div>
        ),
      },
      {
        title: "User ID",
        value: "userID",
        filter: true,
        hidden: true,
        sort: true,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <TableText
          ><span
          id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_Text_${row.id}`}
          >{row.userID}</span></TableText>
        ),
      },
      {
        title: "Entity ID",
        value: "entityID",
        hidden: true,
        filter: true,
        sort: true,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <TableText
          ><span
          id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_Text_${row.id}`}
          >{row.entityID}</span></TableText>
        ),
      },
      {
        title: "Account ID",
        value: "accountID",
        filter: true,
        sort: true,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <TableText
          id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_Text_${row.id}`}
          >{row.accountID}</TableText>
        ),
      },
      {
        title: "Workspace/Entity",
        value: "workspace",
        filter: true,
        sort: true,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <StyledLink
          id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_Link_${row.id}`}
          href={`/workspaces/${row.workspaceID}`}>
            <WorkspaceName
            id={`${props.id}_Grid_Table_${index}_${indexRow}_Text_${row.id}`}
            >{row.workspace}</WorkspaceName>
          </StyledLink>
        ),
      },
      {
        title: "User role",
        value: "userRole",
        filter: true,
        sort: true,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <TableText
          id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_Text_${row.id}`}
          >
            {row.userRole
              ? row.userRole[0]?.toUpperCase() + row.userRole.slice(1)
              : "---"}
          </TableText>
        ),
      },
      {
        title: "Sign in count",
        value: "sign_in_count",
        filter: true,
        sort: false,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <TableText 
          id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_Text_${row.id}`}
          >{row.sign_in_count}</TableText>
        ),
      },
      {
        title: "Last signed in",
        value: "last_signed_in",
        hidden: true,
        filter: true,
        sort: false,
        renderRow: (row: TWorkspaceAdmin) =>
          renderLastSignedIn(row.last_signed_in),
      },
      {
        title: "Created at",
        value: "created_at",
        filter: true,
        sort: false,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <TableText id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_Text_${row.id}`}>
            {new Date(row.created_at).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })}
            ,<br />
            {new Date(row.created_at).toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
            })}
            ,
          </TableText>
        ),
      },
      {
        title: "",
        value: "revoke-btn",
        filter: false,
        sort: false,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <>
            <RevokeAdminBtn
              id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_RevokeAdminBtn_${row.id}`}
              onClick={(e) => {
                setSelectedAdmin(row);
                handleClick(e);
              }}
            >
              <SelectIcon />
            </RevokeAdminBtn>
          </>
        ),
      },
      {
        title: "",
        value: "button_link",
        filter: false,
        sort: false,
        renderRow: (row: TWorkspaceAdmin, index, indexRow) => (
          <ManageButton
			      id={props.id && `${props.id}_Grid_Table_${index}_${indexRow}_SignInBtn_${row.id}`}
            onClick={async () => {
              if (row.isImpersonated) 
                await store.impersonateLogout(row.accountID);
              else 
                await store.impersonateLogin(row.accountID);
              props.refetch();
            }}
            variant={row.isImpersonated? "contained" : "outlined"}
          >
            {row.isImpersonated ? "Sign out" : "Sign in"}
          </ManageButton>
        ),
      },
    ],
  };

  return (
    <>
      <CustomTable
          list={filteredList}
          data={tableData}
          sort_by={props.sort_by}
          sort_type={props.sort_type}
		      id={props.id && `${props.id}_Grid`}
          search={props.handleParams}
          sort={props.changeSort}
          sortModel={props.sortModel}
          search_by={props.search_by}
          search_value={props.search_value}
          filed_key={"userID"}
      />
      <ModalRevokeAdmin
          open={isOpenRevokeAdminModal}
          hide={handleVisibleRevokeAdminPopup}
		      id={props.id && `${props.id}_Grid_PopUpRevokeAdmin`}
          onSave={props.refetch}
          admin={selectedAdmin}
      />
      <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
      >
        <MenuItem   id={props.id && `${props.id}_Grid_RevokeAccess`}  onClick={() => handleVisibleRevokeAdminPopup(true)}>
          Revoke access
        </MenuItem>
      </Menu>
    </>
  );
});

const TableText = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const ManageButton = styled(Button)`
  white-space: nowrap;
`;

const UserName = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 4px 0;
`;

const WorkspaceName = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  color: var(--colorBrandForeground1);
`;

const RevokeAdminBtn = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: var(--colorBrandForeground1);

  &:hover {
    text-decoration: underline;
  }
`;
