import Box from "@mui/material/Box";

interface Props {
  src: string;
  alt: string;
  style?: any;
  width?: string;
  height?: string;
}

const CustomImage = ({ src, alt, style }: Props) => {
  return <Box component={"img"} src={src} alt={alt} style={style}/>;
};

export default CustomImage;
