import { FC } from "react";
import { TMember } from "types/member";

import styled from "styled-components";
import Button from "components/button/Button";
import CustomTable from "components/table/CustomTable";

type WorkspacesTableProps = {
  id?: string;
  list: TMember[];
  sort_by: string | undefined;
  sort_type: string | undefined;
  handleParams: (newParams: { [key: string]: string | undefined }) => void;
  search_by: string | undefined;
  search_value: string;
  handleVisibleRemoveMemberPopup: (state: boolean) => void;
  handleSelectedMember: (member: TMember) => void;
  sortModel: { field: string | null; sort: string | null };
  changeSort: (field: string, sort: string) => void;
};

export const ERMembersTable: FC<WorkspacesTableProps> = (props) => {
  const tableData = {
    headers: [
      {
        title: "Email address",
        value: "user_email",
        filter: false,
        sort: false,
        renderRow: (row: TMember, index, indexRow) => <TableText 
        id={props.id && `${props.id}_Table_${index}_${indexRow}_Text_${row.id}`}>{row.user_email}</TableText>,
      },
      {
        title: "Preferred name",
        value: "user_preferred_name",
        filter: false,
        sort: false,
        renderRow: (row: TMember, index, indexRow) => (
          <TableText id={props.id && `${props.id}_Table_${index}_${indexRow}_Text_${row.id}`}>{row.user_preferred_name}</TableText>
        ),
      },

      {
        title: "",
        value: "button_link",
        filter: false,
        sort: false,
        renderRow: (row: TMember, index, indexRow) => (
          <ButtonWrapper>
            <RemoveButton
            id={props.id && `${props.id}_Table_${index}_${indexRow}_RemoveBtn_${row.id}`}
              variant="outlined"
              onClick={() => {
                props.handleSelectedMember(row);
                props.handleVisibleRemoveMemberPopup(true);
              }}
            >
              Remove member
            </RemoveButton>
          </ButtonWrapper>
        ),
      },
    ],
  };

  return (
    <CustomTable
      id={props.id}
      list={props.list}
      data={tableData}
      sort_by={props.sort_by}
      sort_type={props.sort_type}
      search={props.handleParams}
      sort={props.changeSort}
      search_by={props.search_by}
      search_value={props.search_value}
    />
  );
};

const TableText = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const RemoveButton = styled(Button)``;
