import http from "./config/https";


export const useEditERTemplate = ( dataTemplate: {
  id: number,
  name: string,
  frequency_name: string,
  type_questionary_name: string,
  is_default_template: boolean,
  description: string,
}) => {
  return http.post(
    "/templ_template_questionary/edit_upload_survey_template",
    {
      id: dataTemplate.id,
      Name: dataTemplate.name,
      Frequency: dataTemplate.frequency_name,
      Type: dataTemplate.type_questionary_name,
      MakeDefault: dataTemplate.is_default_template,
      Summary: dataTemplate.description
    }
  );
};
