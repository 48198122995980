import { FC } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import store from "./store";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "components/button/Button";
import ManageTemplateTable from "./ManageTemplateTable";

type ManageTemplatePopupProps = {};

const ManageTemplatePopup: FC<ManageTemplatePopupProps> = observer(() => {
  return (
    <StyledDialog
      id="ManageTemplatePopup_Dialog"
      open={store.isOpenManageTemplatePopup}
      onClose={() => store.setData("isOpenManageTemplatePopup", false)}
    >
      <StyledDialogContent id="ManageTemplatePopup_DialogContent">
        <DialogTitle id="ManageTemplatePopup_DialogTitle">Managing question templates access</DialogTitle>
        <Description id="ManageTemplatePopup_Description">
          Choose workspaces that will have access to this template
        </Description>
        <Box id="ManageTemplatePopup_SearchBox">
          <StyledSearchInput
            id="ManageTemplatePopup_SearchInput"
            size="small"
            placeholder="Search workspace/entity name or ID"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" id="ManageTemplatePopup_InputAdornment">
                  <IconButton onClick={() => {}} id="ManageTemplatePopup_SearchIconButton">
                    <SearchIcon id="ManageTemplatePopup_SearchIcon" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={store.searchedTemplateValue}
            onChange={(e) => store.setData("searchedTemplateValue", e.target.value)}
          />
        </Box>

        <ManageTemplateTable id="ManageTemplatePopup_ManageTable" />

        <ButtonWrapper id="ManageTemplatePopup_ButtonWrapper">
          <SaveCustomButton id="ManageTemplatePopup_SaveButton" variant="contained" type="submit">
            Save changes
          </SaveCustomButton>
          <CancelCustomButton
            id="ManageTemplatePopup_CancelButton"
            variant="outlined"
            onClick={() => store.setData("isOpenManageTemplatePopup", false)}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default ManageTemplatePopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 1070px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 64px 64px 0px 64px !important;
    background-color: var(--colorNeutralForeground8);
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 60px 0px;
`;

const SaveCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin: 12px 0px 20px 0px;
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 320px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  margin: 0px !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 8px 11px !important;
    border-radius: 2px !important;
    font-family: Roboto;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;
