import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import TemplatesList from "./TemplatesList";
import DeleteTemplatePopup from "./DeleteTemplatePopup";
import ManageTemplatePopup from "./ManageTemplatePopup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UploadTemplatePopup from "./UploadTemplatePopup";
import FilterListIcon from "@mui/icons-material/FilterList";
import EditTemplatePopup from "./EditTemplatePopup";

type TemplatesScreenProps = {};

const TemplatesScreen: FC<TemplatesScreenProps> = observer(() => {
  useEffect(() => {
    store.doLoad()
  }, [])

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = "/assets/empty_tempate.xlsx";
    link.download = 'empty_tempate.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container id="TemplatesScreen_Container">
      <Title id="TemplatesScreen_Title">Engage Rocket templates</Title>
      <SettingsWrapper id="TemplatesScreen_SettingsWrapper">
        <Box id="TemplatesScreen_Box">
          <StyledAutocomplete
            id="TemplatesScreen_Autocomplete"
            options={["All templates", "Surveys", "Millestones", "Reviews"]}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                id="TemplatesScreen_AutocompleteTextField"
                placeholder="All templates"
                InputProps={{
                  ...params.InputProps,
                  id: "TemplatesScreen_InputProps",
                  startAdornment: (
                    <InputAdornment position="start" id="TemplatesScreen_InputAdornment">
                      <StyledFilterListIcon id="TemplatesScreen_FilterListIcon" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            value={store.templatesFilter}
            onChange={(_, value: string) => {
              store.setData("templatesFilter", value);
            }}
            popupIcon={<KeyboardArrowDownIcon id="TemplatesScreen_PopupIcon" />}
            isOptionEqualToValue={(option, value) => option === value}
          />
        </Box>
        <ButtonWrapper id="TemplatesScreen_ButtonWrapper">
          <DownloadButton onClick={handleDownload} variant="outlined" id="TemplatesScreen_DownloadButton">
            Download empty template
          </DownloadButton>
          <UploadButton
            variant="contained"
            onClick={() => store.setData("isOpenUploadTemplatePopup", true)}
            id="TemplatesScreen_UploadButton"
          >
            + Upload new template
          </UploadButton>
        </ButtonWrapper>
      </SettingsWrapper>
      <TemplatesList />

      <DeleteTemplatePopup />
      <ManageTemplatePopup />
      <UploadTemplatePopup />
      <EditTemplatePopup />
    </Container>
  );
});


export default TemplatesScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 80px 48px 48px 48px;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-top: 1px solid var(--colorPaletteBlueBackground1);
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadButton = styled(CustomButton)`
  height: 34px !important;
  margin-right: 23px !important;
  font-weight: 400 !important;
`;

const UploadButton = styled(CustomButton)`
  height: 34px !important;
  font-weight: 400 !important;
`;

const StyledAutocomplete = styled(Autocomplete)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;

  input {
    width: 180px !important;
    border-radius: 2px !important;
    padding: 0px !important;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;

const StyledTextField = styled(TextField)`
  ::placeholder {
    color: var(--colorNeutralForeground5);
    font-weight: 400 !important;
    font-family: Roboto !important;
  }
`;

const StyledFilterListIcon = styled(FilterListIcon)`
  color: var(--colorBrandForeground1) !important;
  width: 20px !important;
  margin-right: 6px;
`;
