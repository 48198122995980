import { Input } from "@mui/material";
import { TNewWorkspace } from "types/workspace";
import { useCheckCorrectWorspaceName } from "api/useCheckCorrectWorspaceName";
import { FC, ReactElement, useEffect, useState } from "react";

import styled from "styled-components";
import Button from "components/button/Button";
import CustomModal from "components/modal/Modal";
import useDebounce from "utils/useDebounse";
import FileInput from "components/uploadImage";
import { idID } from "@mui/material/locale";

type ModalChnagePasswordProps = {
  open: boolean;
  hide: (state: boolean) => void;
  workspaceData: TNewWorkspace;
  handleChangeWorkspaceData: (name: string, value: number | string) => void;
  handleVisibleModalCreateWorkspaceSigninType: (state: boolean) => void;
};

const ModalCreateWorkspace: FC<ModalChnagePasswordProps> = (
  props
): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [errorFile, setErrorFile] = useState<string | null>(null);
  const [file, setFile] = useState(null);

  const debouncedNameValue = useDebounce(props.workspaceData.name, 500);

  const { mutateAsync } = useCheckCorrectWorspaceName();

  const clearStates = () => {
    setError(null)
    setErrorFile(null)
    setFile(null)
    props.handleChangeWorkspaceData('name', '')
  }

  const checkName = async () => {
    try {
      if (props.open && debouncedNameValue && debouncedNameValue.length > 0) {
        const response = await mutateAsync({ name: debouncedNameValue });
        if (!response.is_exists) setError(null);
        else setError("There is an existing workspace with the same name.");
      } else return;
    } catch (err) {
      setError("There is an existing workspace with the same name.");
    }
  };

  useEffect(() => {
    checkName();
  }, [debouncedNameValue]);


  const changeFile = (value: any) => {
    if (value == null) {
      if (errorFile !== "") {
        setErrorFile("");
      }
      setFile(null);
      props.handleChangeWorkspaceData("logo", null)
      return;
    }
    if (value.size > 1000000) {
      setErrorFile("Image not uploaded as it is larger than 1MB.");
    } else {
      setFile(value);
      if (errorFile !== "") {
        setErrorFile("")
      }
    }
    props.handleChangeWorkspaceData("logo", value)
  }

  const submitForm = () => {
    if (props.workspaceData.name) {
      props.hide(false);
      props.handleVisibleModalCreateWorkspaceSigninType(true);
    } else {
      setError("Name is required");
    }
  };

  const closeModalHandler = () => {
    props.hide(false)
    clearStates()
  }

  return (
    <CustomModal open={props.open} hide={closeModalHandler} width="468">
      <StyledModalTitle id="Workspace_CreatePopUp_Title">Create new workspace</StyledModalTitle>
      <StyledModalContent aria-label={"Create workspace form"}>
        <StyledInput
          name="name"
          id="Workspace_CreatePopUp_Input"
          placeholder=""
          type="text"
          value={props.workspaceData.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value == null || e.target.value === "") {
              setError("Name is required");
            }
            props.handleChangeWorkspaceData("name", e.target.value)
          }
          }
        />
        {error && <Error id="Workspace_CreatePopUp_Input_Error" >{error}</Error>}

        <FileInput
          Id="Workspace_CreatePopUp_FileInput"
          onFileSelect={(file) => {
            changeFile(file)
          }}
          label={"Company logo (Optional)"}
          secondaryLabel={"Only .jpg and .png formats with max file size of 1MB accepted"}
          errorText={errorFile}
        />

        <ButtonWrapper>
          <StyledNextButton
            id="Workspace_CreatePopUp_NextBtn"
            variant="contained"
            onClick={submitForm}
            disabled={Boolean(error)}
          >
            Next
          </StyledNextButton>
          <StyledCancelButton
            id="Workspace_CreatePopUp_CancelBtn"
            variant="outlined"
            onClick={() => {
              props.handleChangeWorkspaceData("logo", null)
              props.handleChangeWorkspaceData("name", null)
              props.hide(false)
            }}
          >
             Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalCreateWorkspace;

const StyledModalTitle = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
`;

const StyledModalContent = styled.div``;

const StyledInput = styled(Input)`
  width: 100%;
  margin-top: 40px !important;

  label {
    margin-top: 24px;
  }

  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--grayInputPlaceholder);
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px !important;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border: 1px solid var(--colorNeutralForeground1) !important;
`;

const StyledNextButton = styled(Button)`
  width: 100%;
  max-width: 110px;
  height: 36px;
`;

const Error = styled.p`
  width: 100%;
  margin: 4px 0px 0px 0px;
  color: var(--colorPaletteRedForeground2);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;
