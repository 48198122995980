export const SIGN_IN_METHOD_OPTIONS = {
  email_and_password: {
    name: "Via email and password",
    value: 0,
    code: "email_and_password",
  },
  microsoft: {
    name: "Login with",
    value: 1,
    code: "microsoft",
  },
};

export const SIGN_IN_METHOD_OPTIONS_FOR_CARD = {
  email_and_password: "email and password",
  microsoft: "SSO",
};
