import http from "./config/https";

const useGetWoskspaceSetting = (workspaceId: number) =>{
  if (!http.isAPIGO) {
    return http.getGO(`/workspace/${workspaceId}/settings`)
  }else{
    return http.get(`/workspace/GetWorkspaceSetting?idWorkspace=${workspaceId}`, {}, {});
  }
}
  

export default useGetWoskspaceSetting;
