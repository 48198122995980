import { observer } from "mobx-react";
import { TTemplate } from "types/template";
import { FC, useState } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type TemplateItemProps = {
  template: TTemplate;
};

const TemplateItem: FC<TemplateItemProps> = observer((props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDeleteTemplatePopup = () => {
    store.setData("selectedTemplate", props.template);
    store.setData("isOpenRemoveTemplatePopup", true);
    setAnchorEl(null);
  };

  const openManageTemplatePopup = () => {
    store.setData("selectedTemplate", props.template);
    store.setData("isOpenManageTemplatePopup", true);
    setAnchorEl(null);
  };

  const openEditTemplatePopup = () => {
    store.setData("selectedTemplate", props.template);
    store.setData("isOpenEditTemplatePopup", true);
    setAnchorEl(null);
  };

  return (
    <Container id="TemplateDetails_Container">
      <TopContainer id="TemplateDetails_TopContainer">
        <ImageWrapper id="TemplateDetails_ImageWrapper">
          {props.template.logo_url !== null && props.template.logo_url !== "" ? <TemplateImage src={props.template.logo_url} id="TemplateDetails_TemplateImage" /> : <TemplateImage src="/assets/image-empty-template.png" id="TemplateDetails_TemplateImage" />}
        </ImageWrapper>
        <InfoWrapper id="TemplateDetails_InfoWrapper">
          <HeaderWrapper id="TemplateDetails_HeaderWrapper">
            <Header id="TemplateDetails_Header">{props.template.name}</Header>
            <MenuButton
              id="TemplateDetails_MenuButton"
              aria-controls={open ? "TemplateDetails_Menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon id="TemplateDetails_MoreVertIcon" />
            </MenuButton>
            <Menu
              id="TemplateDetails_Menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "TemplateDetails_MenuButton",
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem id="TemplateDetails_EditMenuItem" onClick={openEditTemplatePopup}>
                Edit template details
              </MenuItem>
              <MenuItem id="TemplateDetails_DeleteMenuItem" onClick={openDeleteTemplatePopup}>
                Delete
              </MenuItem>
            </Menu>
          </HeaderWrapper>
          <HeaderDescription id="TemplateDetails_HeaderDescription">
            {props.template.questions_count} questions, {props.template.estimation}
          </HeaderDescription>
          <LabelWrapper id="TemplateDetails_LabelWrapper">
            <Label id="TemplateDetails_Label">{props.template.type_questionary_name}</Label>
            <LabelText id="TemplateDetails_LabelText">
              Recommended frequency: {props.template.frequency_name}
            </LabelText>
          </LabelWrapper>
        </InfoWrapper>
      </TopContainer>
      <Box id="TemplateDetails_Box">
        <Description id="TemplateDetails_Description">{props.template.description}</Description>
      </Box>
    </Container>
  );
});

export default TemplateItem;

const Container = styled.div`
  width: 100%;
  min-width: 435px;
  padding: 14px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--colorNeutralForeground2);
`;

const TopContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 122px 1fr;
  margin-bottom: 10px;
  column-gap: 10px;
`;

const ImageWrapper = styled.div`
  img {
    height: 81px;
    width: 122px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const TemplateImage = styled.img``;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
`;

const Header = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
`;

const HeaderDescription = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--colorNeutralForeground1);
`;

const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--colorNeutralForeground1);
  background-color: var(--colorPaletteBlueBackground1);
  border-radius: 36px;
  padding: 0px 8px;
`;

const LabelText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--colorNeutralForeground2);
  margin-left: 8px;
`;

const MenuButton = styled(Button)`
  border-radius: 50% !important;
  width: 28px !important;
  height: 28px !important;
  min-width: 0px !important;

  svg {
    color: var(--colorNeutralForeground1);
  }
`;
