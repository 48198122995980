import { FC } from "react";
import { observer } from "mobx-react";
import { Dialog, DialogContent } from "@mui/material";

import store from "../../store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";

type SavePopupProps = {};

const SavePopup: FC<SavePopupProps> = observer(() => {
  return (
    <StyledDialog
      open={store.isOpenSaveFeatiresPopup}
      onClose={() => store.setData("isOpenSaveFeatiresPopup", false)}
    >
      <CloseBtnWrapper>
        <CloseButton
        id={`WorkspaceSettings_GridContainer_FeaturesSection_PopUpSave_CloseBtn`}
          onClick={() => store.setData("isOpenSaveFeatiresPopup", false)}
        >
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <StyledDialogContent>
        <DialogTitle
         id={`WorkspaceSettings_GridContainer_FeaturesSection_PopUpSave_Title`}
        >Are you sure you want to save changes?</DialogTitle>
        <Description
        id={`WorkspaceSettings_GridContainer_FeaturesSection_PopUpSave_Subtitle`}
        >
          It will affect feature settings for this entity.
        </Description>
        <ButtonWrapper>
          <SaveCustomButton
          id={`WorkspaceSettings_GridContainer_FeaturesSection_PopUpSave_SaveChangesBtn`}
            variant="contained"
            type="submit"
            onClick={() => {
              store.sendFeatureSettings();
            }}
          >
            Save changes
          </SaveCustomButton>
          <CancelCustomButton
          id={`WorkspaceSettings_GridContainer_FeaturesSection_PopUpSave_CancelBtn`}
            variant="outlined"
            onClick={() => store.setData("isOpenSaveFeatiresPopup", false)}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default SavePopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 502px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 40px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 48px 0px;
`;

const SaveCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 20px;
  color: var(--colorNeutralForeground1);
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;
