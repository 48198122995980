import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled as styledMui } from "@mui/material/styles";
import { Chip, IconButton, Switch, TextField, Tooltip } from "@mui/material";
import store from "./store";
import { observer } from "mobx-react";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "components/button/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomSwitch from "components/switch/Switch";

type BenchmarksTableProps = {};

const BenchmarksTable: React.FC<BenchmarksTableProps> = observer((props) => {
  return (
    <>
      <TableContainerWrapper className="parent">
        <TableWrapper className="child" aria-label="simple table">
          <TableBody>
            <StyledTableRow>
              <TableHeaderBenchmarks width={500}>
                Default Benchmarks
              </TableHeaderBenchmarks>
              <TableHeaderBenchmarks>
                Viewable For roles: “View All Respondents data”
                <Tooltip
                  title={
                    <span>
                      Roles with permissions to "View all respondents data" for
                      the Dashboard
                    </span>
                  }
                >
                  <WrappedInfo />
                </Tooltip>
              </TableHeaderBenchmarks>
              <TableHeaderBenchmarks>
                Viewable for roles: “View team data”
                <Tooltip
                  title={
                    <span>
                      Roles with permissions to "View team data" for the
                      Dashboard
                    </span>
                  }
                >
                  <WrappedInfo />
                </Tooltip>
              </TableHeaderBenchmarks>
            </StyledTableRow>

            {store.defaultBenchmarkList.map((benchmark) => (
              <TableRow key={benchmark.id}>
                <TableCellPermName size="small">
                  <BenchmarkName name={benchmark.name ?? ""} />
                </TableCellPermName>
                <TableCellPermName size="small">
                  <CustomSwitch
                    checked={benchmark.is_view_respondents}
                    onChange={(e) => {
                      store.changeBenchmarkPermission(
                        benchmark.id,
                        !benchmark.is_view_respondents,
                        "is_view_respondents",
                        true
                      );
                    }}
                  />
                </TableCellPermName>
                <TableCellPermName size="small">
                  <CustomSwitch
                    checked={benchmark.is_view_team}
                    onChange={(e) =>
                      store.changeBenchmarkPermission(
                        benchmark.id,
                        !benchmark.is_view_team,
                        "is_view_team",
                        true
                      )
                    }
                  />
                </TableCellPermName>
              </TableRow>
            ))}

            {store.customBenchmarkList.length !== 0 && (
              <StyledTableRow>
                <TableCellCustomBenchmarks $onlyLeft={true}>
                  <BenchmarkHeaderSort>
                    Custom Benchmarks
                    <BlockSortStyle>
                      <KeyboardArrowUpIconStyle
                        $enabled={store.sortType === "asc"}
                        id={"Benchmark_TableRowSort_asc_Benchmarks"}
                        onClick={() => store.changeSort("asc")}
                      />
                      <PlugStyle />
                      <KeyboardArrowDownIconStyle
                        $enabled={store.sortType === "desc"}
                        id={"Benchmark_TableRowSort_desc_Benchmarks"}
                        onClick={() => store.changeSort("desc")}
                      />
                    </BlockSortStyle>
                  </BenchmarkHeaderSort>
                </TableCellCustomBenchmarks>
                <TableCellCustomBenchmarks></TableCellCustomBenchmarks>
                <TableCellCustomBenchmarks
                  $onlyRight={true}
                ></TableCellCustomBenchmarks>
              </StyledTableRow>
            )}

            {store.customBenchmarkList.map((benchmark) => (
              <TableRow key={benchmark.id}>
                <TableCellPermName size="small">
                  {store.selectedId === benchmark.id ? (
                    <NameWrapper>
                      <WrappedTextField
                        value={store.newBenchmarkName}
                        size={"small"}
                        variant="standard"
                        fullWidth
                        onChange={(e) =>
                          store.setData("newBenchmarkName", e.target.value)
                        }
                      />
                      <StyledSaveButton
                        size="small"
                        variant="outlined"
                        onClick={() => store.renameBenchmark()}
                      >
                        Save changes
                      </StyledSaveButton>
                      <CustomButton
                        size="small"
                        onClick={() => store.cancelEditClicked()}
                      >
                        Cancel
                      </CustomButton>
                    </NameWrapper>
                  ) : (
                    <NameWrapper>
                      <EditName>
                        <BenchmarkName name={benchmark.name ?? ""} />
                        <IconButton
                          size="small"
                          onClick={() => {
                            store.editClicked(benchmark.id, benchmark.name);
                          }}
                        >
                          <WrappedEditIcon />
                        </IconButton>
                      </EditName>
                      <CustomButton
                        onClick={() => {
                          store.deleteBenchmarkClicked(benchmark);
                        }}
                      >
                        <DeleteIcon />
                        Delete
                      </CustomButton>
                    </NameWrapper>
                  )}
                </TableCellPermName>
                <TableCellPermName size="small">
                  <CustomSwitch
                    checked={benchmark.is_view_respondents}
                    onChange={(e) => {
                      store.changeBenchmarkPermission(
                        benchmark.id,
                        !benchmark.is_view_respondents,
                        "is_view_respondents",
                        false
                      );
                    }}
                  />
                </TableCellPermName>
                <TableCellPermName size="small">
                  <CustomSwitch
                    checked={benchmark.is_view_team}
                    onChange={(e) =>
                      store.changeBenchmarkPermission(
                        benchmark.id,
                        !benchmark.is_view_team,
                        "is_view_team",
                        false
                      )
                    }
                  />
                </TableCellPermName>
              </TableRow>
            ))}
          </TableBody>
        </TableWrapper>
      </TableContainerWrapper>
    </>
  );
});

const BenchmarkName = observer((props) => {
  const industryRegex = /{Industry}/g;
  const countryRegex = /{Country}/g;

  let afterIndustry = [];
  let result = [];

  let newText = props.name.split(industryRegex);
  for (let index = 0; index < newText.length; index++) {
    afterIndustry.push(newText[index]);
    if (newText.length - 1 !== index) {
      afterIndustry.push(
        <StyledChip
          $isCountry={false}
          size="small"
          label={store.industryName}
        />
      );
    }
  }
  afterIndustry.forEach((text) => {
    if (typeof text === "string") {
      let newText = text.split(countryRegex);
      for (let index = 0; index < newText.length; index++) {
        result.push(newText[index]);
        if (newText.length - 1 !== index) {
          result.push(
            <StyledChip
              $isCountry={true}
              size="small"
              label={store.countryName}
            />
          );
        }
      }
    } else {
      result.push(text);
    }
  });

  return <div>{result}</div>;
});

const WrappedInfo = styled(InfoIcon)`
  fill: var(--colorNeutralForeground5, #959ba2) !important;
  width: 15px !important;
  height: 15px !important;
  margin: 0 4px;
`;
const WrappedEditIcon = styled(EditIcon)`
  fill: var(--colorBrandForeground1, #1477f8) !important;
  width: 20px !important;
  height: 20px !important;
  margin: 0 4px;
  margin-bottom: 4px;
`;

const StyledTableRow = styledMui(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--colorPalleteLightBlue, #EEEFFF)",
  },
}));

const StyledSaveButton = styled(CustomButton)`
  width: 150px !important;
`;

const TableContainerWrapper = styled(TableContainer)``;
const WrappedTextField = styled(TextField)`
  margin-right: 10px !important;
`;

const StyledChip = styled(Chip) <{ $isCountry: boolean }>`
  background-color: ${(props) =>
    props.$isCountry
      ? "var(--colorPalettePurpleBackground2, #D8E2FF)"
      : "var(--colorPalettePurpleBackground3, #E2D6FF)"} !important;
`;

const TableWrapper = styled(Table)`
  min-width: 300px;
`;
const TableCellPermName = styled(TableCell)`
  background: var(--colorNeutralBackground1, #ffffff);
  width: 300px;
  border: 1px solid var(--colorNeutralForeground9, #e0e0e0);
  color: var(--Light-Text-Primary, #3e4450);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditName = styled.div`
  display: flex;
  align-items: center;
`;

const TableHeaderBenchmarks = styled(TableCell)`
  background-color: var(--colorPalleteLightBlue, #eeefff);
  border: 1px solid var(--colorNeutralForeground9, #e0e0e0);
  color: var(--Light-Text-Primary, var(--text-primary, rgba(0, 0, 0, 0.87)));
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%; /* 31.92px */
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const TableCellCustomBenchmarks = styled(TableCell) <{
  $onlyLeft?: boolean;
  $onlyRight?: boolean;
}>`
  padding: 10px 15px;
  background-color: var(--colorNeutralForeground4, #f1f3f8);
  color: var(--Light-Text-Primary, #3e4450);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%; /* 31.92px */
  letter-spacing: 1px;
  text-transform: uppercase;
  border-left: ${(props) =>
    props.$onlyLeft && "1px solid var(--colorNeutralForeground9, #E0E0E0)"};
  border-right: ${(props) =>
    props.$onlyRight && "1px solid var(--colorNeutralForeground9, #E0E0E0)"};
`;

const BenchmarkHeaderSort = styled.div`
  display: flex;
  align-items: center;
`;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon) <{
  $enabled?: boolean;
}>`
  color: ${(props) =>
    props.$enabled
      ? "var(--colorBrandForeground1)"
      : "var(--colorNeutralForeground2)"};
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon) <{
  $enabled?: boolean;
}>`
  color: ${(props) =>
    props.$enabled
      ? "var(--colorBrandForeground1)"
      : "var(--colorNeutralForeground2)"};
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const PlugStyle = styled.div`
  width: 20px;
  height: 8px;
  background-color: transparent;
  position: absolute;
  top: 6px;
  left: -5px;
  z-index: 999;
`;

export default BenchmarksTable;
