import { observer } from "mobx-react";
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { TActiveSeat } from "types/entity";
import { FC, useEffect, useCallback } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import store from "../../store";
import Button from "components/button/Button";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddActiveSeatsPopup from "./AddActiveSeatsPopup";
import RemoveActiveSeatsPopup from "./RemoveActiveSeatsPopup";

type ActiveSeatsTableProps = {
  list: TActiveSeat[];
};

const ActiveSeatsTable: FC<ActiveSeatsTableProps> = observer((props) => {
  useEffect(() => {
    if ((store.tabId === 1, store.selectedEntity)) {
      store.getEntityActiveSeats();
    }
  }, [store.selectedEntity, store.tabId]);

  useEffect(() => {
    if ((store.tabId === 1, store.selectedEntity)) {
      store.clearActiveSeatsTab();
    }
  }, [store.selectedEntity, store.tabId]);

  useEffect(() => {
    return () => {
      store.clearActiveSeatsTab()
    };
  }, [store.tabId]);

  const tableData = [
    {
      field: "full_name",
      headerName: "FULL NAME",
      width: 400,
      sortable: true,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_ActiveSeats_Tab_Column_FullName`}>{params.colDef.headerName}</span>
      },
      renderCell: (params) => {
        return <span  id={`WorkspaceSettings_GridContainer_ActiveSeats_Tab_Column_FullName_Table_${params.id}`}>{params.value}</span>
      }
    },
    {
      field: "email",
      headerName: "EMAIL",
      sortable: true,
      width: 200,
      flex: 0.1,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_ActiveSeats_Tab_Column_Email`}>{params.colDef.headerName}</span>
      },
      renderCell: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_ActiveSeats_Tab_Column_Email_Table_${params.id}`}>{params.value}</span>
      }
    },
    {
      field: "employee_id",
      headerName: "EMPLOYEE ID",
      sortable: true,
      width: 200,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_ActiveSeats_Tab_Column_EmployeeId`}>{params.colDef.headerName}</span>
      },
      renderCell: (params) => {
        return <span id={`WorkspaceSettings_GridContainer_ActiveSeats_Tab_Column_EmployeeId_Table_${params.id}`}>{params.value}</span>
      }
    },
  ];


  return (
    <Container>
      <SettingsContainer>
        <StyledSearchInput
          id={`WorkspaceSettings_GridContainer_ActiveSeats_SearchInput`}
          size="small"
          placeholder="Search person/email"
          InputProps={{
            endAdornment: (
              <InputAdornment
               position="end">
                <IconButton id={`WorkspaceSettings_GridContainer_ActiveSeats_SearchInputBtn`}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={store.searchActiveSeatsValue}
          onChange={(e) => {
            store.setData("searchActiveSeatsValue", e.target.value);
          }}
        />
        <ButtonGroup>
          <RemoveButton
           id={`WorkspaceSettings_GridContainer_ActiveSeats_RemoveBtn`}
            variant="outlined"
            onClick={() => store.setData("isOpenRemoveSeatsPopup", true)}
            disabled={!store.selectedActiveSeatsRows?.length}
          >
            <DeleteIcon />
            Remove
          </RemoveButton>
          <CreateButton
           id={`WorkspaceSettings_GridContainer_ActiveSeats_AddActiveSeatsBtn`}
            variant="contained"
            onClick={() => store.setData("isOpenAddSeatsPopup", true)}
          >
            <PersonAddIcon />
            Add active seats
          </CreateButton>
        </ButtonGroup>
      </SettingsContainer>
      <StyledDataGrid
        rows={props.list}
        columns={tableData}
        initialState={{
          pagination: {
            paginationModel: { page: store.activeSeatsPage, pageSize: store.activeSeatsPageSize },
          },
        }}
        rowCount={store.activeSeatsTotalCount}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode="server"
        onSortModelChange={(sortModel: GridSortModel) => {
          store.changeSortActiveSeats(sortModel)
        }}
        onPaginationModelChange={(model) => {
          store.changeActiveSeatsPagination(model.page, model.pageSize)
        }}
        checkboxSelection
        disableColumnFilter
        sortingMode="server"
        onRowSelectionModelChange={(ids) =>
          store.setData("selectedActiveSeatsRows", ids)
        }
        keepNonExistentRowsSelected
        rowSelectionModel={store.selectedActiveSeatsRows}
      />
      <AddActiveSeatsPopup />
      <RemoveActiveSeatsPopup />
    </Container>
  );
});

export default ActiveSeatsTable;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 220px !important;
  background-color: var(--colorNeutralBackground1) !important;
  margin: 0px !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 8px 11px !important;
    border-radius: 2px !important;
    font-family: Roboto;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const RemoveButton = styled(Button)`
  height: 34px;
  svg {
    font-size: 20px;
    margin-right: 8px;
  }
`;

const CreateButton = styled(Button)`
  margin-left: 16px !important;
  height: 34px;
  svg {
    font-size: 20px;
    margin-right: 8px;
  }
`;

const StyledDataGrid = styled(DataGrid)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 0px !important;

  .MuiDataGrid-columnHeaderTitleContainer {
    background-color: var(--colorNeutralForeground8);
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--colorNeutralForeground8);
    border-radius: 0px !important;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer !important;
  }

  .Mui-selected {
    background-color: transparent !important;
  }
  .MuiCheckbox-root {
    color: var(--colorBrandForeground1) !important;
  }


  .MuiDataGrid-root {
    border: none !important;
  }
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .Mui-odd {
    background-color: var(--colorNeutralForeground8);
  }
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border-right: 1px solid var(--graySelectBoxBorder);
  }
`;
