import { useDebounce } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { TNewWorkspace } from "types/workspace";
import { WorkspacesTable } from "./components/WorkspacesTable";
import { useGetWoskspaces } from "api/useGetWoskspaces";
import { useCreateWoskspace } from "api/useCreateWoskspace";
import { ReactElement, useCallback, useState } from "react";

import styled from "styled-components";
import Button from "components/button/Button";
import Pagination from "components/pagination/Pagination";
import ModalCreateWorkspace from "./components/ModalCreateWorkspace";
import FullPageLoadingIndicator from "components/Loader/FullPageLoadingIndicator";
import ModalErrorCreateWoskspace from "./components/ModalErrorCreateWoskspace";
import ModalCreateWorkspaceSigninType from "./components/ModalCreateWorkspaceSigninType";
import useSortedData from "../../utils/useSortedData";

const WorkspacesScreen = (): ReactElement => {
  const [
    isOpenModalCreateWorkspaceSigninType,
    setIsOpeModalCreateWorkspaceSigninType,
  ] = useState<boolean>(false);
  const [isOpenCreateWorkspacePopup, setIsOpenCreateWorkspacePopup] =
    useState<boolean>(false);
  const [isOpenErrorCreateWorkspacePopup, setIsOpenErrorCreateWorkspacePopup] =
    useState<boolean>(false);

  const [workspaceData, setWorkspaceData] = useState<TNewWorkspace>({
    name: "",
    sign_in_method: 0,
    logo: null,
  });

  const [params, setParams] = useState(defaultParams);

  const navigate = useNavigate();

  const handleParams = (newParams: { [key: string]: string | undefined }) =>
    setParams((prev) => ({ ...prev, ...newParams }));

  const debouncedSearchValue = useDebounce(params.search_value, 500);

  const handleChangeWorkspaceData = (name: string, value: any) =>
    setWorkspaceData((prev) => ({ ...prev, [name]: value }));

  const handleVisibleCreateWorkspacePopup = (state: boolean) =>
    setIsOpenCreateWorkspacePopup(state);

  const handleVisibleModalCreateWorkspaceSigninType = (state: boolean) =>
    setIsOpeModalCreateWorkspaceSigninType(state);

  const handleVisibleModalErrorCreateWorkspace = (state: boolean) =>
    setIsOpenErrorCreateWorkspacePopup(state);

  const { mutateAsync, isLoading } = useCreateWoskspace();

  const createNewWorkspace = useCallback(async () => {
    try {
      const response: any = await mutateAsync(workspaceData);

      handleVisibleModalCreateWorkspaceSigninType(false);
      navigate(`/workspaces/${response}`);
    } catch (err) {
      handleVisibleModalErrorCreateWorkspace(true);
      handleVisibleModalCreateWorkspaceSigninType(false);
    }
  }, [workspaceData]);

  const { data: workspacesData, isLoading: isLoadingGetWorkspaces } =
    useGetWoskspaces({
      offset:
        params.activePage === 1
          ? 0
          : (params.activePage - 1) * params.itemsCountPerPage,
      limit: params.itemsCountPerPage,
      sort_by: params.sort_type ? params.sort_by : undefined,
      sort_type: params.sort_type,
      search_by: debouncedSearchValue.length > 0 ? params.search_by : undefined,
      search_value:
        debouncedSearchValue.length > 0 ? debouncedSearchValue : undefined,
    });

  const { sortedData, changeSort, sortModel } = useSortedData(
    workspacesData?.workspace,
    { field: null, sort: null },
  );

  return (
    <Container>
      {isLoading || (isLoadingGetWorkspaces && <FullPageLoadingIndicator />)}
      <HeaderContainer>
        <Header id="Workspace_title">Workspaces</Header>
        <CreateWorkspaceBtn
          id="Workspace_CreateNewWorkspace_Btn"
          type="button"
          onClick={() => {
            setIsOpenCreateWorkspacePopup(true);
          }}
          variant="contained"
        >Create new workspace
        </CreateWorkspaceBtn>
      </HeaderContainer>
      <TableWrapper>
        <WorkspacesTable
          list={!isLoadingGetWorkspaces ? sortedData : []}
          sort_by={params.sort_by}
          sort_type={params.sort_type}
          handleParams={handleParams}
          search_by={params.search_by}
          search_value={params.search_value}
          changeSort={changeSort}
          sortModel={sortModel}
        />
        <PaginationWrapper>
          <PaginationText>
            {/* TODO */}
            {/* Viewing {params.itemsCountPerPage} per page */}
          </PaginationText>
          <Pagination
            activePage={workspacesData?.Pagination?.active_page}
            itemsCountPerPage={workspacesData?.Pagination?.items_per_page}
            onChange={(e) => {
              setParams((prev) => ({
                ...prev,
                offset: workspacesData?.Pagination?.items_per_page * e,
                activePage: e,
              }));
            }}
            pageRangeDisplayed={5}
            totalItemsCount={workspacesData?.Pagination?.total_items || 10}
            totalPages={workspacesData?.Pagination?.total_pages}
            showTotalPage={false}
          />
        </PaginationWrapper>
      </TableWrapper>

      <ModalCreateWorkspace
        open={isOpenCreateWorkspacePopup}
        hide={handleVisibleCreateWorkspacePopup}
        workspaceData={workspaceData}
        handleChangeWorkspaceData={handleChangeWorkspaceData}
        handleVisibleModalCreateWorkspaceSigninType={
          handleVisibleModalCreateWorkspaceSigninType
        }
      />
      <ModalCreateWorkspaceSigninType
        open={isOpenModalCreateWorkspaceSigninType}
        hide={handleVisibleModalCreateWorkspaceSigninType}
        workspaceData={workspaceData}
        handleChangeWorkspaceData={handleChangeWorkspaceData}
        createNewWorkspace={createNewWorkspace}
      />
      <ModalErrorCreateWoskspace
        open={isOpenErrorCreateWorkspacePopup}
        hide={handleVisibleModalErrorCreateWorkspace}
        createNewWorkspace={createNewWorkspace}
      />
    </Container>
  );
};

export default WorkspacesScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 48px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const Header = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const CreateWorkspaceBtn = styled(Button)`
  height: 36px;
  max-width: 186px;
  margin-top: 18px;
  outline: none;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const PaginationText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.7384616136550903px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const defaultParams = {
  activePage: 1,
  itemsCountPerPage: 10,
  totalItemsCount: 0,
  totalPages: 0,
  sort_by: undefined,
  sort_type: undefined,
  search_by: undefined,
  search_value: "",
};
