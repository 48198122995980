import { AxiosResponse } from "axios";
import http from "./config/https";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const RemoveTemplate = async (id_delete: number) => {
  const response = await http.remove(
    "/templ_template_questionary/" + id_delete,
    {}
  );
  if ([200, 201].includes(response.status)) {
    return response.data;
  }
  throw new UnexpectedApiError(response);
};
