import { FC } from "react";
import { Outlet } from "react-router-dom";

import styled from "styled-components";
import AppHeader from "components/header/AppHeader";
import Error422Dialog from "components/errors/Error422";
import { observer } from "mobx-react"

type AppWrapperProps = {};

const AppWrapper: FC<AppWrapperProps> = observer(() => {
  return (
    <AppMainWapper>
      <Error422Dialog/>
      <AppHeader />
      <AppContent>
        <Outlet />
      </AppContent>
    </AppMainWapper>
  );
});

export default AppWrapper;

const AppMainWapper = styled.div`
  overflow: hidden;
`;

const AppContent = styled.div`
  width: 100%;
  height: calc(100vh - 72px);
  overflow: auto;
  position: relative;
`;
