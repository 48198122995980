import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { Divider, Tooltip } from "@mui/material";
import store from "./store";
import styled from "styled-components";
import SurveysTable from "./SurveyList";
import DownloadIcon from "./icons/DownloadIcon";
import InfoIcon from '@mui/icons-material/Info';

type SurveyProjectsProps = {};

const SurveyProjects: FC<SurveyProjectsProps> = observer(() => {
  useEffect(() => {
    store.doLoad()
  }, [])


  return (
    <Container id="Survey_List_Page">

      <TopContainer>

        <Header id="Survey_List_Title">
          Survey Projects Management
        </Header>

        <RightSide>
          <QuestionExport id="Survey_List_Export">
            Question codes(xlsx){" "} <Tooltip id="Survey_List_Export_Question_Tooltip" title={
              <span id="Survey_List_Export_Question_Tooltip_Text">Click here to download the questions asked throughout the platform for the past year till now and assign them a question code for benchmarking purposes. Available date range: 1 January 2023 - 1 February 2024.</span>
            }>
              <WrappedInfo />
            </Tooltip> : <Export id="Survey_List_Export_Question_Button" onClick={() => store.downloadQuestionCodesExcel()}>Export</Export>
          </QuestionExport>
          <SurveyExport id="Survey_List_Export_Survey">
            <DownloadIcon /> All Surveys Details (xlsx): <Export id="Survey_List_Export_SurveyButton" onClick={() => store.downloadSurveyDetailsExcel()}>Export</Export>
          </SurveyExport>
        </RightSide>

      </TopContainer>

      <Divider />

      <TableWrapper>
        <SurveysTable />
      </TableWrapper>

    </Container>
  );
});

export default SurveyProjects;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 80px 48px 48px 48px;
`;

const WrappedInfo = styled(InfoIcon)`
  fill: var(--colorNeutralForeground5, #959BA2) !important; 
  width: 15px !important;
  height: 15px !important;
  margin: 0 4px;
`

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const Header = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 112.5% */
  
`

const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const QuestionExport = styled.div`
  display: flex;
  padding: 10px 20px;
  border-radius: var(--borderRadius, 4px);
  background: var(--colorNeutralForegroundInverted3, #EDF0FF);
  align-items: center;

  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-right: 15px;
`

const Export = styled.div`
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  color: var(--colorBrandForeground1, #1477F8);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`

const SurveyExport = styled.div`
  display: flex;
  padding: 10px 20px;
  border-radius: var(--borderRadius, 4px);
  background: var(--grenBackgroundColor2, #F5FEFF);
  align-items: center;
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const TableWrapper = styled.div`
  margin-top: 20px;
`

