import { useAuth0 } from "@auth0/auth0-react";
import { LogOutIcon } from "./LogOutIcon";
import { LogOutAlert } from "components/logout-alert/LogOutAlert";
import { prependBasePath } from "api/routes/basePath";
import { ReactElement, useState } from "react";

import styled from "styled-components";
import Button from "components/button/Button";

const TopBannerLogo = (): ReactElement => {
  const [openLogoutAlert, setOpenLogoutAlert] = useState({
    open: false,
    email: "",
  });

  const { logout } = useAuth0();

  return (
    <Container>
      <img
        alt="EngageRocket logo"
        height={50}
        width={176.41}
        src={prependBasePath("/assets/logo_500.png")}
      />

      <LogOutButton onClick={() => logout({ logoutParams: { returnTo: window["_env_"]?.REACT_APP_AUTH0_CALLBACK_URL ?? process.env.REACT_APP_AUTH0_CALLBACK_URL } })}>
        <LogOutIcon />
        Sign out
      </LogOutButton>

      <LogOutAlert
        open={openLogoutAlert.open}
        email={openLogoutAlert.email}
        handleClose={() => setOpenLogoutAlert({ open: false, email: "" })}
      />
    </Container>
  );
};

export default TopBannerLogo;

const Container = styled.div`
  background: var(--colorNeutralBackground1)
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 48px;
  position: relative;

  .MuiSnackbar-root {
    position: absolute;
    top: 90px;
    width: 100%;
    left: 0px;
    right: 0px;
    z-index: 0;
  }
`;

const LogOutButton = styled(Button)`
  color: var(--colorNeutralForeground1) !important;

  svg {
    margin-right: 8px;
  }
`;
