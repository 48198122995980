import http from "api/config/https";

export const checkEntityNameUnique = (
  name: string,
  workspaceId: number,
  entity_id: number
): Promise<any> => {
  if(http.isAPIGO){
return http.postGO(
    `/entity/checkname`,
    {
      name: name,
      workspace_id: workspaceId,
      entity_id,
    },
    {}
  );
  }else{
    return http.post(
      `/entity/checkname`,
      {
        name: name,
        workspace_id: workspaceId,
        entity_id,
      },
      {}
    );
  }
  
};
