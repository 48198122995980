import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const SuccessIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <path d="M25.2656 4.74062L11.0031 19.0156L5.70312 13.7156L7.46563 11.9531L11.0031 15.4906L23.5031 2.99062L25.2656 4.74062ZM22.5031 11.0406C22.6656 11.7531 22.7656 12.5031 22.7656 13.2656C22.7656 18.7906 18.2906 23.2656 12.7656 23.2656C7.24062 23.2656 2.76562 18.7906 2.76562 13.2656C2.76562 7.74062 7.24062 3.26562 12.7656 3.26562C14.7406 3.26562 16.5656 3.84063 18.1156 4.82812L19.9156 3.02812C17.8906 1.60312 15.4281 0.765625 12.7656 0.765625C5.86562 0.765625 0.265625 6.36562 0.265625 13.2656C0.265625 20.1656 5.86562 25.7656 12.7656 25.7656C19.6656 25.7656 25.2656 20.1656 25.2656 13.2656C25.2656 11.7781 24.9906 10.3531 24.5156 9.02812L22.5031 11.0406Z" fill="#A9AFC6" />
  </StyledSvg>
);
