import { TWorkspace } from "types/workspace";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import http from "./config/https";

export type GetWorkspacesSuccessResponseData = {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  totalPages: number;
  workspaces: TWorkspace[];
}[];

type RequestParams = {
  offset: number;
  limit: number;
  sort_by?: undefined | string;
  sort_type?: undefined | string;
  search_by?: undefined | string;
  search_value?: undefined | string;
};

type Workspace = {
  id: number;
  name: string;
  sign_in_method: number;
  created_at: string;
  entities: [];
};


const getWoskspaces = async (params: RequestParams) => {
  const workspacesList: Workspace[] = [];
  if (http.isAPIGO) {
    const { data } = await http.getGO(
      `/workspace/GetErList`,
      {},
      {
        params: {
          offset: params.offset,
          limit: params.limit,
          sort_by: params.sort_by,
          sort_type: params.sort_type,
          search_value: params.search_value,
          search_by: params.search_by,
        },
      }
    )
    const ids = data.map((obj: any) => obj.id);

    const entities = await http.postGO(
      `/entity/getbyworkspaces`,
      { workspace_ids: ids },
    )

    data.forEach(element => {
      const workspace: Workspace = {
        id: element.id,
        name: element.name,
        entities: entities.data.filter((entity: any) => entity.workspace_id === element.id),
        sign_in_method: element.sign_in_method,
        created_at: element.created_at
      };

      workspacesList.push(workspace);
    })
    return { workspace: workspacesList };
  } else {
    const { data } = await http.get(
      `/workspace/getall`,
      {},
      {
        params: {
          offset: params.offset,
          limit: params.limit,
          sort_by: params.sort_by,
          sort_type: params.sort_type,
          search_value: params.search_value,
          search_by: params.search_by,
        },
      }
    );
    data.forEach(element => {
      const workspace: Workspace = {
        id: element.id,
        name: element.name,
        entities: element.entities,
        sign_in_method: element.sign_in_method,
        created_at: element.created_at
      };

      workspacesList.push(workspace);
    })
    return { workspace: workspacesList };
  }


};

export const useGetWoskspaces = (
  params: RequestParams
): UseQueryResult<any, Error> => {
  const queryResult = useQuery(
    [
      "workspaces",
      params.limit,
      params.offset,
      params.sort_by,
      params.sort_type,
      params.search_by,
      params.search_value,
    ],
    {
      queryFn: () => getWoskspaces(params),
      enabled: true,
      onError: (error: Error) => error,
    }
  );

  return queryResult;
};
