import { FC } from "react";
import { observer } from "mobx-react";
import { Dialog, DialogContent } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

type ConfirmSavePopupProps = {
  onSaved: () => void;
};

const ConfirmSavePopup: FC<ConfirmSavePopupProps> = observer((props) => {
  return (
    <StyledDialog
      open={store.isOpenConfirmSavePopup}
      onClose={() => store.changeValue("isOpenConfirmSavePopup", false)}
    >
      <CloseBtnWrapper>
        <CloseButton
          onClick={() => store.changeValue("isOpenConfirmSavePopup", false)}
        >
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <StyledDialogContent>
        <DialogTitle>Are you sure you want to save changes?</DialogTitle>
        <Description>
          <WarningAmberIcon />
          The changes you are about to apply will affect the workspace level
          settings.
        </Description>
        <ButtonWrapper>
          <SaveCustomButton
            variant="contained"
            type="submit"
            onClick={() => {
              store.saveWorkspaceSettingsSubmit(props.onSaved);
            }}
          >
            Save changes
          </SaveCustomButton>
          <CancelCustomButton
            variant="outlined"
            onClick={() => {
              store.changeValue("isOpenConfirmSavePopup", false)
              store.clearStore()
            }}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default ConfirmSavePopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 502px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 40px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 48px 0px;
`;

const SaveCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.15px;
  margin-top: 20px;
  color: var(--colorNeutralForeground1);

  svg {
    color: var(--colorPaletteRedForeground2);
    margin-right: 4px;
    vertical-align: middle;
    font-size: 22px;
  }
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;
