import http from "./config/https";

const useGetEntityActiveSeats = (
  entityId: number,
  searchValue: string,
  page: number,
  pageSize: number,
  sortField: string,
  sortType: string
) =>
  http.get(
    `/entity_settings/activeSeatsByEntityId?id=${entityId}&searchValue=${searchValue}&page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortType=${sortType}`,
    {}
  );

export default useGetEntityActiveSeats;
