import { TMember } from "types/member";
import { FC, ReactElement } from "react";
import { useRemoveERMember } from "api/useRemoveERMember";

import styled from "styled-components";
import Button from "components/button/Button";
import CustomModal from "components/modal/Modal";
import MainStore from "MainStore"

type ModalRemoveMemberProps = {
  id?: string;
  open: boolean;
  hide: (state: boolean) => void;
  selectedMember: TMember | null;
};

const ModalRemoveMember: FC<ModalRemoveMemberProps> = (props): ReactElement => {
  const { mutateAsync, isLoading } = useRemoveERMember();

  const submitForm = async () => {
    try {
      if (props.selectedMember) {
        await mutateAsync(props.selectedMember.id);
      }
    } catch (err) {
      console.error(err);
      
    } finally {
      props.hide(false);
      MainStore.setSnackbar(props.selectedMember.user_email + " has been removed")
    }
    props.hide(false);
  };

  return (
    <CustomModal open={props.open} hide={props.hide} width="468">
      <StyledModalTitle id={props.id && `_${props.id}_PopUpRemove_Title`}>
        Are you sure you want to remove this ER member?
      </StyledModalTitle>
      <StyledModalContent aria-label={"Create workspace form"}>
        <Text
        id={props.id && `${props.id}_PopUpRemove_Subtitle`}
        >
          {props.selectedMember?.user_email || ""} will lose access to the internal
          tool immediately.
        </Text>
        <ButtonWrapper>
          <StyledNextButton
          id={props.id && `${props.id}_PopUpRemove_Btn_RemoveMember`}
            variant="contained"
            onClick={submitForm}
            disabled={isLoading}
          >
            Remove member
          </StyledNextButton>
          <StyledCancelButton
           id={props.id && `${props.id}_PopUpRemove_Btn_Cancel`}
            variant="outlined"
            onClick={() => props.hide(false)}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalRemoveMember;

const StyledModalTitle = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
`;

const StyledModalContent = styled.div``;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px;
  height: 36px;
  color: var(--colorNeutralForeground1) !important;
  border-color: var(--colorNeutralForeground1) !important;
`;

const StyledNextButton = styled(Button)`
  width: 100%;
  max-width: 150px;
  height: 36px;
  margin-right: 20px !important;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 16px 0px 0px 0px;
  color: var(--colorNeutralForeground1);
`;
