import { TBenchmark } from "types/survey";
import { makeAutoObservable } from "mobx";
import MainStore from "MainStore";
import getBenchmarks from "api/useGetBenchmarks";
import { renameBenchmark } from "api/useRenameBenchmark";
import { changeBenchmark } from "api/useChangeBenchmark";
import { removeBenchmark } from "api/useRemoveBenchmark";
import getEntityCountryName from "api/useGetEntityCountryName";
import getEntityIndustryName from "api/useGetEntityIndustryName";

class Store {
  surveyId: number = 0;
  benchmarkList: TBenchmark[] = [];
  defaultBenchmarkList: TBenchmark[] = [];
  customBenchmarkList: TBenchmark[] = [];

  selectedId: number = 0;
  newBenchmarkName: string = "";
  selectedBenchmark: TBenchmark = null;
  openDeleteBenchmark: boolean = false;
  deleteBenchmarkInput: string = "";
  deleteBenchmarkInputError: string = "";
  sortType: "asc" | "desc" = null;

  countryName: string = "Singapore";
  industryName: string = "Online/Technology";

  constructor() {
    makeAutoObservable(this);
  }

  clearStore = () => {};

  setData = (field: string, value: any) => {
    if (field === "deleteBenchmarkInput") {
      if (value !== "DELETE") {
        this.setData(
          "deleteBenchmarkInputError",
          "Please type in the exact letters"
        );
      } else {
        this.setData("deleteBenchmarkInputError", "");
      }
    }
    this[field] = value;
  };

  setBenchmarkData = (data: TBenchmark[]) => {
    this.benchmarkList = data;
    this.customBenchmarkList = data.filter((x) => !x.is_default);
    this.defaultBenchmarkList = data.filter((x) => x.is_default);
  };

  doLoad = (surveyId: number) => {
    this.setData("surveyId", surveyId);
    this.getBenchmarks();
    this.getEntityCountryName();
    this.getEntityIndustryName();
  };

  editClicked = (id: number, name: string) => {
    this.selectedId = id;
    this.newBenchmarkName = name;
  };

  clearAfterEditName = () => {
    this.customBenchmarkList.find((x) => x.id === this.selectedId).name =
      this.newBenchmarkName;
    this.selectedId = 0;
    this.newBenchmarkName = "";
  };

  changeNameEdit = (name: string) => {
    this.customBenchmarkList.find((x) => x.id === this.selectedId).name = name;
  };

  deleteBenchmarkClicked = (bench: TBenchmark) => {
    this.selectedBenchmark = bench;
    this.openDeleteBenchmark = true;
  };

  closeDeleteBenchmark = () => {
    this.selectedBenchmark = null;
    this.openDeleteBenchmark = false;
    this.deleteBenchmarkInput = "";
    this.deleteBenchmarkInputError = "";
  };

  cancelEditClicked = () => {
    this.selectedId = 0;
    this.newBenchmarkName = "";
  };

  changeBenchmarkPermission = (
    id: number,
    flag: boolean,
    field: string,
    is_default: boolean
  ) => {
    if (is_default) {
      this.defaultBenchmarkList.find((x) => x.id === id)[field] = flag;
    } else {
      this.customBenchmarkList.find((x) => x.id === id)[field] = flag;
    }
    this.changeBenchmark(id, field);
  };

  changeSort = (type: "asc" | "desc") => {
    if (type === this.sortType) {
      this.sortType = null;
      this.customBenchmarkList = this.benchmarkList.filter(
        (x) => !x.is_default
      );
    } else {
      this.sortType = type;
      if (type === "asc") {
        this.customBenchmarkList.sort((a, b) => a.name.localeCompare(b.name));
      } else if (type === "desc") {
        this.customBenchmarkList.sort((a, b) => b.name.localeCompare(a.name));
      }
    }
  };

  getBenchmarks = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getBenchmarks(this.surveyId);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setBenchmarkData(response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  renameBenchmark = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await renameBenchmark(
        this.selectedId,
        this.newBenchmarkName
      );
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.clearAfterEditName();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeBenchmark = async (idBench: number, field: string) => {
    try {
      MainStore.changeLoader(true);
      const response = await changeBenchmark(idBench, field);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        // this.setBenchmarkData(response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  deleteBenchmark = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await removeBenchmark(this.selectedBenchmark?.id);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.closeDeleteBenchmark();
        this.getBenchmarks();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getEntityCountryName = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getEntityCountryName(this.surveyId);
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        this.setData("countryName", response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getEntityIndustryName = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getEntityIndustryName(this.surveyId);
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        this.setData("industryName", response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
