import { TMember } from "types/member";
import { FC, useState } from "react";
import { ERMembersTable } from "./components/ERMembersTable";
import { useGetERMembers } from "api/useGetERMembers";

import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import Input from "@mui/joy/Input";
import styled from "styled-components";
import Button from "components/button/Button";
import useDebounce from "utils/useDebounse";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import ModalCreateMember from "./components/ModalCreateMember";
import ModalRemoveMember from "./components/ModalRemoveMember";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import FullPageLoadingIndicator from "components/Loader/FullPageLoadingIndicator";
import useSortedData from "../../utils/useSortedData";

const defaultParams = {
  activePage: 1,
  itemsCountPerPage: 10,
  totalItemsCount: 0,
  totalPages: 0,
  sort_by: "createdAt",
  sort_type: "desc",
  search_by: "email",
  search_value: "",
};

type ERMembersScreenProps = {};

const ERMembersScreen: FC<ERMembersScreenProps> = () => {
  const [params, setParams] = useState(defaultParams);
  const [isOpenCreateMemberPopup, setIsOpenCreateMemberPopup] =
    useState<boolean>(false);
  const [isOpenRemoveMemberPopup, setIsOpenRemoveMemberPopup] =
    useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<TMember | null>(null);

  const debouncedSearchValue = useDebounce(params.search_value, 500);

  const handleParams = (newParams: { [key: string]: string | undefined }) =>
    setParams((prev) => ({ ...prev, ...newParams }));

  const handleSelectedMember = (member: TMember) => setSelectedMember(member);

  const handleVisibleCreateMemberPopup = (state: boolean) =>
    setIsOpenCreateMemberPopup(state);

  const handleVisibleRemoveMemberPopup = (state: boolean) =>
    setIsOpenRemoveMemberPopup(state);

  const { data: membersData, isLoading: isLoadingGetMembers } = useGetERMembers(
    {
      offset:
        params.activePage === 1
          ? 0
          : (params.activePage - 1) * params.itemsCountPerPage,
      limit: params.itemsCountPerPage,
      sort_by:
        params.sort_by === "emailAsc" || params.sort_by === "emailDesc"
          ? "email"
          : params.sort_type
            ? params.sort_by
            : undefined,
      sort_type: params.sort_by === "emailAsc" ? "asc" : "desc",
      search_by: debouncedSearchValue.length > 0 ? params.search_by : undefined,
      search_value:
        debouncedSearchValue.length > 0 ? debouncedSearchValue : undefined,
    },
  );

  const { sortedData, changeSort, sortModel } = useSortedData(
    membersData?.members,
    { field: null, sort: null },
  );

  return (
    <Container>
      {isLoadingGetMembers && <FullPageLoadingIndicator />}
      <HeaderContainer>
        <Header id="ErMembers_Title">Internal tool ER members</Header>
        <HeaderRow>
          <TableSortContainer>
            <StyledInput
             id="ErMembers_SearchInput"
              endDecorator={<StyledSearchSharpIcon />}
              name="search_input"
              onChange={(e) => handleParams({ search_value: e.target.value })}
              placeholder="Search for member"
              type="text"
              value={params.search_value}
            />
            <SortWrapper>
              <SortWrapperText id="ErMembers_SelectInput_Label">Sort by</SortWrapperText>
              <FormControl fullWidth>
                <StyledSelect
                id="ErMembers_SelectInput"
                  value={params.sort_by}
                  onChange={(event: SelectChangeEvent<any>) =>
                    handleParams({
                      sort_by: event.target.value,
                      sort_value:
                        event.target.value === "emailAsc" ? "asc" : "desc",
                    })
                  }
                  variant="outlined"
                  IconComponent={KeyboardArrowDownSharpIcon}
                >
                  <MenuItem id="ErMembers_SelectInput_Option_CreatedAt" value={"createdAt"}>Last created</MenuItem>
                  <MenuItem id="ErMembers_SelectInput_Option_Email" value={"emailAsc"}>Email (A-Z)</MenuItem>
                  <MenuItem id="ErMembers_SelectInput_Option_EmailDesc" value={"emailDesc"}>Email (Z-A)</MenuItem>
                </StyledSelect>
              </FormControl>
            </SortWrapper>
          </TableSortContainer>
          <AddNewMemberButton
          id="ErMembers_AddNewMember"
            type="button"
            onClick={() => {
              handleVisibleCreateMemberPopup(true);
            }}
            variant="contained"
          >
            Add new member
          </AddNewMemberButton>
        </HeaderRow>
        <TableWrapper>
          <ERMembersTable
            list={!isLoadingGetMembers ? sortedData : []}
			id="ErMembers"
            sort_by={params.sort_by}
            sort_type={params.sort_type}
            handleParams={handleParams}
            search_by={params.search_by}
            search_value={params.search_value}
            handleVisibleRemoveMemberPopup={handleVisibleRemoveMemberPopup}
            handleSelectedMember={handleSelectedMember}
            sortModel={sortModel}
            changeSort={changeSort}
          />
          <PaginationWrapper>
            {/* TODO */}
            {/* <PaginationText>Viewing {10} per page</PaginationText> */}
            <Pagination
              //@ts-ignore
              activepage={membersData?.activePage}
              itemscountperpage={membersData?.itemsCountPerPage}
              onChange={(e) => {
                // setParams((prev) => ({
                //   ...prev,
                //   offset: membersData.itemsCountPerPage * e,
                //   activePage: e,
                // }));
              }}
              pagerangedisplayed={5}
              totalitemscount={membersData?.totalItemsCount || 10}
              totalpages={membersData?.totalPages}
              showtotalpage={"false"}
            />
          </PaginationWrapper>
        </TableWrapper>
      </HeaderContainer>

      <ModalCreateMember
        id="ErMembers"
        open={isOpenCreateMemberPopup}
        hide={handleVisibleCreateMemberPopup}
      />
      <ModalRemoveMember
       id="ErMembers"
        open={isOpenRemoveMemberPopup}
        hide={handleVisibleRemoveMemberPopup}
        selectedMember={selectedMember}
      />
    </Container>
  );
};

export default ERMembersScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 48px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const Header = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const TableSortContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AddNewMemberButton = styled(Button)`
  width: 100%;
  height: 36px;
  max-width: 158px;
  outline: none;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

// const PaginationText = styled.p`
//   font-family: Roboto, sans-serif;
//   font-size: 12px;
//   font-weight: 500;
//   line-height: 14px;
//   letter-spacing: 0.7384616136550903px;
//   text-align: left;
//   color: var(--colorNeutralForeground2);
//   text-transform: uppercase;
// `;

const StyledSearchSharpIcon = styled(SearchSharpIcon)`
  font-size: 18px;
  color: var(--colorNeutralForeground6);
`;

const SortWrapper = styled.div`
  display: flex;
  min-width: 270px;
  width: fit-content;
  align-items: center;
  margin-left: 24px;
`;

const SortWrapperText = styled.p`
  margin: 0 8px 0 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  color: var(--colorNeutralForeground2);
`;

const StyledInput = styled(Input)`
  height: 36px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  margin: 0;
  background-color: var(--colorNeutralBackground1);

  ::placeholder {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--colorNeutralForeground2);
  }
`;

const StyledSelect = styled(Select)`
  height: 36px;
  background-color: var(--colorNeutralBackground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);

  .MuiSelect-icon {
    color: var(--colorNeutralForeground5);
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--colorPaletteBlueBackground1);
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--colorBrandForeground1);
  }
`;
