import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  .path-background {
    fill: var(--colorBrandForeground1);
  }
`;

export const ErroIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="36" cy="36" r="36" fill="#FA6B6B" />
    <circle cx="36" cy="36" r="28" fill="#3E4450" />
    <g>
      <circle cx="36" cy="36" r="28" fill="url(#paint0_linear_1362_29486)" />
    </g>
    <path
      d="M28.417 27.1815L45.0258 43.7904"
      stroke="white"
      strokeWidth="5.09554"
      strokeLinecap="round"
    />
    <path
      d="M28.3801 43.8304L45.0117 27.2443"
      stroke="white"
      strokeWidth="5.09554"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1362_29486"
        x1="36"
        y1="10.1961"
        x2="36"
        y2="64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </StyledSvg>
);
