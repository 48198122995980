import { observer } from "mobx-react";
import { FC, ReactElement, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import store from "./store";
import styled from "styled-components";
import topStore from "../store";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";
import { TEntitySetting } from "types/workspace";
import NumberInput from "components/number-input/NumberInput";
import DatePickerComponent from "components/range-picker/RangePicker";
import dayjs from "dayjs";

type EditEntitySettingProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onClose: () => void;
  entity_setting: TEntitySetting;
};

const EditEntitySetting: FC<EditEntitySettingProps> = observer(
  (props): ReactElement => {
    useEffect(() => {
      if (props.openPanel) {
        let new_setting = Object.assign({}, props.entity_setting);
        store.changeValue("entity_setting", new_setting);
      }
    }, [props.openPanel]);

    return (
      <StyledDialog
        open={props.openPanel}
        onClose={() => {
          props.onBtnCancelClick();
          store.clearStore();
        }}
      >
        <CloseBtnWrapper>
          <CloseButton
            id={`WorkspaceSettings_Grid_PopUpEdit_CLoseBtn`}
            onClick={() => {
              props.onBtnCancelClick();
              store.clearStore();
            }}
          >
            <ClearIcon />
          </CloseButton>
        </CloseBtnWrapper>
        <StyledDialogContent>
          <Header>
            Edit number of people inside the entity “
            {topStore.selectedEntity?.name || ""}”
          </Header>
          {store.entity_setting && (
            <>
              <TextFieldWrapper>
                <NumberInput
                  id={`WorkspaceSettings_Grid_PopUpEdit_EntityAdminsInput`}
                  value={store.entity_setting.admin_count}
                  variant="standard"
                  error={store.erroradmin_count !== ""}
                  helperText={store.erroradmin_count}
                  label="Entity admins"
                  name="admin_count"
                  placeholder="Entity admins"
                  fullWidth
                  onChange={(e) => {
                    let value = e.target.value.replace(/^0+/, "");
                    value = value === "" ? "0" : value;
                    let v = parseInt(value, 10);
                    if (!isNaN(v)) store.changeFieldEntity("admin_count", v);
                  }}
                />
              </TextFieldWrapper>

              <TextFieldWrapper>
                <NumberInput
                  id={`WorkspaceSettings_Grid_PopUpEdit_UserCountInput`}
                  value={store.entity_setting.user_count}
                  variant="standard"
                  error={store.erroruser_count !== ""}
                  helperText={store.erroruser_count}
                  onChange={(e) => {
                    let value = e.target.value.replace(/^0+/, "");
                    value = value === "" ? "0" : value;
                    let v = parseInt(value, 10);
                    if (!isNaN(v)) store.changeFieldEntity("user_count", v);
                  }}
                  name="user_count"
                  label="Entity level users"
                  placeholder="Entity level users"
                  fullWidth
                />
              </TextFieldWrapper>

              <TextFieldWrapper>
                <NumberInput
                  id={`WorkspaceSettings_Grid_PopUpEdit_EmployeeCountInput`}
                  value={store.entity_setting.employee_count}
                  variant="standard"
                  error={store.erroremployee_count !== ""}
                  helperText={store.erroremployee_count}
                  onChange={(e) => {
                    let value = e.target.value.replace(/^0+/, "");
                    value = value === "" ? "0" : value;
                    let v = parseInt(value, 10);
                    if (!isNaN(v)) store.changeFieldEntity("employee_count", v);
                  }}
                  name="employee_count"
                  label="Entity level employees"
                  placeholder="Entity level employees"
                  fullWidth
                />
              </TextFieldWrapper>

              <MaxLevelActiveSeatsWrap>
                <NumberInput
                  id={`WorkspaceSettings_Grid_PopUpEdit_ActiveSeatCountInput`}
                  value={store.entity_setting?.active_seat_count}
                  variant="standard"
                  error={store.erroractive_seat_count !== ""}
                  helperText={store.erroractive_seat_count}
                  name="active_seat_count"
                  label="Max level active seats"
                  placeholder="Max level active seats"
                  fullWidth
                  onChange={(e) => {
                    let value = e.target.value.replace(/^0+/, "");
                    value = value === "" ? "0" : value;
                    let v = parseInt(value, 10);
                    if (!isNaN(v))
                      store.changeFieldEntity("active_seat_count", v);
                  }}
                />
              </MaxLevelActiveSeatsWrap>
              <GroupTitle>Set validity period</GroupTitle>
              <Group>
                <DatePickerComponent
                  startValue={dayjs(store.entity_setting?.strbegin_date)}
                  endValue={dayjs(store.entity_setting?.strend_date)}
                  onChange={(key, value) => {
                    store.changeFieldEntity(
                      key,
                      dayjs(value).format("YYYY-MM-DD")
                    );
                  }}
                  error={{
                    start: store.errorstrbegin_date !== "",
                    end: store.errorstrend_date !== "",
                  }}
                  helperText={{
                    start: store.errorstrbegin_date,
                    end: store.errorstrend_date,
                  }}
                />
              </Group>
            </>
          )}

          <ButtonWrapper>
            <SaveCustomButton
              id={`WorkspaceSettings_Grid_PopUpEdit_SaveChangesBtn`}
              variant="contained"
              type="submit"
              disabled={
                store.erroractive_seat_count !== "" ||
                store.erroradmin_count !== "" ||
                store.erroremployee_count !== "" ||
                store.errorstrbegin_date !== "" ||
                store.errorstrend_date !== "" ||
                store.erroruser_count !== ""
              }
              onClick={() => {
                store.saveEntitySettingSubmit();
              }}
            >
              Save changes
            </SaveCustomButton>
            <CancelCustomButton
              id={`WorkspaceSettings_Grid_PopUpEdit_CanselBtn`}
              variant="outlined"
              onClick={() => {
                props.onBtnCancelClick();
                store.clearStore();
              }}
            >
              Cancel
            </CancelCustomButton>
          </ButtonWrapper>
        </StyledDialogContent>
      </StyledDialog>
    );
  }
);

export default EditEntitySetting;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const TextFieldWrapper = styled.div`
  margin-top: 20px;
`;

const MaxLevelActiveSeatsWrap = styled.div`
  margin-top: 50px;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 592px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 40px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 48px 0px;
`;

const SaveCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const Group = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const GroupTitle = styled.p`
  margin: 20px 0px 0px 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: var(--colorNeutralForeground1);
`;
