import { FC } from "react";
import { observer } from "mobx-react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import store from "./store";
import styled from "styled-components";
import XLSUploader from "./XLSUploader";
import CustomButton from "components/button/Button";
import ImageUploader from "./ImageUploader";

type UploadTemplatePopupProps = {};

const UploadTemplatePopup: FC<UploadTemplatePopupProps> = observer(() => {
  return (
    <StyledDialog
      open={store.isOpenUploadTemplatePopup}
      onClose={() => store.setData("isOpenUploadTemplatePopup", false)}
      id="UploadTemplatePopup_Dialog"
    >
      <StyledDialogContent id="UploadTemplatePopup_DialogContent">
        <DialogTitle id="UploadTemplatePopup_DialogTitle">Upload template</DialogTitle>

        <StyledTextField
          id="UploadTemplatePopup_TemplateTitleField"
          value={store.templateTitle}
          onChange={(e) => store.setData("templateTitle", e.target.value)}
          fullWidth
          variant="standard"
          placeholder="Template title"
          helperText={`${store.templateTitle.length}/33`}
          inputProps={{
            maxLength: 33,
          }}
        />

        <ImageUploader />

        <SelectWrapper id="UploadTemplatePopup_SelectWrapper">
          <FormControl fullWidth id="UploadTemplatePopup_FrequencyFormControl">
            <InputLabel id="UploadTemplatePopup_FrequencyInputLabel">
              Set Recommended Frequency
            </InputLabel>
            <Select
              labelId="UploadTemplatePopup_FrequencyInputLabel"
              id="UploadTemplatePopup_FrequencySelect"
              value={store.freequency}
              label="Set Recommended Frequency"
              onChange={(e) => store.setData("freequency", e.target.value)}
            > {store.typeRecomendedFrequancy.map((x, index) => <MenuItem key={index} value={x.code} id={`UploadTemplatePopup_FrequencyOption_${x.code}`}>{x.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl fullWidth id="UploadTemplatePopup_TagFormControl">
            <InputLabel id="UploadTemplatePopup_TagInputLabel">Choose Tag</InputLabel>
            <Select
              labelId="UploadTemplatePopup_TagInputLabel"
              id="UploadTemplatePopup_TagSelect"
              required
              value={store.tag}
              label="Choose Tag"
              onChange={(e) => store.setData("tag", e.target.value)}
            >{store.typesQuestionary.map((x, index) => <MenuItem key={index} value={x.code} id={`UploadTemplatePopup_TagOption_${x.code}`}>{x.name}</MenuItem>)}
            </Select>
          </FormControl>
        </SelectWrapper>

        <StyledTextField
          id="UploadTemplatePopup_SummaryField"
          value={store.summary}
          onChange={(e) => store.setData("summary", e.target.value)}
          fullWidth
          variant="outlined"
          placeholder="Template summary"
          helperText={`${store.summary.length}/200`}
          inputProps={{
            maxLength: 200,
          }}
          label="Summary"
          multiline
        />

        <StyledFormControlLabel
          id="UploadTemplatePopup_DefaultTemplateControl"
          control={
            <StyledCheckBox
              id="UploadTemplatePopup_DefaultTemplateCheckBox"
              checked={store.is_default_template}
              onChange={() => store.setData("is_default_template", !store.is_default_template)}
            />
          }
          label="Make this template a default template for all entities that has access to Surveys."
        />
        <XLSUploader />

        <ButtonWrapper id="UploadTemplatePopup_ButtonWrapper">
          <UploadCustomButton
            id="UploadTemplatePopup_UploadButton"
            disabled={store.xlsUpload === null || store.templateTitle === "" || store.tag === ""}
            variant="contained"
            type="submit"
            onClick={store.saveTemplate}
          >
            Upload
          </UploadCustomButton>
          <CancelCustomButton
            id="UploadTemplatePopup_CancelButton"
            variant="outlined"
            onClick={() => {
              store.setData("isOpenUploadTemplatePopup", false);
              store.clearStore();
            }}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});


export default UploadTemplatePopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 520px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 40px 40px 0px 40px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 0px 40px 0px;
`;

const UploadCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 15px !important;

  .MuiFormHelperText-root {
    align-self: self-end !important;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground2);
  margin-top: 16px;
  align-items: flex-start !important;

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: 24px;
`;
