import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import styled from "styled-components";
import { observer } from 'mobx-react';

type TimePickerValueProps = {
  value: Dayjs;
  changeValue: (value: Dayjs) => void;
  disabled?: boolean;
  id?: string;
  label?: string;
};

const TimeField: React.FC<TimePickerValueProps> = observer((props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <WrappedTimeField
        disabled={props.disabled}
        value={props.value}
        label={props.label ? props.label : "Set time"}
        closeOnSelect={false}
        onChange={(newValue: Dayjs) => {
          if (newValue instanceof dayjs) {
            props.changeValue(newValue)
          }
        }}
      />
    </LocalizationProvider>
  );
})

const WrappedTimeField = styled(TimePicker)`
  input{
    border-color: #CDD3EC;
  }
`
export default TimeField;