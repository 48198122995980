import store from './store'

export const validate = (nameField, value, validated) => {
  let admin_count = "";
  if (nameField === "admin_count") {
    let admin_countErrors = [];
    CheckEmptyTextField(value, admin_countErrors)
    CheckOnlyDigit(value, admin_countErrors)
    OnlyPositive(value, admin_countErrors)
    CheckMaxValue(value, admin_countErrors, 250000)
    checkTotalUsers(value, admin_countErrors, store.workspace_setting.admin_count_total)

    admin_count = admin_countErrors.join(", ");
    validated("erroradmin_count", admin_count);
  }
  //End of validation field admin_count

  let user_count = "";
  if (nameField === "user_count") {
    let user_countErrors = [];
    CheckEmptyTextField(value, user_countErrors)
    CheckOnlyDigit(value, user_countErrors)
    OnlyPositive(value, user_countErrors)
    CheckMaxValue(value, user_countErrors, 250000)
    checkTotalUsers(value, user_countErrors, store.workspace_setting.user_count_total)
    user_count = user_countErrors.join(", ");
    validated("erroruser_count", user_count);
  }
  //End of validation field user_count

  let employee_count = "";
  if (nameField === "employee_count") {
    let employee_countErrors = [];
    CheckEmptyTextField(value, employee_countErrors)
    CheckOnlyDigit(value, employee_countErrors)
    OnlyPositive(value, employee_countErrors)
    CheckMaxValue(value, employee_countErrors, 250000)
    checkTotalEmployees(value, employee_countErrors, store.workspace_setting.employee_count_total)
    employee_count = employee_countErrors.join(", ");
    validated("erroremployee_count", employee_count);
  }
  //End of validation field employee_count

  let active_seat_count = "";
  if (nameField === "active_seat_count") {
    let active_seat_countErrors = [];
    CheckEmptyTextField(value, active_seat_countErrors)
    CheckOnlyDigit(value, active_seat_countErrors)
    OnlyPositive(value, active_seat_countErrors)
    CheckMaxValue(value, active_seat_countErrors, 250000)
    checkTotalActiveSeats(value, active_seat_countErrors, store.workspace_setting.active_seat_count_total)
    active_seat_count = active_seat_countErrors.join(", ");
    validated("erroractive_seat_count", active_seat_count);
  }
  //End of validation field active_seat_count

  let canSave =
    true &&
    admin_count === "" &&
    user_count === "" &&
    employee_count === "" &&
    active_seat_count === "";
  return canSave;
};

export function CheckEmptyTextField(s: string, errors: string[]) {
  if (s === "" || s === null){
    errors.push("Required field");
    return;
  }
}

export function CheckOnlyDigit(s: string, errors: string[]) {
  if (s === "" || s === null) return;
  let isnum = /^-?[0-9]+?$/.test(s);
  if (!isnum) errors.push("Only digits");
}

export function OnlyPositive(s: string, errors: string[]) {
  let isnum = +s
  if (isnum < 0) errors.push("Only positive");
}

export function CheckMaxValue(s: string, errors: string[], maxValue: number) {
  let isnum = +s
  if (isnum > maxValue) errors.push("Value can't exceed " + maxValue);
}

export function CheckMinValue(s: string, errors: string[], minValue: number) {
  let isnum = +s
  if (isnum > minValue) errors.push("Minimuv value " + minValue);
}

export function checkTotalAdmins(vallue: string, errors: string[], maxValue: number) {
  let isnum = +vallue
  if (isnum < maxValue) errors.push("Value is less than the total workspace admins");
}
export function checkTotalEmployees(vallue: string, errors: string[], maxValue: number) {
  let isnum = +vallue
  if (isnum < maxValue) errors.push("Value is less than the total employees");
}
export function checkTotalUsers(vallue: string, errors: string[], maxValue: number) {
  let isnum = +vallue
  if (isnum < maxValue) errors.push("Value is less than the total users amount");
}
export function checkTotalActiveSeats(vallue: string, errors: string[], maxValue: number) {
  let isnum = +vallue
  if (isnum < maxValue) errors.push("Value is less than the total active seats");
}