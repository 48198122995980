import { FC, Fragment } from "react";

import styled from "styled-components";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";

type CustomSwitchProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    defaultChecked?: boolean;
    id?: string;
};

const CustomSwitch: FC<CustomSwitchProps> = (props) => {
    return (
        <StyledSwitch
            onClick={(event) => {
                event.stopPropagation();
            }}
            onChange={props.onChange}
            id={props.id}
            checked={props.checked}
            slotProps={{
                track: {
                    children: (
                        <Fragment>
                            {props.checked && <StyledTypography $isOn>On</StyledTypography>}
                            {!props.checked && <StyledTypography>Off</StyledTypography>}
                        </Fragment>
                    ),
                },
            }}
            sx={{
                "--Switch-thumbSize": "16px",
                "--Switch-trackWidth": "52px",
                "--Switch-trackHeight": "22px",
                "--Switch-trackBackground": "var(--colorNeutralForeground5)",
            }}
            {...props}
        />
    );
};

export default CustomSwitch;

const StyledTypography = styled(Typography) <{ $isOn?: boolean }>`
  text-transform: uppercase !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  color: var(--colorNeutralBackground1) !important;
  padding: ${(props) => (props.$isOn ? "0px 8px" : "0px 0px 0px 25px")};
`;

const StyledSwitch = styled(Switch)`
  input {
    position: static !important;
  }
`;
