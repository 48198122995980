import { TQuestion, TQuestionDimension, TQuestionType } from "types/survey";
import { makeAutoObservable } from "mobx";
import MainStore from "MainStore";
import getSurveyQuestions from "api/useGetSurveyQuestions";
import getQuestionDimensions from "api/useGetQuestionDimensions";
import getQuestionTypes from "api/useGetQuestionTypes";

class Store {
  surveyId: number = 0;
  questionList: TQuestion[] = [];
  questionTypes: TQuestionType[] = [];
  questionDimensions: TQuestionDimension[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  clearStore = () => {};

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  doLoad = (surveyId: number) => {
    this.setData("surveyId", surveyId);
    this.getQuestions();
    this.getQuestionDimensions();
    this.getQuestionTypes();
  };

  getQuestions = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getSurveyQuestions(this.surveyId);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("questionList", response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getQuestionDimensions = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getQuestionDimensions();
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("questionDimensions", response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getQuestionTypes = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getQuestionTypes();
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("questionTypes", response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
