import { TEntity } from "types/entity";
import { Pagination } from "@mui/material";
import { TWorkspace } from "types/workspace";
import { useDebounce } from "usehooks-ts";
import { AdminsTable } from "features/admins-table/AdminsTable";
import { FC, useEffect, useState } from "react";
import { CustomBreadCrumbs } from "./components/BreadCrumbs";
import { GetAdminsForWorkspace } from "api/sharp/useGetWorkspaceAdmins";

import Button from "components/button/Button";
import styled from "styled-components";
import WorkspaceCard from "./components/WorkspaceCard";
import ModalEditEntity from "./components/ModalEditEntity";
import ModalCreateEntity from "./components/ModalCreateEntity";
import ModalEditWorkspace from "./components/ModalEditWorkspace";
import ModalCreateWorkspaceAdmin from "./components/ModalCreateWorkspaceAdmin";
import ModalCreateEntityAdmin from "./components/ModalCreateEntityAdmin";

import EntityCards from "./ManageEntities/cards";
import useSortedData from "../../utils/useSortedData";
import ModalAddOrUpdateEntity from "./components/AddOrUpdateEntity/ModalAddOrUpdateEntity";

const defaultParams = {
  activePage: 1,
  itemsCountPerPage: 10,
  totalItemsCount: 0,
  totalPages: 0,
  sort_by: undefined,
  sort_type: undefined,
  search_by: undefined,
  search_value: "",
};

type WorkspaceScreenProps = {
  workspaceInfo: TWorkspace | undefined;
};

const WorkspaceScreen: FC<WorkspaceScreenProps> = (props) => {
  const [params, setParams] = useState(defaultParams);
  const [isOpenModalCreateWorkspaceAdmin, setIsOpenModalCreateWorkspaceAdmin] =
    useState(false);
  const [isOpenModalCreateEntityAdmin, setIsOpenModalCreateEntityAdmin] =
    useState(false);
  const [isOpenModalCreateEntity, setIsOpenModalCreateEntity] = useState(false);
  const [isOpenModalEditWorkspace, setIsOpenModalEditWorkspace] =
    useState(false);
  const [isOpenModalEditEntity, setIsOpenModalEditEntity] = useState(false);
  const [activeEntity, setActiveEntity] = useState<TEntity | null>(null);

  const handleParams = (newParams: { [key: string]: string | undefined }) =>
    setParams((prev) => ({ ...prev, ...newParams }));

  // const debouncedSearchValue = useDebounce(params.search_value, 500);

  // TODO only for demo, revert when Go backend is ready
  // const { data: entityList, isLoading: isLoadingGetEntity } =
  //   useGetEntitiesByWorkspaceId(props.workspaceInfo?.id);

  // TODO only for demo, revert when Go backend is ready
  // const { data: workspaceAdminsData, isLoading: isLoadingGetWorkspaceAdmins } =
  //   useGetWoskspaceAdmins(
  //     {
  //       offset:
  //         params.activePage === 1
  //           ? 0
  //           : (params.activePage - 1) * params.itemsCountPerPage,
  //       limit: params.itemsCountPerPage,
  //       sort_by: params.sort_type ? params.sort_by : undefined,
  //       sort_type: params.sort_type,
  //       search_by:
  //         debouncedSearchValue.length > 0 ? params.search_by : undefined,
  //       search_value:
  //         debouncedSearchValue.length > 0 ? debouncedSearchValue : undefined,
  //     },
  //     props.workspaceInfo?.id
  //   );

  const [isLoadingGetWorkspaceAdmins, setIsLoadingGetWorkspaceAdmins] =
    useState(true);
  const [workspaceAdminsData, setAdminData] = useState(null);

  useEffect(() => {
    GetAdminsForWorkspace(props.workspaceInfo?.id).then((x) => {
      setAdminData({
        admins: x.data,
      });
      setIsLoadingGetWorkspaceAdmins(false);
    });
  }, [props.workspaceInfo?.id]);

  const handleVisibleCreateWorkspaceAdminPopup = (state: boolean) =>
    setIsOpenModalCreateWorkspaceAdmin(state);

  const handleVisibleCreateEntityAdminPopup = (state: boolean) =>
    setIsOpenModalCreateEntityAdmin(state);

  const handleVisibleEditWorkspacePopup = (state: boolean) =>
    setIsOpenModalEditWorkspace(state);

  const handleVisibleCreateEntityPopup = (state: boolean) =>
    setIsOpenModalCreateEntity(state);

  const handleVisibleEditEntityPopup = (state: boolean) =>
    setIsOpenModalEditEntity(state);

  // const handleActiveEntity = (entity: TEntity) => setActiveEntity(entity);

  const { sortedData, changeSort, sortModel } = useSortedData(
    workspaceAdminsData?.admins,
    { field: null, sort: null },
  );

  return (
    <Container>
      <BreadCrumbWrapper>
        <CustomBreadCrumbs
          workspaceName={props.workspaceInfo?.name || "Workspace name"}
        />
      </BreadCrumbWrapper>
      <WorkspaceCardWrapper>
        <WorkspaceCard
          workspaceInfo={props.workspaceInfo}
          workspaceAdminsData={workspaceAdminsData?.admins}
          handleVisibleEditWorkspacePopup={handleVisibleEditWorkspacePopup}
        />
      </WorkspaceCardWrapper>
      {/* <Row> TODO for demo
        <EntityHeader>Entities ({entityList?.entities.length})</EntityHeader>
      </Row>
      <EntityList>
        {entityList?.entities &&
          entityList.entities.map((entity: TEntity) => (
            <EntityCard
              entity={entity}
              handleActiveEntity={handleActiveEntity}
              handleVisibleEditEntityPopup={handleVisibleEditEntityPopup}
            />
          ))}
        <CreateEntityButton
          handleVisibleCreateEntityPopup={handleVisibleCreateEntityPopup}
        />
      </EntityList>*/}

      <EntityCards
        workspaceId={props.workspaceInfo?.id}
        workspaceName={props.workspaceInfo?.name}
        workspaceLogo={props.workspaceInfo?.logo_url}
      ></EntityCards>
      <Row>
        <EntityHeader id="Workspace_Manage_Title">Workspace / Entity admins</EntityHeader>
        <AddAdminWrapper>
          <AddAdminButton
          id="Workspace_Manage_AddWorkspaceAdminBtn"
            variant="outlined"
            onClick={() => handleVisibleCreateWorkspaceAdminPopup(true)}
          >
            Add workspace admin
          </AddAdminButton>
          <AddAdminButton
            id="Workspace_Manage_AddEntityAdminBtn"
            variant="outlined"
            onClick={() => handleVisibleCreateEntityAdminPopup(true)}
          >
            Add entity admin
          </AddAdminButton>
        </AddAdminWrapper>
      </Row>
      <TableWrapper>
        <AdminsTable
          list={!isLoadingGetWorkspaceAdmins ? sortedData : []}
		  id="Workspace_Manage"          sort_by={params.sort_by}
          sort_type={params.sort_type}
          refetch={() => {
            GetAdminsForWorkspace(props.workspaceInfo?.id).then((x) => {
              setAdminData({
                admins: x.data,
              });
              setIsLoadingGetWorkspaceAdmins(false);
            });
          }}
          handleParams={handleParams}
          search_by={params.search_by}
          search_value={params.search_value}
          sortModel={sortModel}
          changeSort={changeSort}
        />
        <PaginationWrapper>
          <PaginationText>
            {/* TODO */}
            {/* Viewing {params.itemsCountPerPage} per page */}
          </PaginationText>
          <Pagination
            //@ts-ignore
            activepage={workspaceAdminsData?.activePage}
            itemscountperpage={workspaceAdminsData?.itemsCountPerPage}
            onChange={() => {
              // setParams((prev) => ({
              //   ...prev,
              //   offset: workspaceAdminsData.itemsCountPerPage * e,
              //   activePage: e,
              // }));
            }}
            pagerangedisplayed={10}
            totalitemscount={workspaceAdminsData?.totalItemsCount || 10}
            totalpages={workspaceAdminsData?.totalPages}
            showtotalpage={"false"}
          />
        </PaginationWrapper>
      </TableWrapper>
      <ModalCreateWorkspaceAdmin
        open={isOpenModalCreateWorkspaceAdmin}
        hide={handleVisibleCreateWorkspaceAdminPopup}
        onSave={() => {
          GetAdminsForWorkspace(props.workspaceInfo?.id).then((x) => {
            setAdminData({
              admins: x.data,
            });
            setIsLoadingGetWorkspaceAdmins(false);
          });
        }}
        workspaceId={props.workspaceInfo?.id}
        currentAdmins={workspaceAdminsData?.admins || []}
      />
      <ModalCreateEntityAdmin
        open={isOpenModalCreateEntityAdmin}
        hide={handleVisibleCreateEntityAdminPopup}
        currentAdmins={workspaceAdminsData?.admins || []}
        workspaceInfo={props.workspaceInfo}
        onSave={() => {
          GetAdminsForWorkspace(props.workspaceInfo?.id).then((x) => {
            setAdminData({
              admins: x.data,
            });
            setIsLoadingGetWorkspaceAdmins(false);
          });
        }}
        workspaceId={props.workspaceInfo?.id}
      />
      <ModalEditWorkspace
        open={isOpenModalEditWorkspace}
        hide={handleVisibleEditWorkspacePopup}
        workspaceInfo={props.workspaceInfo}
      />
      <ModalCreateEntity
        open={isOpenModalCreateEntity}
        hide={handleVisibleCreateEntityPopup}
        workspaceInfo={props.workspaceInfo}
      />
      {/* <ModalEditEntity
        open={isOpenModalEditEntity}
        hide={handleVisibleEditEntityPopup}
        entityInfo={activeEntity}
        workspaceId={props.workspaceInfo?.id}
      /> */}
      {/* <ModalAddOrUpdateEntity
        open={isOpenModalEditEntity}
        hide={handleVisibleEditEntityPopup}
        workspaceId={props.workspaceInfo?.id}
        entityId={activeEntity?.id}
        onSaved={() => {}}
      /> */}
    </Container>
  );
};

export default WorkspaceScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 48px;
`;

const BreadCrumbWrapper = styled.div`
  width: 100%;
`;

const WorkspaceCardWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const EntityHeader = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
`;

const AddAdminWrapper = styled.div`
  display: inline-flex;
`;

const AddAdminButton = styled(Button)`
  max-width: 220px;
  margin-left: 10px !important;
  height: 36px;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const PaginationText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.7384616136550903px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

// const EntityList = styled.div`
//   width: 100%;
//   display: flex;
//   row-gap: 16px;
//   column-gap: 16px;
//   flex-wrap: wrap;
// `;
