import App from "./App";
import ReactDOM from "react-dom/client";
import Error500Page from "pages/page500/index.page";
import ReactQueryProvider from "./providers/ReactQueryProvider";

import { queryClient } from "./providers/ReactQueryProvider";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Auth0ProviderWithNavigate } from "Auth0Provider";

import "./index.css";
import "./assets/styles/variables.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary fallback={<Error500Page />}>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <ReactQueryProvider queryClient={queryClient}>
          <App />
        </ReactQueryProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </ErrorBoundary>
);
