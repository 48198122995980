import { observer } from "mobx-react";
import {
  DataGrid, GridFilterItem,
  GridFilterInputValueProps, getGridStringOperators, GridToolbarFilterButton
} from '@mui/x-data-grid';
import React from "react";
import store from "./store";
import { InputLabel } from '@mui/material';
import styled from "styled-components";
import { GridColDef } from "@mui/x-data-grid/models";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ImportIcon from "../icons/ImportIcon";

type AllQuestionsProps = {
};

const AllQuestions: React.FC<AllQuestionsProps> = observer((props) => {

  const tableData: GridColDef[] = [
    {
      field: "dimension_idNavName",
      headerName: "Dimension",
      sortable: true,
      flex: 1,
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_AllQuestions_Table_Header_Dimension">Dimension</HeaderTable>
      },
      renderCell: (params) => {
        return <span id={"Survey_View_AllQuestions_Table_Dimension_" + params.row.id}>{params.row.dimension_idNavName}</span>
      },
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator || !Array.isArray(filterItem.value) || filterItem.value.length === 0) {
              return null;
            }
            return ({ value }): boolean => {
              return value != null && Array.isArray(filterItem.value) && filterItem.value.includes(value);
            };
          },
          InputComponent: DimensionsInputValue,
          InputComponentProps: { type: 'string' },
        },
      ],
    },
    {
      field: "title",
      headerName: "Question",
      sortable: true,
      flex: 3,
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_AllQuestions_Table_Header_Question">Question</HeaderTable>
      },
      renderCell: (params) => {
        return <span id={"Survey_View_AllQuestions_Table_Question_" + params.row.id}>{params.row.title}</span>
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains' || operator.value === 'equals',
      ),
    },
    {
      field: "type_question_idNavName",
      headerName: "Type",
      sortable: true,
      flex: 1,
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_AllQuestions_Table_Header_Type">Type</HeaderTable>
      },
      renderCell: (params) => {
        return <span id={"Survey_View_AllQuestions_Table_Type_" + params.row.id}>{params.row.type_question_idNavName}</span>
      },
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem: GridFilterItem, column) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator || !Array.isArray(filterItem.value) || filterItem.value.length === 0) {
              return null;
            }
            return ({ value }): boolean => {
              return value != null && Array.isArray(filterItem.value) && filterItem.value.includes(value);
            };
          },
          InputComponent: TypesInputValue,
          InputComponentProps: { type: 'string' },
        },
      ],
    },
    {
      field: "id",
      headerName: "Question ID",
      sortable: true,
      filterable: false,
      flex: 1,
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_AllQuestions_Table_Header_QuestionID">Question ID</HeaderTable>
      },
      renderCell: (params) => {
        return <span id={"Survey_View_AllQuestions_Table_QuestionID_" + params.row.id}>{params.row.id}</span>
      },
    },
    {
      field: "name",
      headerName: "Question Code",
      sortable: true,
      flex: 1,
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_AllQuestions_Table_Header_QuestionCode">Question Code</HeaderTable>
      },
      renderCell: (params) => {
        return <span id={"Survey_View_AllQuestions_Table_QuestionCode_" + params.row.id}>{params.row.name}</span>
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'contains' || operator.value === 'equals',
      ),
    },
    {
      field: "isQuestionCondition",
      headerName: "Conditional Logic",
      sortable: true,
      flex: 1,
      type: "boolean",
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_AllQuestions_Table_Header_QuestionCode">Conditional Logic</HeaderTable>
      },
    },
    {
      field: "isCalculateScore",
      headerName: "Overall Score",
      sortable: true,
      flex: 1,
      type: "boolean",
      renderHeader: (params) => {
        return <HeaderTable id="Survey_View_AllQuestions_Table_Header_QuestionCode">Overall Score</HeaderTable>
      },
    },
  ];


  return (
    <Container>
      <QuestionExportWrap>
        <QuestionExport id="Survey_View_AllQuestions_QuestionExport">
          <ImportIcon />
          Question codes(xlsx){" "} : <Import id="Survey_View_AllQuestions_QuestionExport_Button" href={"/survey-projects/import-questions/" + store.surveyId}>Import</Import>
        </QuestionExport>
      </QuestionExportWrap>
      <StyledDataGrid
        rows={store.questionList}
        columns={tableData}
        pageSizeOptions={[10, 25, 50, 100]}
        rowSelection={false}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        slots={{
          toolbar: GridToolbarFilterButton,
        }}
      />
    </Container>
  );
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      padding: 0
    },
  },
};

const TypesInputValue = observer((props: GridFilterInputValueProps) => {
  const { item, applyValue, focusElementRef } = props;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    applyValue({ ...item, value: typeof value === 'string' ? value.split(',') : value })
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">Value</InputLabel>
        <StyledSelect
          multiple
          variant="standard"
          labelId="demo-multiple-checkbox-label"
          value={item.value ?? []}
          onChange={handleChange}
          renderValue={(selected: any) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {store.questionTypes.map((status) => (
            <CustomMenu key={status.id} value={status.name}>
              <Checkbox checked={(item.value ?? []).indexOf(status.name) > -1} />
              <ListItemText primary={status.name} />
            </CustomMenu>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
})

const DimensionsInputValue = observer((props: GridFilterInputValueProps) => {
  const { item, applyValue, focusElementRef } = props;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    applyValue({ ...item, value: typeof value === 'string' ? value.split(',') : value })
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">Value</InputLabel>
        <StyledSelect
          multiple
          variant="standard"
          labelId="demo-multiple-checkbox-label"
          value={item.value ?? []}
          onChange={handleChange}
          renderValue={(selected: any) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {store.questionDimensions.map((status) => (
            <CustomMenu key={status.id} value={status.name}>
              <Checkbox checked={(item.value ?? []).indexOf(status.name) > -1} />
              <ListItemText primary={status.name} />
            </CustomMenu>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
})


export default AllQuestions;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
`;

const CustomMenu = styled(MenuItem)`
  padding: 0 !important;
`;


const HeaderTable = styled.span<{ $second?: boolean }>`
  color: ${props => props.$second ? "var(--colorShadowInverted5, rgba(0, 0, 0, 0.38))"
    : "var(--colorNeutralBackgroundInverted2, #10141f)"} !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%; /* 31.92px */
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const QuestionExportWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

const QuestionExport = styled.div`
  display: flex;
  padding: 10px;
  border-radius: var(--borderRadius, 4px);
  background: var(--colorNeutralForeground4, #F1F3F8);
  align-items: center;
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  width: 250px;
`

const Import = styled.a`
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  text-decoration: none;
  color: var(--colorBrandForeground1, #1477F8);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`

const StyledDataGrid = styled(DataGrid)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 0px !important;

  .MuiDataGrid-columnHeaderTitleContainer {
    background-color: var(--colorPaletteBlueBackground2);
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--colorPaletteBlueBackground2);
    border-radius: 0px !important;
    text-transform: uppercase !important;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer !important;
  }

  .Mui-selected {
    background-color: transparent !important;
  }
  .MuiCheckbox-root {
    color: var(--colorBrandForeground1) !important;
  }


  .MuiDataGrid-root {
    border: none !important;
  }
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .Mui-odd {
    background-color: var(--colorNeutralForeground8);
  }
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border-right: 1px solid var(--graySelectBoxBorder);
    color: var(--Light-Text-Primary, var(--text-primary, rgba(0, 0, 0, 0.87)));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
  }
`;
