import { validate } from "./valid";
import { TWorkspaceSetting } from "types/workspace";
import { makeAutoObservable } from "mobx";

import topStore from "../store";
import MainStore from "MainStore";
import useSaveWorkspaceSettings from "api/useSaveWorkspaceSettings";

class Store {
  workspace_setting: TWorkspaceSetting = null;
  erroradmin_count = "";
  erroruser_count = "";
  erroremployee_count = "";
  erroractive_seat_count = "";
  id_workspace = 0;

  isOpenConfirmSavePopup: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  changeFieldWorkspace = (name: string, value: number) => {
    this.workspace_setting[name] = value;
    validate(name, value, (errorField: string, errorText: string) => {
      this[errorField] = errorText;
    });
  };

  clearStore() {
    this.workspace_setting = null;
    this.erroradmin_count = "";
    this.erroruser_count = "";
    this.erroremployee_count = "";
    this.erroractive_seat_count = "";
  }

  saveWorkspaceSettings = () => {
    const validated = (errorField: string, errorText: string) => {
      this[errorField] = errorText;
    };

    let canSave = true;
    canSave =
      validate("admin_count", this.workspace_setting.admin_count, validated) &&
      canSave;
    canSave =
      validate("user_count", this.workspace_setting.user_count, validated) &&
      canSave;
    canSave =
      validate(
        "employee_count",
        this.workspace_setting.employee_count,
        validated
      ) && canSave;
    canSave =
      validate(
        "active_seat_count",
        this.workspace_setting.active_seat_count,
        validated
      ) && canSave;
    if (
      this.workspace_setting.workspace_id == null ||
      this.workspace_setting.workspace_id == 0
    ) {
      this.workspace_setting.workspace_id = this.id_workspace;
    }

    if (canSave) {
      this.isOpenConfirmSavePopup = true;
      topStore.setData("openPanelEditWorkspaceSetting", false);
    } else {
      // MainStore.openErrorDialog(i18n.t("message:error.alertMessageAlert")); //todo message if not valid
    }
    return canSave;
  };

  saveWorkspaceSettingsSubmit = async (afterSave: () => void) => {
    try {
      MainStore.changeLoader(true);
      const response = await useSaveWorkspaceSettings(this.workspace_setting);
      if (response.status == 200 || response.status === 201) {
        afterSave();
        this.clearStore()
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      this.isOpenConfirmSavePopup = false;
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
