import styled from "styled-components";

const ReloadIcon = () => {
  return (
    <SvgWrapped xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path d="M10.8862 1.7625C9.79875 0.675 8.30625 0 6.64875 0C3.33375 0 0.65625 2.685 0.65625 6C0.65625 9.315 3.33375 12 6.64875 12C9.44625 12 11.7787 10.0875 12.4462 7.5H10.8862C10.2712 9.2475 8.60625 10.5 6.64875 10.5C4.16625 10.5 2.14875 8.4825 2.14875 6C2.14875 3.5175 4.16625 1.5 6.64875 1.5C7.89375 1.5 9.00375 2.0175 9.81375 2.835L7.39875 5.25H12.6487V0L10.8862 1.7625Z" fill="#1477F8" />
    </SvgWrapped>
  );
};

const SvgWrapped = styled.svg`
  margin: 0 5px;
  fill: var(--ER-Theme-sys-light-primary, #1477F8);
`;
export default ReloadIcon;
