import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { TWorkspace } from "types/workspace";

import http from "./config/https";

export type GetERMembersSuccessResponseData = {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  totalPages: number;
  workspaces: TWorkspace[];
}[];

type RequestParams = {
  offset: number;
  limit: number;
  sort_by?: undefined | string;
  sort_type?: undefined | string;
  search_by?: undefined | string;
  search_value?: undefined | string;
};

const getERMembers = async (params: RequestParams) => {
  const { data } = await http.get(
    `/engage_rocket_admins/getall`,
    {},
    {
      params: {
        offset: params.offset,
        limit: params.limit,
        sort_by: params.sort_by,
        sort_type: params.sort_type,
        search_value: params.search_value,
        search_by: params.search_by,
      },
    }
  );
  return { members: data }; //TODO pagination GO
};

export const useGetERMembers = (
  params: RequestParams
): UseQueryResult<any, Error> => {
  const queryResult = useQuery(
    [
      "engage_rocket_admins",
      params.limit,
      params.offset,
      params.sort_by,
      params.sort_type,
      params.search_by,
      params.search_value,
    ],
    {
      queryFn: () => getERMembers(params),
      enabled: true,
      onError: (error: Error) => error,
    }
  );

  return queryResult;
};
