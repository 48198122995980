import http from "api/config/https";
import { createUrl } from "helpers/url";

export const GetMyInfo = async (): Promise<any> => {
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/permission/internal/myinfo",
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/accounts/GetMyInfo",
    });
    return http.get(url);
  }
};
