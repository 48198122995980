import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  .path-background {
    fill: var(--colorBrandForeground1);
  }
`;

export const SuccessLogoutIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
      fill="#07C357"
    />
  </StyledSvg>
);
