import http from "./config/https";

export type RevokeAdminSuccessResponseData = {};

export const revokeAdmin = ({
  workspaceId,
  accountRoleId,
  entityId,
  email,
}: {
  workspaceId: number;
  accountRoleId: number;
  entityId?: number;
  email: string;
}) => {
  const data = {
    email: email,
    accountRoleID: accountRoleId,
    entityId: entityId,
    workspaceID: workspaceId
  }
  if (http.isAPIGO) {
    return http.postGO(`/permission/account_role/RevokeWorkspaceAdmin`, data);
  }
  return http.post(`/account_role/RevokeWorkspaceAdmin`, data)
} 
