import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg``;

export const IconchngPhoto = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12.8867" cy="12.5" r="12.5" fill="#A9CDFF" />
    <path
      d="M18.1367 7.21054H13.4267L13.1867 6.4737C13.0311 6.04134 12.7425 5.66723 12.3609 5.40319C11.9793 5.13915 11.5235 4.99825 11.0567 5.00002H7.63672C7.03998 5.00002 6.46769 5.23291 6.04573 5.64746C5.62377 6.06202 5.38672 6.62427 5.38672 7.21054V16.7895C5.38672 17.3757 5.62377 17.938 6.04573 18.3526C6.46769 18.7671 7.03998 19 7.63672 19H18.1367C18.7335 19 19.3058 18.7671 19.7277 18.3526C20.1497 17.938 20.3867 17.3757 20.3867 16.7895V9.42106C20.3867 8.8348 20.1497 8.27254 19.7277 7.85799C19.3058 7.44343 18.7335 7.21054 18.1367 7.21054ZM18.8867 16.7895C18.8867 16.9849 18.8077 17.1723 18.667 17.3105C18.5264 17.4487 18.3356 17.5263 18.1367 17.5263H7.63672C7.43781 17.5263 7.24704 17.4487 7.10639 17.3105C6.96574 17.1723 6.88672 16.9849 6.88672 16.7895V7.21054C6.88672 7.01512 6.96574 6.8277 7.10639 6.68951C7.24704 6.55133 7.43781 6.4737 7.63672 6.4737H11.0567C11.214 6.4733 11.3673 6.52146 11.4952 6.61137C11.623 6.70128 11.7189 6.8284 11.7692 6.97475L12.1742 8.18317C12.2245 8.32952 12.3204 8.45664 12.4482 8.54655C12.5761 8.63646 12.7295 8.68462 12.8867 8.68422H18.1367C18.3356 8.68422 18.5264 8.76185 18.667 8.90004C18.8077 9.03822 18.8867 9.22564 18.8867 9.42106V16.7895Z"
      fill="white"
    />
  </StyledSvg>
);
