import React, { ChangeEvent, useRef, useState } from "react";
import styled from "styled-components";
import ImageIcon from "@mui/icons-material/Image";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CustomButton from "./button/Button";

interface FileInputProps {
  onFileSelect: (file: any) => void;
  label: string;
  secondaryLabel: string;
  errorText: string;
  Id?: string;
  logoURL?: string;
}

const FileInput: React.FC<FileInputProps> = ({ onFileSelect, ...props }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      onFileSelect(file);
      if (file) {
        const blob = new Blob([file!]);
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
        setSelectedFile(file!);
        fileInputRef.current.value = null;
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveButtonClick = () => {
    setSelectedFile(null);
    setImageSrc(null);
    onFileSelect(null);
  };

  return (
    <>
      <LabelText id={props.Id ? `${props.Id}_LabelTitle` : null}>{props.label}</LabelText>
      <LabelSecondary id={props.Id ? `${props.Id}_LabelSubTitle` : null}>
        {props.secondaryLabel}
      </LabelSecondary>
      {selectedFile || (props.logoURL && props.logoURL) ? (
        <LogoWrapper>
          <ImgWrapper
            id={props.Id ? `${props.Id}_Select` : null}
            src={imageSrc || (props.logoURL && props.logoURL) || undefined}
            alt="Selected image"
          />
        </LogoWrapper>
      ) : (
        <>
          <LogoWrapperNoImage>
            <NoImageItems>
              <ImageIconStyled />
              <NoImageText id={props.Id ? `${props.Id}_NoImage_Title` : null}>
                No image uploaded
              </NoImageText>
            </NoImageItems>
          </LogoWrapperNoImage>
        </>
      )}

      {props.errorText !== "" && (
        <ErrorText id={props.Id ? `${props.Id}_NoImage_ErrorText` : null}>
          {props.errorText}
        </ErrorText>
      )}
      <ButtonsWrapper>
        <input
          id={props.Id ? `${props.Id}_Input` : null}
          type="file"
          ref={fileInputRef}
          accept=".jpg, .jpeg, .png"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <UploadButton
          id={props.Id ? `${props.Id}_UploadImageBtn` : null}
          variant="outlined"
          onClick={handleButtonClick}
        >
          {" "}
          <FileUploadIcon />
          Upload image
        </UploadButton>

        <CustomButton
          id={props.Id ? `${props.Id}_DeleteImage` : null}
          variant="outlined"
          onClick={handleRemoveButtonClick}
        >
          Delete image
        </CustomButton>
      </ButtonsWrapper>
    </>
  );
};

const LogoWrapper = styled.div`
  width: 130px;
  height: 130px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 8px;
`;

const LogoWrapperNoImage = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 8px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  background: var(--colorNeutralForeground8);
`;

const ErrorText = styled.div`
  color: var(--colorPaletteRedForeground2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ImageIconStyled = styled(ImageIcon)`
  color: var(--colorNeutralForeground10);
  height: 70px !important;
  font-size: 40px !important;
`;

const NoImageItems = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
  flex-direction: column;
`;

const LabelText = styled.div`
  margin-top: 15px;
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const ImgWrapper = styled.img`
  max-width: 110px;
  max-height: 110px;
`;

const NoImageText = styled.p`
  color: var(--colorNeutralForeground11);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const LabelSecondary = styled.div`
  color: var(--colorNeutralForeground5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

const UploadButton = styled(CustomButton)`
  margin-right: 5px !important;
`;

export default FileInput;
