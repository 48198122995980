import { FC } from "react";
import { observer } from "mobx-react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import store from "./store";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import ImageUploader from "./ImageUploader";

type EditTemplatePopupProps = {};

const EditTemplatePopup: FC<EditTemplatePopupProps> = observer(() => {
  return (
    <StyledDialog
      id="EditTemplatePopup_Dialog"
      open={store.isOpenEditTemplatePopup}
      onClose={() => store.setData("isOpenEditTemplatePopup", false)}
    >
      <StyledDialogContent id="EditTemplatePopup_DialogContent">
        <DialogTitle id="EditTemplatePopup_DialogTitle">Edit Template details</DialogTitle>

        <StyledTextField
          id="EditTemplatePopup_TemplateNameField"
          value={store.selectedTemplate?.name}
          onChange={(e) => {
            store.setData("selectedTemplate", {
              ...store.selectedTemplate,
              name: e.target.value,
            });
          }}
          fullWidth
          variant="standard"
          placeholder="Template title"
          helperText={`${store.selectedTemplate?.name?.length}/33`}
          inputProps={{
            maxLength: 33,
          }}
        />

        <ImageUploader logoURL={store.selectedTemplate?.logo_url}/>

        <SelectWrapper id="EditTemplatePopup_SelectWrapper">
          <FormControl fullWidth id="EditTemplatePopup_FrequencyFormControl">
            <InputLabel id="EditTemplatePopup_FrequencyLabel">Set Recommended Frequency</InputLabel>
            <Select
              id="EditTemplatePopup_FrequencySelect"
              labelId="EditTemplatePopup_FrequencyLabel"
              value={store.selectedTemplate?.frequency_name}
              label="Set Recommended Frequency"
              onChange={(e) =>
                store.setData("selectedTemplate", {
                  ...store.selectedTemplate,
                  frequency_name: e.target.value,
                })
              }
            >
              {store.typeRecomendedFrequancy.map((x) => (
                <MenuItem id={`EditTemplatePopup_FrequencyOption_${x.code}`} key={x.code} value={x.name}>{x.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth id="EditTemplatePopup_TagFormControl">
            <InputLabel id="EditTemplatePopup_TagLabel">Choose Tag</InputLabel>
            <Select
              id="EditTemplatePopup_TagSelect"
              labelId="EditTemplatePopup_TagLabel"
              value={store.selectedTemplate?.type_questionary_name}
              label="Choose Tag"
              onChange={(e) =>
                store.setData("selectedTemplate", {
                  ...store.selectedTemplate,
                  type_questionary_name: e.target.value,
                })
              }
            >
              {store.typesQuestionary.map((x) => (
                <MenuItem id={`EditTemplatePopup_TagOption_${x.code}`} key={x.code} value={x.name}>{x.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </SelectWrapper>

        <StyledTextField
          id="EditTemplatePopup_DescriptionField"
          value={store.selectedTemplate?.description}
          onChange={(e) => {
            store.setData("selectedTemplate", {
              ...store.selectedTemplate,
              description: e.target.value,
            });
          }}
          fullWidth
          variant="outlined"
          placeholder="Template title"
          helperText={`${store.selectedTemplate?.description?.length}/200`}
          inputProps={{
            maxLength: 200,
          }}
          label="Summary"
          multiline
        />

        <StyledFormControlLabel
          id="EditTemplatePopup_DefaultTemplateControl"
          control={
            <StyledCheckBox
              id="EditTemplatePopup_DefaultCheckBox"
              checked={store.selectedTemplate?.is_default_template}
              onChange={() => {
                store.setData("selectedTemplate", {
                  ...store.selectedTemplate,
                  is_default_template: !store.selectedTemplate?.is_default_template,
                });
              }}
            />
          }
          label="Make this template a default template for all entities that has access to Surveys."
        />

        <ButtonWrapper id="EditTemplatePopup_ButtonWrapper">
          <UploadCustomButton id="EditTemplatePopup_UploadButton" variant="contained" type="submit" onClick={() => store.saveEditTemplate(store.selectedTemplate)}>
            Upload
          </UploadCustomButton>
          <CancelCustomButton
            id="EditTemplatePopup_CancelButton"
            variant="outlined"
            onClick={() => store.setData("isOpenEditTemplatePopup", false)}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default EditTemplatePopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 520px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 40px 40px 0px 40px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 0px 40px 0px;
`;

const UploadCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 140px;
  height: 34px;
  font-weight: 400 !important;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  font-weight: 400 !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 15px !important;

  .MuiFormHelperText-root {
    align-self: self-end !important;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground2);
  margin-top: 16px;
  align-items: flex-start !important;

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: 24px;
`;
