import { ErroIcon } from "./ErrorIcon";
import { FC, ReactElement } from "react";

import styled from "styled-components";
import Button from "components/button/Button";
import CustomModal from "components/modal/Modal";

type ModalErrorCreateWoskspaceProps = {
  open: boolean;
  hide: (state: boolean) => void;
  createNewWorkspace: () => void;
};

const ModalErrorCreateWoskspace: FC<ModalErrorCreateWoskspaceProps> = (
  props
): ReactElement => {
  return (
    <CustomModal open={props.open} hide={() => props.hide(false)} width="468">
      <StyledModalContent aria-label={"Oops, workspace not created form"}>
        <ContentWrapper>
          <ErroIcon />
          <ModalHeader id="Workspace_ErrorModalCreateWorkspace_Title">Oops, workspace not created.</ModalHeader>
          <SubHeader id="Workspace_ErrorModalCreateWorkspace_Subtitle">
            Something went wrong when creating your workspace. Please try again.
          </SubHeader>
        </ContentWrapper>
        <ButtonWrapper>
          <StyledCreateButton
          id="Workspace_ErrorModalCreateWorkspace_CreateWorkspaceAgain_Btn"
            variant="outlined"
            onClick={props.createNewWorkspace}
          >
            Create workspace again
          </StyledCreateButton>
          <StyledCancelButton
          id="Workspace_ErrorModalCreateWorkspace_Cancel_Btn"
            variant="outlined"
            onClick={() => props.hide(false)}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalErrorCreateWoskspace;

const StyledModalContent = styled.div``;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHeader = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin-top: 20px;
`;

const SubHeader = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 17px 0px 0px 0px;
`;

const StyledCancelButton = styled(Button)`
  width: 100%;
  max-width: 75px;
  margin-left: 16px;
`;

const StyledCreateButton = styled(Button)`
  width: 100%;
  max-width: 183px;
  white-space: nowrap;
`;
