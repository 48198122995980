import { observer } from "mobx-react";
import { FC, useEffect } from "react";

import store from "../../store";
import styled from "styled-components";
import SavePopup from "./SavePopup";
import CustomSwitch from "components/switch/Switch";

type FeatureTabProps = {};

const FeatureTab: FC<FeatureTabProps> = observer(() => {
  useEffect(() => {
    store.clearFeaturesTab();
    store.getFeatures();
  }, [store.selectedEntity, store.tabId]);

  return (
    <Container>
      {store.features.map((section, index) => (
        <Section key={section.id} id={`WorkspaceSettings_GridContainer_FeaturesSection_${index}_${section.id}`}>
          <SectionRow id={`WorkspaceSettings_GridContainer_FeaturesSection_${index}_headerName_${section.id}`} $isHeader>
            <SectionText $isHeader>{section.name}</SectionText>
          </SectionRow>
          {section.features.map((setting, settingIndex) => (
            <SectionRow
              id={`WorkspaceSettings_GridContainer_FeaturesSection_${index}_Row_${settingIndex}_${setting.id}`}
              key={setting.id}>
              <SectionText
                id={`WorkspaceSettings_GridContainer_FeaturesSection_${index}_Row_${settingIndex}_Text_${setting.id}`}
              >{setting.name}</SectionText>
              <CustomSwitch
                id={`WorkspaceSettings_GridContainer_FeaturesSection_${index}_Row_${settingIndex}_SwitchBtn_${setting.id}`}
                checked={setting.hasFeature}
                onChange={(event) => {
                  store.changeFeature(
                    index,
                    settingIndex,
                    event.target.checked
                  );
                }}
              />
            </SectionRow>
          ))}
        </Section>
      ))}
      <SavePopup />
    </Container>
  );
});

export default FeatureTab;

const Container = styled.div`
  width: 100%;
  max-width: 605px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--colorShadowInverted3);
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionRow = styled.div<{ $isHeader?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--colorShadowInverted3);
  padding: ${(props) => (props.$isHeader ? `9px 16px` : `16px`)};
  background-color: ${(props) =>
    props.$isHeader
      ? `var(--colorPalleteLightBlue)`
      : `var(--colorNeutralBackground1)`};
`;

const SectionText = styled.p<{ $isHeader?: boolean }>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: ${(props) => (props.$isHeader ? `500` : `400`)};
  line-height: 24px;
  letter-spacing: 0.15px;
`;
