import { queryClient } from "providers/ReactQueryProvider";
import { useMutation } from "@tanstack/react-query";

import http from "./config/https";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export type CreateMemberSuccessResponseData = {
  id: string;
};

const createERMember = async (memberData: { email: string; name: string }) => {
  const response = await http.post(
    `/engage_rocket_admins/AddAdmin`, //TODO replace later to GO backend
    { email: memberData.email, name: memberData.name },
    {}
  );
  if ([200, 201].includes(response.status)) {
    return response.data
  }
  throw new UnexpectedApiError(response);
};

export const useCreateERMember = () => {
  return useMutation({
    mutationFn: createERMember,
    onSuccess: () => {
      queryClient.removeQueries(["engage_rocket_admins"]);
    },
  });
};
